import React, { FC } from 'react'
import { Col } from 'antd'
import { ProfileDataBoxContainer, SectionTitle, StyledText } from './Report.styled'
import { CustomIcon } from '../../../../components/CustomIcon/CustomIcon'
import { WebAppIcon } from '../../../../assets/icons/icons'
import { Flex } from '../Flex'

interface Props {
  title: string
  value: string
  iconName?: WebAppIcon
}

export const ProfileDataBox: FC<Props> = (props: Props) => {
  const { title, value, iconName } = props
  return (
    <Col xxl={{ span: 4 }} xl={{ span: 4 }} lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}>
      <ProfileDataBoxContainer>
        <StyledText style={{ whiteSpace: 'nowrap' }}>{title}</StyledText>
        <Flex alignItems="center" style={{ flexWrap: 'wrap', gap: 6 }}>
          {iconName && <CustomIcon style={{ fill: 'black', stroke: 'unset', marginRight: 10 }} icon={iconName} />}
          <SectionTitle color="black" fontSize={16} style={{ whiteSpace: 'nowrap' }}>
            {value}
          </SectionTitle>
        </Flex>
      </ProfileDataBoxContainer>
    </Col>
  )
}
