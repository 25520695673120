import { MultiSelectQuestion, QUESTION_STATE_HEALTH_DATA, QUESTION_TYPE_MULTISELECT } from 'health-check-api'

export const mockMultiSelectQuestion: MultiSelectQuestion = {
  id: 'multiSelect',
  text: 'Are you receiving treatment for your diabetes?',
  description: '',
  type: QUESTION_TYPE_MULTISELECT,
  state: QUESTION_STATE_HEALTH_DATA,
  answers: [
    {
      id: 'multiSelectA1',
      text: 'Diet and exercise',
      params: {},
    },
    {
      id: 'multiSelectA2',
      text: 'Yes, pills (e.g., metformin, SGLT inhibitors)',
      params: {},
    },
    {
      id: 'multiSelectA3',
      text: 'Yes, insulin',
      params: {},
    },
    {
      id: 'multiSelectA4',
      text: 'Yes, other injections (e.g., liraglutide)',
      params: {},
    },
    {
      id: 'multiSelectA5',
      text: 'Something exclusive',
      params: {
        excluding: true,
      },
    },
    {
      id: 'multiSelectA6',
      text: 'None of these',
      params: {
        excluding: true,
      },
    },
    {
      id: 'multiSelectA7',
      text: 'No treatment',
      params: {
        excluding: true,
      },
    },
  ],
  params: null,
  canMoveBack: true,
}
