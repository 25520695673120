import { ReportResponse } from 'health-check-api'
import { PROFILE_RULE_GENDER_FEMALE } from 'health-check-types'

const extraTopics = [
  {
    id: 'riskTopic2',
    name: 'Mental health',
    description: 'Mental health disease description',
    score: 3,
    recommendedExaminations: [],
    recommendation: {
      protective: [
        { id: 'protective4', factor: 'Try to rest more ' },
        { id: 'protective1', factor: 'Stress free life' },
        { id: 'protective2', factor: 'Staying active' },
        { id: 'protective5', factor: 'Eat more vegetables' },
        { id: 'protective6', factor: 'Eat less junk food' },
        { id: 'protective7', factor: 'Work less' },
      ],
      negative: [
        {
          id: 'negative3',
          factor: 'High stress',
          title: 'Try to remain stress-free',
          description: 'Too much stress can have a detrimental effect on your mental health.',
        },
        {
          id: 'negative2',
          factor: 'High body mass / high waist circumference',
          title: 'Lose weight',
          description:
            'Your Body Mass Index and waist circumference are above the normal range. Overweight and obesity is associated with many health risks and complications, e.g. cardiovascular diseases, type 2 diabetes, cancers, gastrointestinal issues, reproductive disorders, mental health disorders and joint problems. Try to lose some weight mainly by being active and by focusing on your eating habits.',
        },
      ],
    },
  },
  {
    id: 'riskTopic3',
    name: 'Neoplasms',
    description: 'Neoplasms (also known as tumors, cancers, etc.) description',
    score: 4,
    recommendedExaminations: [
      {
        id: 'd19b7b14-52bd-492e-ae5f-f94733d9412d',
        name: 'Full blood count (FBC)',
        description:
          ' Full blood count (FBC) – measures different types of blood levels and can show, for example, if there is an infection or if you have anaemia.',
      },
    ],
    recommendation: {
      protective: [],
      negative: [
        {
          id: 'negative4',
          factor: 'Negative factor 4',
          title: 'Negative title 4',
          description: 'Negative description 4',
        },
        {
          id: 'negative5',
          factor: 'Negative factor 5',
          title: 'Negative title 5',
          description: 'Negative description 5',
        },
      ],
    },
  },
  {
    id: 'riskTopic4',
    name: 'Musculoskeletal diseases',
    description: 'Musculoskeletal diseases description',
    score: 1,
    recommendedExaminations: [],
    recommendation: {
      protective: [
        { id: 'protective1', factor: 'Stress free life' },
        { id: 'protective2', factor: 'Staying active' },
        { id: 'protective3', factor: 'Non-smoker' },
      ],
      negative: [
        {
          id: 'negative6',
          factor: 'Negative factor 6',
          title: 'Negative title 6',
          description: 'Negative description 6',
        },
        {
          id: 'negative7',
          factor: 'Negative factor 7',
          title: 'Negative title 7',
          description: 'Negative description 7',
        },
      ],
    },
  },
  {
    id: 'riskTopic5',
    name: 'Endocrine diseases',
    description:
      'Endocrine diseases are disorders of the endocrine system. The branch of medicine associated with endocrine disorders is known as endocrinology. In endocrinology, medical emergencies include diabetic ketoacidosis, hyperosmolar hyperglycemic state, hypoglycemic coma, acute adrenocortical insufficiency, phaeochromocytoma crisis, hypercalcemic crisis, thyroid storm, myxoedema coma and pituitary apoplexy.',
    score: 0,
    recommendedExaminations: [],
    recommendation: {
      protective: [],
      negative: [],
    },
  },
]

export const mockReportQuestion: ReportResponse = {
  profile: {
    age: 43,
    height: 175,
    weight: 67,
    gender: PROFILE_RULE_GENDER_FEMALE,
    bmi: 20.4,
  },
  topics: [
    {
      id: 'riskTopic1',
      name: 'Cardiovascular diseases',
      description:
        'Cardiovascular diseases (CVDs) constitute a group of disorders that affect the heart and blood vessels and include conditions such as coronary heart disease, heart failure, stroke and high blood pressure. These diseases are a leading health problem worldwide and are responsible for a significant proportion of morbidity and mortality worldwide. Many factors contribute to the development of CVD, including unhealthy diet, lack of physical activity, smoking, excessive alcohol consumption and genetic predisposition. Obesity, diabetes and stress also play a role in increasing the risk of CVDs. Prevention and treatment strategies are essential. Lifestyle changes such as a balanced diet, regular exercise, smoking cessation and stress reduction can significantly reduce the risk of CVDs. Medications and medical interventions may be necessary for individuals with existing conditions. Furthermore, public health initiatives, early detection and better management of CVD risk factors are crucial to reducing the burden of these diseases.',
      score: 2,
      recommendedExaminations: [
        {
          id: '50674f9c-d912-498b-81fe-e20cdd874600',
          name: 'Screening for high blood pressure',
          description:
            'Screening for elevated blood pressure is recommended for all persons over the age of 18. The mean value of two measurements taken in a sitting position should be used as the blood pressure value for screening. If this value is normal, it is checked again at the recommended interval.',
        },
      ],
      recommendation: {
        protective: [
          { id: 'protective1', factor: 'Stress free life' },
          { id: 'protective2', factor: 'Staying active' },
          { id: 'protective3', factor: 'Non-smoker' },
        ],
        negative: [
          {
            id: 'negative1',
            factor: 'Blood lipids',
            title: 'Keep your blood lipids controlled',
            description:
              'Elevated levels of cholesterol and triglycerides promote atherosclerosis, causing plaque buildup and narrowing of arteries, leading to heart disease, heart attacks, strokes and peripheral artery disease as well. Adopt a balanced diet low in saturated and trans fats, be physically active and take medication if advised by your healthcare provider.',
          },
          {
            id: 'negative2',
            factor: 'High body mass / high waist circumference',
            title: 'Lose weight',
            description:
              'Your Body Mass Index and waist circumference are above the normal range. Overweight and obesity is associated with many health risks and complications, e.g. cardiovascular diseases, type 2 diabetes, cancers, gastrointestinal issues, reproductive disorders, mental health disorders and joint problems. Try to lose some weight mainly by being active and by focusing on your eating habits.',
          },
        ],
      },
    },
    ...extraTopics,
  ],
  recommendedExaminations: [
    {
      id: '50674f9c-d912-498b-81fe-e20cdd874600',
      name: 'Screening for high blood pressure',
      description:
        'Screening for elevated blood pressure is recommended for all persons over the age of 18. The mean value of two measurements taken in a sitting position should be used as the blood pressure value for screening. If this value is normal, it is checked again at the recommended interval.',
    },
    {
      id: 'd19b7b14-52bd-492e-ae5f-f94733d9412d',
      name: 'Full blood count (FBC)',
      description:
        ' Full blood count (FBC) – measures different types of blood levels and can show, for example, if there is an infection or if you have anaemia.',
    },
  ],
}

export const mockReportQuestionWithSingleTopic: ReportResponse = {
  profile: {
    age: 43,
    height: 175,
    weight: 67,
    gender: PROFILE_RULE_GENDER_FEMALE,
    bmi: 20.4,
  },
  topics: [
    {
      id: 'riskTopic1',
      name: 'Cardiovascular diseases',
      description:
        'Cardiovascular diseases (CVDs) constitute a group of disorders that affect the heart and blood vessels and include conditions such as coronary heart disease, heart failure, stroke and high blood pressure. These diseases are a leading health problem worldwide and are responsible for a significant proportion of morbidity and mortality worldwide. Many factors contribute to the development of CVD, including unhealthy diet, lack of physical activity, smoking, excessive alcohol consumption and genetic predisposition. Obesity, diabetes and stress also play a role in increasing the risk of CVDs. Prevention and treatment strategies are essential. Lifestyle changes such as a balanced diet, regular exercise, smoking cessation and stress reduction can significantly reduce the risk of CVDs. Medications and medical interventions may be necessary for individuals with existing conditions. Furthermore, public health initiatives, early detection and better management of CVD risk factors are crucial to reducing the burden of these diseases.',
      score: 2,
      recommendedExaminations: [
        {
          id: '50674f9c-d912-498b-81fe-e20cdd874600',
          name: 'Screening for high blood pressure',
          description:
            'Screening for elevated blood pressure is recommended for all persons over the age of 18. The mean value of two measurements taken in a sitting position should be used as the blood pressure value for screening. If this value is normal, it is checked again at the recommended interval.',
        },
      ],
      recommendation: {
        protective: [
          { id: 'protective1', factor: 'Stress free life' },
          { id: 'protective2', factor: 'Staying active' },
          { id: 'protective3', factor: 'Non-smoker' },
        ],
        negative: [
          {
            id: 'negative1',
            factor: 'Blood lipids',
            title: 'Keep your blood lipids controlled',
            description:
              'Elevated levels of cholesterol and triglycerides promote atherosclerosis, causing plaque buildup and narrowing of arteries, leading to heart disease, heart attacks, strokes and peripheral artery disease as well. Adopt a balanced diet low in saturated and trans fats, be physically active and take medication if advised by your healthcare provider.',
          },
          {
            id: 'negative2',
            factor: 'High body mass / high waist circumference',
            title: 'Lose weight',
            description:
              'Your Body Mass Index and waist circumference are above the normal range. Overweight and obesity is associated with many health risks and complications, e.g. cardiovascular diseases, type 2 diabetes, cancers, gastrointestinal issues, reproductive disorders, mental health disorders and joint problems. Try to lose some weight mainly by being active and by focusing on your eating habits.',
          },
        ],
      },
    },
  ],
  recommendedExaminations: [
    {
      id: '50674f9c-d912-498b-81fe-e20cdd874600',
      name: 'Screening for high blood pressure',
      description:
        'Screening for elevated blood pressure is recommended for all persons over the age of 18. The mean value of two measurements taken in a sitting position should be used as the blood pressure value for screening. If this value is normal, it is checked again at the recommended interval.',
    },
    {
      id: 'd19b7b14-52bd-492e-ae5f-f94733d9412d',
      name: 'Full blood count (FBC)',
      description:
        ' Full blood count (FBC) – measures different types of blood levels and can show, for example, if there is an infection or if you have anaemia.',
    },
  ],
}
