import styled from '@emotion/styled'

export const SliderLabel = styled.span<{ color: string; fontSize: string; textAlign?: string }>`
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.fontSize}px`};
  word-break: break-word;
  white-space: normal;
  max-width: 100px;
  display: block;
  line-height: 12px;
  text-align: ${(props) => props.textAlign ?? 'left'};
`
