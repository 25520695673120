import { createContext, useContext } from 'react'
import { CHECK_STATE_IN_PROGRESS, FamilyHistoryAnswer, QuestionResponse, ReportResponse } from 'health-check-api'
import { startQuestion } from '../mock'
import { HealthCheckApi } from '../hooks/useApi'
import { FooterButtonState, StatusStep } from '../models'

export type HealthCheckContext = {
  report?: ReportResponse
  setReport: (val: ReportResponse) => void
  currentQuestion: QuestionResponse
  setCurrentQuestion: (val: QuestionResponse) => void
  isLoading: boolean
  setLoading: (val: boolean) => void
  checkId: string
  setCheckId: (checkId: string) => void
  selectedAnswer: string | null | string[] | FamilyHistoryAnswer
  setSelectedAnswer: (selectedAnswer: string | null | string[] | FamilyHistoryAnswer) => void
  api: HealthCheckApi
  footerButtonState: FooterButtonState
  setFooterButtonState: (state: FooterButtonState) => void
  statusStep: StatusStep
  setStatusStep: (step: StatusStep) => void
}

const initialValue: HealthCheckContext = {
  report: undefined,
  setReport: () => null,
  currentQuestion: startQuestion,
  setCurrentQuestion: () => null,
  isLoading: false,
  setLoading: () => null,
  checkId: '',
  setCheckId: () => null,
  selectedAnswer: null,
  setSelectedAnswer: () => null,
  api: {
    initialize: () => Promise.resolve(''),
    getQuestion: () => Promise.resolve(startQuestion),
    setAnswer: () => Promise.resolve({ status: CHECK_STATE_IN_PROGRESS }),
    getReport: () =>
      Promise.resolve({
        topics: [],
        recommendedExaminations: [],
        recommendation: { protective: [], negative: [] },
        profile: { age: 0, bmi: 0, height: 0, weight: 0, gender: 'Male' },
      }),
    back: () => Promise.resolve({ status: CHECK_STATE_IN_PROGRESS }),
    getPDF: () => Promise.resolve(new Buffer('')),
    getExaminationUrls: () => Promise.resolve({ items: [] }),
  },
  footerButtonState: {
    onNextButtonClick: null,
    nextButtonTitle: '',
    isNextButtonDisabled: false,
    isNextButtonHidden: false,
    isSkipButtonHidden: true,
    onSkipButtonClick: null,
    isBackButtonHidden: true,
  },
  setFooterButtonState: () => null,
  statusStep: 0,
  setStatusStep: () => null,
}

export const QuestionContext = createContext(initialValue)

export const useQuestionContext = () => useContext(QuestionContext)
