import { useMemo } from 'react'
import { RecommendedExamination, RiskTopicWithRecommendation } from 'health-check-api'

export const useReportDetails = (
  topics: RiskTopicWithRecommendation[],
  examinations: RecommendedExamination[],
  isMultipleRiskTopicMode: boolean,
  isDetailedMode: boolean,
  selectedRiskTopics?: RiskTopicWithRecommendation,
) => {
  const protectiveFactors = useMemo(() => {
    if (isMultipleRiskTopicMode && !isDetailedMode) {
      const allProtectiveFactors = topics.flatMap((topic) => topic.recommendation.protective)
      return [...new Map(allProtectiveFactors.map((item) => [item.id, item])).values()]
    }
    if (selectedRiskTopics) {
      return selectedRiskTopics?.recommendation.protective ?? []
    }
    return topics[0].recommendation.protective
  }, [isMultipleRiskTopicMode, isDetailedMode, selectedRiskTopics, topics])

  const negativeFactors = useMemo(() => {
    if (isMultipleRiskTopicMode && !isDetailedMode) {
      const allNegativeFactors = topics.flatMap((topic) => topic.recommendation.negative)
      return [...new Map(allNegativeFactors.map((item) => [item.id, item])).values()]
    }
    if (selectedRiskTopics) {
      return selectedRiskTopics?.recommendation.negative ?? []
    }
    return topics[0].recommendation.negative ?? []
  }, [isMultipleRiskTopicMode, isDetailedMode, selectedRiskTopics, topics])

  const filteredExaminations = useMemo(() => {
    if (isDetailedMode && selectedRiskTopics) {
      const topicRelatedExaminationIds =
        topics
          .find((t) => t.id === selectedRiskTopics.id)
          ?.recommendedExaminations.map((examinationHit) => examinationHit.id) ?? []
      return examinations.filter((decoratedExamination) => topicRelatedExaminationIds.includes(decoratedExamination.id))
    }
    return examinations
  }, [examinations, isDetailedMode, selectedRiskTopics, topics])

  return { protectiveFactors, negativeFactors, filteredExaminations }
}
