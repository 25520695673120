import styled from '@emotion/styled'

// TODO migrate MainPage.module.less to styled components

export const PageContainer = styled.div<{ isServicesOpen: boolean; isOnStartScreen: boolean }>(
  `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  `,
  (props) => props.isServicesOpen && `overflow: hidden`,
  (props) => !props.isOnStartScreen && `margin-top: 50px;`,
)
