import React, { FC, useMemo, useState } from 'react'
import { Col, Collapse, Row } from 'antd'
import { UpOutlined } from '@ant-design/icons'
import { NegativeRiskFactor } from 'health-check-api'
import { mobileMaxWidth, useI18n } from '../../../../hooks'
import {
  AssociatedRiskFactorRow,
  RecommendationContainer,
  RecommendationPanel,
  RecommendationTitleRow,
  SectionTitle,
  StyledRow,
  StyledText,
} from './Report.styled'
import { useWindowSize } from 'usehooks-ts'
import { Flex } from '../Flex'

interface Props {
  negativeRiskFactors: NegativeRiskFactor[]
}

export const RecommendationSection: FC<Props> = (props) => {
  const { negativeRiskFactors } = props
  const { i18n } = useI18n()
  const { width: windowWidth } = useWindowSize()
  const [isFiltered, setFiltered] = useState<boolean>(true)

  const isMobile = useMemo(() => windowWidth <= mobileMaxWidth, [windowWidth])

  //TODO reactivate once categories are implemented
  // const [selectedCategory, setSelectedCategory] = useState<string>('All')
  // const filterLogic = useMemo(() => {
  //   if (selectedCategory === 'All') {
  //     return () => true
  //   } else {
  //     return (recommendation: Record<string, any>) => recommendation.category === selectedCategory
  //   }
  // }, [selectedCategory])
  // const isCategoryDisabled = useCallback((categoryName: string) => {
  //   return false
  // if (categoryName === 'All') {
  //   return false
  // }
  // const numberOfRecommendationsInCategory =
  //   negativeRiskFactors.filter((riskFactor) => riskFactor.category === categoryName)?.length ?? 0
  // return numberOfRecommendationsInCategory === 0
  // }, [])

  const filteredNegativeRiskFactors = useMemo(
    () => (isFiltered && negativeRiskFactors.length > 4 ? negativeRiskFactors.slice(0, 5) : negativeRiskFactors),
    [isFiltered, negativeRiskFactors],
  )

  return (
    <>
      <StyledRow gutter={[0, 10]} margin="0 0 20px 0">
        <Col span={24}>
          <SectionTitle>{i18n('healthCheck.report.negativeRiskFactors.title')}</SectionTitle>
        </Col>
        <Col span={24}>
          <StyledText>
            {negativeRiskFactors.length === 0
              ? i18n('healthCheck.report.negativeRiskFactor.emptyState.description')
              : i18n('healthCheck.report.negativeRiskFactors.description')}
          </StyledText>
        </Col>
      </StyledRow>
      <Row>
        {/*TODO reactivate once categories are implemented*/}
        {/*<Col span={24} style={{ display: 'flex' }}>*/}
        {/*  {['All', 'General', 'Family history', 'Lifestyle', 'Medical history'].map((category) => (*/}
        {/*    <RiskFactorCategoryButton*/}
        {/*      key={`risk-factor-category-${category}`}*/}
        {/*      onClick={() => setSelectedCategory(category)}*/}
        {/*      fontWeight={selectedCategory === category ? 700 : 400}*/}
        {/*      data-text={category}*/}
        {/*      disabled={isCategoryDisabled(category)}*/}
        {/*    >*/}
        {/*      {category}*/}
        {/*    </RiskFactorCategoryButton>*/}
        {/*  ))}*/}
        {/*</Col>*/}
        <Col span={24}>
          <Collapse
            defaultActiveKey={['0']}
            ghost={true}
            expandIconPosition="end"
            expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 0 : 180} style={{ marginTop: 8 }} />}
            collapsible={isMobile ? undefined : 'icon'}
          >
            {filteredNegativeRiskFactors.map((riskFactor, index) => (
              <RecommendationPanel
                key={index}
                header={
                  <RecommendationTitleRow>
                    <StyledText fontSize={16} color="black" isBold={true}>
                      {riskFactor.title}
                    </StyledText>
                    <AssociatedRiskFactorRow>
                      <StyledText>{i18n('healthCheck.report.associatedRiskFactor')}</StyledText>
                      <StyledText color="black" isBold={true} style={{ marginLeft: '0.5rem' }}>
                        {riskFactor.factor}
                      </StyledText>
                    </AssociatedRiskFactorRow>
                  </RecommendationTitleRow>
                }
              >
                <RecommendationContainer>
                  <Col span={24}>
                    <StyledText isBold={true}>{i18n('healthCheck.report.recommendations')}</StyledText>
                  </Col>
                  <Col span={24}>
                    <StyledText>{riskFactor.description}</StyledText>
                  </Col>
                </RecommendationContainer>
              </RecommendationPanel>
            ))}
          </Collapse>
        </Col>
        {negativeRiskFactors.length > 5 && (
          <Flex alignItems="center" justifyContent="end" width="100%">
            <button
              style={{
                border: 'unset',
                background: 'unset',
                cursor: 'pointer',
              }}
              onClick={() => {
                setFiltered((prevState) => !prevState)
              }}
            >
              <StyledText
                fontSize={12}
                color={document.documentElement.style.getPropertyValue('--filledButtonBackgroundColor')}
              >
                {isFiltered ? i18n('xund.general.showMore') : i18n('xund.general.showLess')}
              </StyledText>
            </button>
          </Flex>
        )}
      </Row>
    </>
  )
}
