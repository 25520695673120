import React, { FC, Ref, useLayoutEffect, useRef, useState } from 'react'
import { Col } from 'antd'
import { FullWidthBackground, SectionTitle, StyledRow, StyledText } from '../Report.styled'
import { Flex } from '../../Flex'
import { useI18n } from '../../../../../hooks'
import { RiskClassesBox } from './RiskClassesBox'

interface Props {
  isMobile: boolean
  showRiskClasses: boolean
}

export const ExplanationSection: FC<Props> = (props) => {
  const { isMobile, showRiskClasses } = props
  const { i18n } = useI18n()
  const ref: Ref<HTMLDivElement> = useRef(null)
  const [rowLeftBorder, setRowLeftBorder] = useState(0)

  useLayoutEffect(() => {
    const setter = () => {
      const rectangle = ref?.current?.getBoundingClientRect()
      setRowLeftBorder(rectangle?.left ?? 0)
    }
    setter()
    window.addEventListener('resize', setter)

    return () => {
      window.removeEventListener('resize', setter)
    }
  }, [])

  return (
    <StyledRow
      ref={ref}
      gutter={showRiskClasses ? [32, 10] : undefined}
      style={{
        position: 'relative',
        padding: showRiskClasses ? 0 : '50px 0 50px 0',
        marginBottom: showRiskClasses ? 0 : 50,
      }}
    >
      {!showRiskClasses && <FullWidthBackground left={rowLeftBorder} />}
      {isMobile && (
        <Col xs={24}>
          <SectionTitle style={{ marginBottom: '0.5rem' }}>{i18n('healthCheck.report.explanation.title')}</SectionTitle>
        </Col>
      )}
      <Col xs={24} sm={showRiskClasses ? 14 : 24} order={isMobile ? 1 : 0}>
        <Flex flexDirection="column">
          {!isMobile && (
            <SectionTitle style={{ marginBottom: '0.5rem' }}>
              {i18n('healthCheck.report.explanation.title')}
            </SectionTitle>
          )}
          <StyledText style={{ marginBottom: '1rem' }}>
            {i18n('healthCheck.report.explanation.firstParagraph')}
          </StyledText>
          <StyledText style={{ marginBottom: '1rem' }}>
            {i18n('healthCheck.report.explanation.secondParagraph')}
          </StyledText>
          <StyledText>{i18n('healthCheck.report.explanation.thirdParagraph')}</StyledText>
        </Flex>
      </Col>
      {showRiskClasses && (
        <Col xs={24} sm={10} order={isMobile ? 0 : 1}>
          <RiskClassesBox />
        </Col>
      )}
    </StyledRow>
  )
}
