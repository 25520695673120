import { QUESTION_STATE_RISK_TOPIC, QUESTION_TYPE_RISK_TOPIC, RiskTopicQuestion } from 'health-check-api'

export const mockRiskTopicQuestion: RiskTopicQuestion = {
  id: 'riskTopicId',
  text: 'Select health topics to assess',
  description: '',
  type: QUESTION_TYPE_RISK_TOPIC,
  answers: [
    {
      id: 'cardio',
      text: 'Cardiovascular disease',
      params: {
        description: 'Cardiovascular disease short description is here',
      },
    },
    {
      id: 'mental',
      text: 'Mental health',
      params: {
        description: 'Mental health short description is here',
      },
    },
  ],
  state: QUESTION_STATE_RISK_TOPIC,
  params: null,
  canMoveBack: true,
}
