import { BasicQuestion, QUESTION_STATE_REPORT, QUESTION_TYPE_CONFIRM } from 'health-check-api'

export const mockConfirmQuestion: BasicQuestion = {
  id: 'confirmQuestion',
  text: 'Your health check is ready!',
  description: '',
  type: QUESTION_TYPE_CONFIRM,
  state: QUESTION_STATE_REPORT,
  answers: [],
  params: null,
  canMoveBack: true,
}
