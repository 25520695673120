import React, { FC, useCallback, useState } from 'react'
import { CustomIcon } from '../../../../components/CustomIcon/CustomIcon'
import { useI18n } from '../../../../hooks'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { ButtonContainer, InfoArticle, InfoButton } from './InfoBox.styled'
import { Modal } from '../../../../common/Modals/Modal'

interface Props {
  contextText: string
  title?: string
}

export const InfoBox: FC<Props> = (props: Props) => {
  const { title, contextText } = props
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const { i18n } = useI18n()
  const { trackEvent } = useMatomo()

  const handleClick = useCallback(() => {
    setModalOpen(true)
    trackEvent({
      category: 'Health Check',
      action: 'Infobox opened',
      name: title,
    })
  }, [title, trackEvent])

  return (
    <>
      <ButtonContainer onClick={handleClick}>
        <CustomIcon icon="infoIcon" />
        <InfoButton>{i18n('healthCheck.infoBox.moreInfo')}</InfoButton>
      </ButtonContainer>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onCancel={() => setModalOpen(false)} title={title}>
          <InfoArticle>{contextText}</InfoArticle>
        </Modal>
      )}
    </>
  )
}
