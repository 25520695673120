import React, { FC, useMemo } from 'react'
import { BasicAnswer } from 'health-check-api'
import { StyledButton } from '../../../components/General/StyledButton/StyledButton'
import { useI18n } from '../../../hooks'
import { InfoBox } from './InfoBox'
import { SelectQuestionWrapper } from './SelectQuestionWrapper'
import { SelectInput } from '../../../common'

const MAX_NUMBER_OF_BUTTONS = 7

interface Props {
  title: string
  onDropdownSelect: (value: string) => void
  onSingleSelectClick: (value: string) => void
  answers: BasicAnswer[]
  isLoading: boolean
  questionDescription?: string
}

export const SingleSelect: FC<Props> = (props) => {
  const { title, onDropdownSelect, onSingleSelectClick, answers, isLoading, questionDescription } = props
  const { i18n } = useI18n()

  const isDropDown = useMemo(() => (answers?.length ?? 0) >= MAX_NUMBER_OF_BUTTONS, [answers])

  return (
    <>
      <SelectQuestionWrapper title={title} isLoading={isLoading}>
        <>
          {!isDropDown && (
            <>
              {answers?.map((answer) => (
                <StyledButton
                  key={answer.id}
                  type="outlined"
                  title={answer.text}
                  style={{ marginTop: 10, height: 'auto', whiteSpace: 'normal' }}
                  disabled={isLoading}
                  onClick={() => onSingleSelectClick(answer.id)}
                />
              ))}
            </>
          )}

          {isDropDown && (
            <SelectInput
              options={answers.map((answer) => ({ label: answer.text, value: answer.id }))}
              placeholder={i18n('components.singleSelect.placeholder')}
              onSelect={(value) => {
                if (typeof value !== 'string') {
                  throw new Error('SingleSelect cannot handle multiple selected items!')
                }
                onDropdownSelect(value)
              }}
            />
          )}
          {questionDescription && <InfoBox contextText={questionDescription} title={title} />}
        </>
      </SelectQuestionWrapper>
    </>
  )
}
