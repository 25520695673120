import React, { FC, ReactElement, ReactNode, useMemo } from 'react'
import { WrapperWithTitleAndAnimation } from '../WrapperWithTitleAndAnimation'
import { mobileMaxWidth } from '../../../../hooks'
import { useWindowSize } from 'usehooks-ts'
import { Container, IconWrapper } from './SelectQuestionWrapper.styled'

interface Props {
  title: string
  isLoading: boolean
  prefixIcon?: ReactNode
  children?: ReactElement
}

export const SelectQuestionWrapper: FC<Props> = (props) => {
  const { title, isLoading, prefixIcon, children } = props
  const { width: windowWidth } = useWindowSize()
  const isMobile = useMemo(() => windowWidth <= mobileMaxWidth, [windowWidth])

  return (
    <WrapperWithTitleAndAnimation hasTopMargin={!isMobile} title={title} isAnimated animationStartsWhen={!isLoading}>
      <Container>
        {prefixIcon && <IconWrapper>{prefixIcon}</IconWrapper>}
        {children}
      </Container>
    </WrapperWithTitleAndAnimation>
  )
}
