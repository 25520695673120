import styled from '@emotion/styled'
import { Modal } from 'antd'
import { CSS_VARS, JS_STYLING } from '../../resources/cssVariableConfig'

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 15px;
    box-shadow: 3px 13px 54px 0 rgba(0, 65, 122, 0.18);
    padding: 50px 70px 10px 70px;
    @media (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
      padding: 25px 40px 10px 40px;
    }
  }
`

export const ModalTitle = styled.h1`
  color: ${CSS_VARS.modalTitleFontColor};
  font-family: ${CSS_VARS.fontFamily};
  font-size: ${CSS_VARS.modalTitleFontSize};
  font-weight: ${CSS_VARS.modalTitleFontWeight};
  text-align: center;
`
