import { BasicQuestion, QUESTION_STATE_PROFILE, QUESTION_TYPE_AGE } from 'health-check-api'

export const mockAgeSelectQuestion: BasicQuestion = {
  id: 'ageId',
  text: 'How old are you?',
  description: '',
  type: QUESTION_TYPE_AGE,
  state: QUESTION_STATE_PROFILE,
  answers: [],
  params: null,
  canMoveBack: true,
}
