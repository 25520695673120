import { QUESTION_STATE_START, QUESTION_TYPE_TERMS, QuestionResponse } from 'health-check-api'

export const startQuestion: QuestionResponse = {
  type: QUESTION_TYPE_TERMS,
  id: 'termsAndConditionsId',
  text: '',
  description: '',
  state: QUESTION_STATE_START,
  answers: [],
  params: null,
  canMoveBack: true,
}
