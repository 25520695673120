import React, { FC, useCallback, useMemo } from 'react'
import { Col, Row } from 'antd'
import { useWindowSize } from 'usehooks-ts'
import { LeftOutlined } from '@ant-design/icons'
import { mobileMaxWidth, tabletMaxWidth, useI18n } from '../../../../hooks'
import { StyledButton } from '../../../../components/General/StyledButton/StyledButton'
import { IconButton } from '../../../../components/General/IconButton/IconButton'
import { useQuestionContext } from '../../context'
import { useAppStateContext } from '../../../../context'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { CATEGORY_HEALTH_CHECK } from '../../../../models/Matomo'

export const Footer: FC<{
  onBackClick: () => void
}> = ({ onBackClick }) => {
  const { i18n } = useI18n()
  const { isLoading, footerButtonState, currentQuestion } = useQuestionContext()
  const { isContentLibraryOpen } = useAppStateContext()
  const { trackEvent } = useMatomo()

  const { width: windowWidth } = useWindowSize()

  const isMobile = useMemo(() => windowWidth <= mobileMaxWidth, [windowWidth])
  const isTabletOrSmaller = useMemo(() => windowWidth <= tabletMaxWidth, [windowWidth])

  const handleSkipButtonClick = useCallback(() => {
    if (footerButtonState.onSkipButtonClick) {
      footerButtonState.onSkipButtonClick()
    }
    trackEvent({
      category: CATEGORY_HEALTH_CHECK,
      action: 'Question skipped',
      name: `${currentQuestion.text} (${currentQuestion.id})`,
    })
  }, [currentQuestion.id, currentQuestion.text, footerButtonState, trackEvent])

  const breakPoints = {
    col1: { xs: 24, sm: 8, md: 8 },
    col2: { xs: 24, sm: 8, md: 8 },
    col3: { xs: 24, sm: 8, md: 8 },
  }

  return (
    <div id="footer" style={{ width: '100%', padding: isMobile ? '0 5px' : 'initial' }}>
      <Row
        style={{
          marginLeft: isTabletOrSmaller && isContentLibraryOpen && !isMobile ? 41 : 0,
        }}
      >
        {isContentLibraryOpen && (
          <Col {...breakPoints.col1} order={isMobile ? 4 : 0}>
            <>
              {!isMobile && (
                <div style={{ display: 'flex', marginLeft: -15 }}>
                  <IconButton
                    testId="backButton"
                    icon={<LeftOutlined />}
                    onClick={onBackClick}
                    disabled={isLoading}
                    style={{ height: 30 }}
                  />
                </div>
              )}
              {isMobile && (
                <StyledButton
                  type="skip"
                  title={i18n('xund.general.back')}
                  disabled={isLoading}
                  onClick={onBackClick}
                  style={{ height: 30, width: '100%' }}
                />
              )}
            </>
          </Col>
        )}
        {!isContentLibraryOpen && (
          <>
            <Col {...breakPoints.col1} order={isMobile ? 3 : 0}>
              {!footerButtonState.isBackButtonHidden && (
                <div style={{ display: 'flex', marginLeft: isMobile ? 0 : 30 }}>
                  {!isMobile && (
                    <IconButton
                      testId="backButton"
                      icon={<LeftOutlined />}
                      onClick={onBackClick}
                      disabled={isLoading}
                      style={{ height: 30 }}
                    />
                  )}
                  {isMobile && (
                    <StyledButton
                      testId="skipButton"
                      title="Back"
                      type="skip"
                      onClick={onBackClick}
                      style={{ width: '100%', border: 'unset', boxShadow: 'unset' }}
                      disabled={isLoading}
                    />
                  )}
                </div>
              )}
            </Col>
            <Col {...breakPoints.col2} order={isMobile ? 2 : 0} style={{ textAlign: 'center' }}>
              {!footerButtonState.isSkipButtonHidden && (
                <StyledButton
                  testId="skipButton"
                  title={i18n('healthCheck.footer.skipButton')}
                  type="skip"
                  onClick={handleSkipButtonClick}
                  style={{ width: isMobile ? '100%' : 'initial', marginBottom: isMobile ? 10 : 'unset' }}
                  disabled={isLoading}
                />
              )}
            </Col>
            <Col
              {...breakPoints.col3}
              style={{ textAlign: 'end', paddingRight: !isMobile ? 40 : 0 }}
              order={isMobile ? 1 : 0}
            >
              {footerButtonState.onNextButtonClick && !footerButtonState.isNextButtonHidden && (
                <StyledButton
                  testId="nextButton"
                  type="confirm"
                  title={footerButtonState.nextButtonTitle}
                  onClick={footerButtonState.onNextButtonClick}
                  disabled={footerButtonState.isNextButtonDisabled || isLoading}
                  style={{
                    height: 30,
                    marginBottom: isMobile ? 10 : 'unset',
                    width: isMobile ? '100%' : 'initial',
                    backgroundColor: CSS_VARS.filledButtonBackgroundColor,
                    color: CSS_VARS.filledButtonFontColor,
                    borderColor: CSS_VARS.filledButtonBorderColor,
                  }}
                />
              )}
            </Col>
          </>
        )}
      </Row>
      <div />
    </div>
  )
}
