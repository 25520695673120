import styled from '@emotion/styled'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'

export const ButtonContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
`
export const InfoButton = styled.button`
  background-color: unset;
  border: unset;
  cursor: pointer;
  text-decoration: underline;
`

export const InfoArticle = styled.article`
  color: ${CSS_VARS.chatFontColor};
  font-family: ${CSS_VARS.fontFamily};
  font-size: ${CSS_VARS.chatFontSize};
  font-weight: ${CSS_VARS.chatFontWeight};
  text-align: justify;
  white-space: pre-wrap;
`
