import React, { CSSProperties, FC, useEffect } from 'react'
import { Input } from 'antd'
import styled from '@emotion/styled'
import { AutocompleteResult } from '../../../../../models'
import { useMapboxAutocomplete, useI18n } from '../../../../../hooks'
import { CustomIcon } from '../../../../CustomIcon/CustomIcon'
import { CSS_VARS } from '../../../../../resources/cssVariableConfig'

const StyledInput = styled(Input)`
  input {
    font-size: ${CSS_VARS.chatFontSize} !important;
  }
`

/**
 * @param props The props object
 * @param props.onChange The change handler
 * @param props.style The optional stylings
 * @returns The AutocompleteInput component
 */
export const AutocompleteInput: FC<{ style?: CSSProperties; onChange: (val: AutocompleteResult[]) => void }> = ({
  style,
  onChange,
}) => {
  const { results, searchValue, setSearchText } = useMapboxAutocomplete()

  const { i18n } = useI18n()

  useEffect(() => {
    onChange(results)
  }, [onChange, results])

  return (
    <StyledInput
      placeholder={i18n('xund.services.filters.searchLocation')}
      style={{ background: 'transparent', ...style }}
      value={searchValue}
      onChange={(e) => setSearchText(e.target.value)}
      suffix={<CustomIcon icon="zoom" />}
      autoFocus
    />
  )
}
