import React, { Dispatch, FC, SetStateAction } from 'react'
import { RiskCategory, RiskTopicWithRecommendation, riskCategoryColors } from 'health-check-api'
import {
  LegendScoreDot,
  RiskTopicSelector,
  RiskTopicSelectorButton,
  RiskTopicSectionContainer,
  RiskTopicSelectorTitle,
  StyledText,
  RiskTopicSectionTitle,
  SectionTitle,
} from './Report.styled'
import { useI18n } from '../../../../hooks'
import { TranslationCodeLiteral } from '../../../../models'
import { Flex } from '../Flex'

interface Props {
  riskTopics: RiskTopicWithRecommendation[]
  onSelectChartTopic: Dispatch<SetStateAction<RiskTopicWithRecommendation | undefined>>
  onSetDetailedMode: Dispatch<SetStateAction<boolean>>
}

export const riskCategoryLongNames = {
  0: 'healthCheck.report.score.low',
  1: 'healthCheck.report.score.average',
  2: 'healthCheck.report.score.slightlyIncreased',
  3: 'healthCheck.report.score.medium',
  4: 'healthCheck.report.score.high',
}

export const RiskTopicSection: FC<Props> = (props) => {
  const { riskTopics, onSelectChartTopic, onSetDetailedMode } = props
  const { i18n } = useI18n()

  return (
    <RiskTopicSectionContainer>
      <RiskTopicSectionTitle>
        <SectionTitle fontSize={20}>{i18n('healthCheck.report.multiTopic.completedTopics')}</SectionTitle>
      </RiskTopicSectionTitle>
      {riskTopics
        .sort((a, b) => b.score - a.score)
        .map((topic: RiskTopicWithRecommendation) => (
          <RiskTopicSelector key={`topic-row--${topic.id}`}>
            <RiskTopicSelectorTitle>
              <div>
                <StyledText fontSize={16} isBold={true} color="black">
                  {topic.name}
                </StyledText>
              </div>
              <Flex alignItems="center">
                <LegendScoreDot color={riskCategoryColors[topic.score as RiskCategory]} />
                <StyledText isBold={true}>
                  {i18n(riskCategoryLongNames[topic.score as RiskCategory] as TranslationCodeLiteral)}
                </StyledText>
              </Flex>
            </RiskTopicSelectorTitle>
            <RiskTopicSelectorButton
              onClick={() => {
                onSelectChartTopic(topic)
                onSetDetailedMode(true)
              }}
            >
              {i18n('healthCheck.report.multiTopic.viewDetails')}
            </RiskTopicSelectorButton>
          </RiskTopicSelector>
        ))}
    </RiskTopicSectionContainer>
  )
}
