import styled from '@emotion/styled'
import { Button } from 'antd'
import { buttonCommon, customizedButtonCommon } from '../Filters.common.styled'

export const StyledButton = styled(Button)`
  ${buttonCommon}

  box-shadow: none;
  border-radius: 13px !important;

  ${customizedButtonCommon}
`

export const SelectedLocationContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
`
