import React, { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react'
import { Col, Row } from 'antd'
import { useQuestionContext } from '../../../context'
import styles from '../../../../../components/Footer/Footer.module.less'
import { mobileMaxWidth, tabletMaxWidth, useI18n } from '../../../../../hooks'
import { RestartModal } from '../../../../../components/Modals/RestartModal/RestartModal'
import { useWindowSize } from 'usehooks-ts'
import { BaseButton } from './FooterSection.styled'
import { CSS_VARS } from '../../../../../resources/cssVariableConfig'
import { GhostButton } from '../Report.styled'
import { ACTION_REPORT, CATEGORY_HEALTH_CHECK } from '../../../../../models/Matomo'
import { useMatomo } from '@datapunt/matomo-tracker-react'

interface Props {
  onEndCheck: Dispatch<SetStateAction<boolean>>
  onSetNavigationPreventionDisabled: Dispatch<SetStateAction<boolean>>
  onDownloadPDF: () => void
  isPDFLoading: boolean
  isMobile: boolean
  isIntermediateSize: boolean
}

export const FooterSection: FC<Props> = (props) => {
  const { onEndCheck, onSetNavigationPreventionDisabled, onDownloadPDF, isPDFLoading, isMobile, isIntermediateSize } =
    props
  const { i18n } = useI18n()
  const { width: windowWidth } = useWindowSize()
  const { setStatusStep } = useQuestionContext()
  const { trackEvent } = useMatomo()
  const [isRestartModalVisible, setIsRestartModalVisible] = useState(false)

  const spanSize = useMemo(() => {
    if (isIntermediateSize) {
      return 24
    }
    if (windowWidth > 900 || (windowWidth < tabletMaxWidth && windowWidth > mobileMaxWidth)) {
      return 8
    }
    return 24
  }, [isIntermediateSize, windowWidth])

  const onEndCheckClick = useCallback(() => {
    onEndCheck(true)
    onSetNavigationPreventionDisabled(true)
    setStatusStep(5)
    trackEvent({
      category: CATEGORY_HEALTH_CHECK,
      action: ACTION_REPORT,
      name: 'End Check button clicked',
    })
  }, [onEndCheck, onSetNavigationPreventionDisabled, setStatusStep, trackEvent])

  return (
    <>
      <Row>
        <Col span={spanSize} style={{ textAlign: 'left' }} order={isMobile || isIntermediateSize ? 3 : 0}>
          <GhostButton
            type="skip"
            title={i18n('xund.general.restartCheck')}
            onClick={() => {
              onSetNavigationPreventionDisabled(true)
              setIsRestartModalVisible(true)
            }}
            disabled={isPDFLoading}
            classNames={[styles.reportButton, styles.restartButton]}
            style={{
              padding: isIntermediateSize || isMobile ? '4px 15px' : '4px 15px 4px 0',
              width: isIntermediateSize || isMobile ? '100%' : 'initial',
              textAlign: isIntermediateSize || isMobile ? 'center' : 'left',
            }}
          />
        </Col>
        <Col span={spanSize} style={{ textAlign: 'center' }}>
          <BaseButton
            type="filled"
            title={i18n('xund.general.downloadReport')}
            disabled={isPDFLoading}
            onClick={onDownloadPDF}
            classNames={[styles.reportButton, styles.pdfButton]}
            style={{
              width: isIntermediateSize || isMobile ? '100%' : 'initial',
              fontSize: 14,
            }}
            isLoading={isPDFLoading}
          />
        </Col>
        <Col
          span={spanSize}
          style={{
            textAlign: 'end',
          }}
        >
          <BaseButton
            type="outlined"
            title={i18n('xund.general.endCheck')}
            onClick={onEndCheckClick}
            disabled={isPDFLoading}
            classNames={[styles.reportButton]}
            style={{
              width: isIntermediateSize || isMobile ? '100%' : 'initial',
              color: CSS_VARS.filledButtonBackgroundColor,
              borderColor: CSS_VARS.filledButtonBackgroundColor,
              fontSize: 14,
            }}
          />
        </Col>
      </Row>
      {isRestartModalVisible && (
        <RestartModal
          onConfirm={() => {
            window.location.reload()
            setIsRestartModalVisible(false)
          }}
          onCancel={() => {
            setIsRestartModalVisible(false)
            onSetNavigationPreventionDisabled(false)
          }}
        />
      )}
    </>
  )
}
