import { Col, Steps } from 'antd'
import styled from '@emotion/styled'
import { CheckOutlined } from '@ant-design/icons'
import { CSS_VARS, JS_STYLING } from '../../../../resources/cssVariableConfig'

export const StatusBarCol = styled(Col)`
  margin: 30px 0 10px 0 !important;
  max-height: 50px !important;
  padding: 24px 24px 50px 24px !important;
  background: white !important;
  border-radius: 15px !important;

  @media screen and (max-width: ${JS_STYLING.TABLET_MAX_WIDTH}) and (orientation: portrait),
    (min-width: ${JS_STYLING.TABLET_MAX_WIDTH}) and (orientation: portrait) {
    padding: 24px 0 50px 0 !important;
  }

  @media screen and (max-width: ${JS_STYLING.TABLET_STANDARD_MAX_WIDTH}) and (orientation: portrait) {
    padding: 24px 0 50px 0 !important;
  }
  @media screen and (max-height: ${JS_STYLING.MOBILE_MAX_HEIGHT}) and (max-width: ${JS_STYLING.MOBILE_LANDSCAPE_MAX_WIDTH}) and (orientation: landscape),
    screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    margin: 0 !important;
    min-width: 100% !important;
    padding: 23px !important;
    border-radius: unset !important;
  }
`
export const MobileStepContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const MobileStep = styled.div<{ backgroundColor: string; borderColor: string }>`
  display: flex;
  height: 15px;
  width: 15px;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 50%;
  border-width: 1px;
  border-color: ${(props) => props.borderColor};
  border-style: solid;
  justify-content: center;
  align-items: center;
`
export const CheckIcon = styled(CheckOutlined)`
  color: white;
  font-size: 10px;
`
export const ProgressLine = styled.span<{ borderColor: string }>`
  flex-grow: 1;
  border-bottom: 1px solid ${(props) => props.borderColor};
  margin-top: 5px;
  margin-bottom: 7px;
`

export const StyledSteps = styled(Steps)`
  &.ant-steps .ant-steps-item-process .ant-steps-item-icon {
    border-color: ${CSS_VARS.statusBarColor};
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
    color: ${CSS_VARS.statusBarColor};
  }
  &.ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: ${CSS_VARS.statusBarColor};
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon,
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: #fff;
  }
`
