import React, { FC, useCallback } from 'react'
import { useI18n } from '../../../../hooks'
import { WrapperWithTitleAndAnimation } from '../../../../components/WrapperWithTitleAndAnimation'
import { StyledButton } from '../../../../components/General/StyledButton/StyledButton'
import { Article, ButtonContainer } from './LegalDisclaimer.styled'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { CATEGORY_HEALTH_CHECK } from '../../../../models/Matomo'

interface Props {
  onStartCheck: () => void
  onCancelCheck: () => void
}

export const LegalDisclaimer: FC<Props> = (props) => {
  const { onStartCheck, onCancelCheck } = props
  const { i18n } = useI18n()
  const { trackEvent } = useMatomo()

  const handleStartCheck = useCallback(() => {
    onStartCheck()
    trackEvent({
      category: CATEGORY_HEALTH_CHECK,
      action: 'Terms and conditions accepted',
    })
  }, [onStartCheck, trackEvent])

  return (
    <WrapperWithTitleAndAnimation title={i18n('xund.general.generalTerms')} isAnimated animationStartsWhen={true}>
      <Article>
        <p>{i18n('healthCheck.disclaimer.firstParagraph')}</p>
      </Article>

      <ButtonContainer>
        <StyledButton type="outlined" title={i18n('xund.general.accept')} onClick={handleStartCheck} />
        <StyledButton
          type="outlined"
          title={i18n('xund.general.doNotAccept')}
          style={{ marginTop: 10 }}
          onClick={onCancelCheck}
        />
      </ButtonContainer>
    </WrapperWithTitleAndAnimation>
  )
}
