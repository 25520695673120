import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDebouncedState } from 'xund-react-utils'
import { Col, Row } from 'antd'
import { useElementSize } from 'usehooks-ts'
import { BodyZone, ChatbotQuestionAnswer, Question } from '../../models'
import { WrapperWithTitleAndAnimation } from '../WrapperWithTitleAndAnimation'
import { useSearch } from '../../hooks'
import { OptionItem } from '../../models/api/response/OptionItem'
import { BodyPartQuickSelectorDetailedData } from '../../models/api/response/detailedTypeData/BodyPartQuickSelectorDetailedData'
import { SearchInput } from '../General/SearchInput/SearchInput'
import { StyledButton } from '../General/StyledButton/StyledButton'
import { assignAnswerOptionsToBodyZones, bodyZones } from '../../utils'
import { CHECK_TYPE_SYMPTOM_CHECK, useApiGatewayContext } from '../../context'
import { DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS } from '../../constants'
import { OpaqueListOverlay } from '../OpaqueListOverlay/OpaqueListOverlay'
import { BodyPartSelectorSVG } from './BodyPartSelectorSVG/BodyPartSelectorSVG'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useQuestionContext } from '../../context/QuestionContext'
import { ACTION_BODY_PART_SELECTOR, CATEGORY_ILLNESS_CHECK, CATEGORY_SYMPTOM_CHECK } from '../../models/Matomo'

export interface OptionValueWithZone extends OptionItem {
  zone?: string
}

/**
 * @param props The props object
 * @param props.question The current question
 * @param props.animationStartsWhen The boolean that decides when to start the animation
 * @param props.isLoading The boolean for whether the app is loading
 * @param props.sendAnswer The method that sends the answer
 * @returns The BodyPartSelector component
 */
export const BodyPartSelector: FC<{
  question: Question
  animationStartsWhen: boolean
  isLoading: boolean
  sendAnswer: (val: ChatbotQuestionAnswer) => void
}> = ({ question, animationStartsWhen, isLoading, sendAnswer }) => {
  const [options, setOptions] = useState<OptionValueWithZone[]>(question.options?.values || [])
  const [filteredOptions, setFilteredOptions] = useState<OptionValueWithZone[]>(question.options?.values || [])
  const { setValue: setSearchText, debouncedValue: searchText } = useDebouncedState(
    '',
    DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS,
  )
  const [selectedBodyZone, setSelectedBodyZone] = useState('')
  const [error, setError] = useState<Error>()

  const [atScrollEnd, setAtScrollEnd] = useState(false)

  const { apiGateway } = useApiGatewayContext()

  const [ref, { height }] = useElementSize()

  const { checkType } = useQuestionContext()
  const { trackEvent } = useMatomo()

  /**
   * Selects a body zone
   *
   * @param eventTarget The event target
   */
  const selectBodyZone = useCallback(
    (eventTarget: EventTarget) => {
      const targetId = (eventTarget as HTMLElement).id
      const targetParentId = (eventTarget as HTMLElement)?.parentElement?.id
      const target = (targetId || targetParentId) as BodyZone

      if (bodyZones.includes(target) && selectedBodyZone !== target) {
        setSelectedBodyZone(target)
        trackEvent({
          category: checkType === CHECK_TYPE_SYMPTOM_CHECK ? CATEGORY_SYMPTOM_CHECK : CATEGORY_ILLNESS_CHECK,
          action: ACTION_BODY_PART_SELECTOR,
          name: 'Body part selector image clicked',
        })
        setFilteredOptions(options.filter((bp) => bp.zone && bp.zone.includes(target)))
      } else {
        setSelectedBodyZone('')
        setFilteredOptions(options)
      }
    },
    [checkType, options, selectedBodyZone, trackEvent],
  )

  const handleBodyPartButtonClick = useCallback(
    (bodyPart: OptionValueWithZone) => {
      trackEvent({
        category: checkType === CHECK_TYPE_SYMPTOM_CHECK ? CATEGORY_SYMPTOM_CHECK : CATEGORY_ILLNESS_CHECK,
        action: ACTION_BODY_PART_SELECTOR,
        name: 'Body part selected',
      })
      sendAnswer({ questionId: question.id, answer: { id: bodyPart.id } })
    },
    [checkType, question.id, sendAnswer, trackEvent],
  )

  const getOptions = useSearch

  useEffect(() => {
    if (searchText) {
      getOptions(setOptions, setError, apiGateway, searchText)
      trackEvent({
        category: checkType === CHECK_TYPE_SYMPTOM_CHECK ? CATEGORY_SYMPTOM_CHECK : CATEGORY_ILLNESS_CHECK,
        action: ACTION_BODY_PART_SELECTOR,
        name: 'Body part search used',
      })
    } else {
      setOptions(question.options?.values as OptionValueWithZone[])
    }
  }, [getOptions, searchText, question.options?.values, apiGateway, trackEvent, checkType])

  useEffect(() => {
    if (options.length && options.every((item) => !item.zone)) {
      setOptions(
        options.map((option) => ({
          ...option,
          zone: assignAnswerOptionsToBodyZones(option.code as string),
        })) as OptionValueWithZone[],
      )
      setFilteredOptions(
        options.map((option) => ({
          ...option,
          zone: assignAnswerOptionsToBodyZones(option.code as string),
        })) as OptionValueWithZone[],
      )
    }
  }, [options])

  if (error) {
    throw error
  }

  return (
    <WrapperWithTitleAndAnimation
      title={question.text}
      isAnimated
      animationStartsWhen={animationStartsWhen}
      style={{ justifyContent: 'initial' }}
      hasTopMargin={false}
    >
      <SearchInput setSearchText={setSearchText} />
      <Row style={{ marginTop: 20, marginBottom: 20 }} justify="space-between">
        <Col lg={{ span: 10 }} xs={{ span: 8 }}>
          <div className="bodySvgContainer">
            <BodyPartSelectorSVG
              gender={(question.detailedTypeData as BodyPartQuickSelectorDetailedData)?.gender}
              selectBodyZone={selectBodyZone}
              isLoading={isLoading}
            />
          </div>
        </Col>

        <Col lg={{ span: 12 }} xs={{ span: 12, offset: 0 }}>
          <OpaqueListOverlay isHidden={atScrollEnd} height={height} />
          <div
            className="buttonContainer bodyPartSelectorList"
            onScroll={(event) => {
              const target = event.target as HTMLElement
              setAtScrollEnd(target.offsetHeight + target.scrollTop >= target.scrollHeight)
            }}
          >
            {filteredOptions.map((item, index) => {
              return (
                <span
                  ref={ref}
                  key={item.id}
                  style={{ marginTop: index === 0 ? 0 : 12, marginBottom: 5, width: '100%', minHeight: 24 }}
                >
                  <StyledButton
                    type="outlined"
                    title={item.text}
                    size="middle"
                    style={{ width: '100%', minHeight: 24 }}
                    disabled={isLoading}
                    onClick={() => handleBodyPartButtonClick(item)}
                  />
                </span>
              )
            })}
          </div>
        </Col>
      </Row>
    </WrapperWithTitleAndAnimation>
  )
}
