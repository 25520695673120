import React, { FC } from 'react'
import { Col } from 'antd'
import { ProtectiveRiskFactor } from 'health-check-api'
import { Flex } from '../Flex'
import { useI18n } from '../../../../hooks'
import { PositiveRiskFactorContainer, RiskFactorPill, SectionTitle, StyledRow, StyledText } from './Report.styled'

interface Props {
  protectiveRiskFactors: ProtectiveRiskFactor[]
  riskTopicName?: string
}

export const ProtectiveRiskFactorSection: FC<Props> = (props) => {
  const { protectiveRiskFactors, riskTopicName } = props
  const { i18n } = useI18n()

  return (
    <>
      <StyledRow gutter={[0, 10]} margin="0 0 20px 0">
        <Col span={24}>
          <Flex alignItems="center">
            {riskTopicName && (
              <SectionTitle>
                {i18n('healthCheck.report.protectiveRiskFactor.titleDetailed', { topicName: riskTopicName }).replace(
                  '%1$s',
                  riskTopicName,
                )}
              </SectionTitle>
            )}
            {!riskTopicName && <SectionTitle>{i18n('healthCheck.report.protectiveRiskFactor.title')}</SectionTitle>}
          </Flex>
        </Col>
        <Col span={24}>
          <StyledText>
            {protectiveRiskFactors.length === 0 &&
              i18n('healthCheck.report.protectiveRiskFactor.emptyState.description')}
            {riskTopicName &&
              protectiveRiskFactors.length !== 0 &&
              i18n('healthCheck.report.protectiveRiskFactor.descriptionDetailed', { topicName: riskTopicName }).replace(
                '%1$s',
                riskTopicName,
              )}
            {!riskTopicName &&
              protectiveRiskFactors.length !== 0 &&
              i18n('healthCheck.report.protectiveRiskFactor.description')}
          </StyledText>
        </Col>
      </StyledRow>
      <StyledRow margin="0 0 50px 0">
        <Col span={24}>
          {protectiveRiskFactors.length > 0 && (
            <PositiveRiskFactorContainer>
              {protectiveRiskFactors.map((riskFactor) => (
                <RiskFactorPill key={`positive-risk-factor-${riskFactor.factor}`}>{riskFactor.factor}</RiskFactorPill>
              ))}
            </PositiveRiskFactorContainer>
          )}
        </Col>
      </StyledRow>
    </>
  )
}
