import React, { ComponentPropsWithoutRef, ElementRef, ElementType, forwardRef } from 'react'

import { Text } from './Text'

export interface HeadingProps {
  as?: ElementType
}

const DEFAULT_HEADING_TAG: ElementType = 'div'

export const Heading = forwardRef<
  ElementRef<typeof DEFAULT_HEADING_TAG>,
  HeadingProps & ComponentPropsWithoutRef<typeof DEFAULT_HEADING_TAG>
>(({ children, as, ...props }) => {
  return (
    <Text as={as} {...props}>
      {children}
    </Text>
  )
})
