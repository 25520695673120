import { useCallback, useMemo } from 'react'
import axios from 'axios'
import {
  AnswerResponse,
  answerRoute,
  BackResponse,
  backRoute,
  FamilyHistoryAnswer,
  InitializeResponse,
  initializeRoute,
  QuestionResponse,
  questionRoute,
  reportPdfRoute,
  ReportResponse,
  reportRoute,
} from 'health-check-api'
import { useLanguage } from '../../../hooks'
import { useAuthToken } from '../../../hooks/useAuthToken'
import { isUseExaminationUrlsHCE181 } from '../../../utils/envHelpers'
import { ExaminationResponse, getPartnerExaminationsEndpoint } from 'ch-common-schemas'
import { Gender } from 'health-check-types'
import { denormalizeFamilyHistoryAnswer, transformInitializeBody } from '../utils/transformAnswer'

export type HealthCheckApi = {
  initialize: (
    age?: number,
    gender?: Gender,
    height?: number,
    weight?: number,
    selectedRiskTopics?: string[],
  ) => Promise<string>
  getQuestion: (checkId: string) => Promise<QuestionResponse>
  setAnswer: (
    checkId: string,
    questionId: string,
    answer: string | null | string[] | FamilyHistoryAnswer,
  ) => Promise<AnswerResponse>
  back: (checkId: string) => Promise<BackResponse>
  getReport: (checkId: string) => Promise<ReportResponse>
  getPDF: (checkId: string) => Promise<Buffer>
  getExaminationUrls: (examinationIds: string[]) => Promise<{ items: ExaminationResponse[] } | undefined>
}

export const useApi = (): HealthCheckApi => {
  const { currentLanguage } = useLanguage()
  const token = useAuthToken()

  const baseApi = useMemo(() => {
    return axios.create({
      baseURL: window.xundEnvironment.HEALTH_CHECK_API_BASE_URL,
      timeout: 30000,
    })
  }, [])

  const chBackendApi = useMemo(() => {
    return axios.create({
      baseURL: window.xundEnvironment.PP_INTERNAL_API_BASE_URL,
      timeout: 30000,
    })
  }, [])

  const initialize = useCallback(
    async (
      age?: number,
      gender?: Gender,
      height?: number,
      weight?: number,
      selectedRiskTopics?: string[],
    ): Promise<string> => {
      const body = transformInitializeBody({ age, gender, height, weight, selectedRiskTopics })
      const response = await baseApi.post<InitializeResponse>(initializeRoute.path, body, {
        headers: {
          Language: currentLanguage,
          Authorization: `Bearer ${token}`,
        },
      })

      return response.data.checkId
    },
    [baseApi, token, currentLanguage],
  )

  const getQuestion = useCallback(
    async (checkId: string): Promise<QuestionResponse> => {
      const response = await baseApi.get<QuestionResponse>(questionRoute.path, {
        headers: {
          Language: currentLanguage,
          Authorization: `Bearer ${token}`,
          'Check-Id': checkId,
        },
      })

      return response.data
    },
    [baseApi, token, currentLanguage],
  )

  const setAnswer = useCallback(
    async (
      checkId: string,
      questionId: string,
      answer: string | null | string[] | FamilyHistoryAnswer,
    ): Promise<AnswerResponse> => {
      if (Array.isArray(answer?.[0])) {
        answer = denormalizeFamilyHistoryAnswer(answer)
      }
      const response = await baseApi.post<AnswerResponse>(
        answerRoute.path,
        { questionId, answer },
        {
          headers: {
            Language: currentLanguage,
            Authorization: `Bearer ${token}`,
            'Check-Id': checkId,
          },
        },
      )

      return response.data
    },
    [baseApi, currentLanguage, token],
  )

  const back = useCallback(
    async (checkId: string): Promise<BackResponse> => {
      const response = await baseApi.post<BackResponse>(
        backRoute.path,
        {},
        {
          headers: {
            Language: currentLanguage,
            Authorization: `Bearer ${token}`,
            'Check-Id': checkId,
          },
        },
      )

      return response.data
    },
    [baseApi, token, currentLanguage],
  )

  const getReport = useCallback(
    async (checkId: string): Promise<ReportResponse> => {
      const response = await baseApi.get<ReportResponse>(reportRoute.path, {
        headers: {
          Language: currentLanguage,
          Authorization: `Bearer ${token}`,
          'Check-Id': checkId,
        },
      })

      return response.data
    },
    [baseApi, token, currentLanguage],
  )

  const getPDF = useCallback(
    async (checkId: string): Promise<Buffer> => {
      const response = await baseApi.get(reportPdfRoute.path, {
        headers: {
          Language: currentLanguage,
          Authorization: `Bearer ${token}`,
          'Check-Id': checkId,
        },
        responseType: 'blob',
      })

      return response.data
    },
    [baseApi, token, currentLanguage],
  )

  const getExaminationUrls = useCallback(
    async (examinationIds: string[]): Promise<{ items: ExaminationResponse[] } | undefined> => {
      if (token && examinationIds.length > 0 && isUseExaminationUrlsHCE181()) {
        const examinationParam = examinationIds.map((id) => `"${id}"`).join(',')

        const response = await chBackendApi.get(
          `${getPartnerExaminationsEndpoint.path}?examinationIds=[${examinationParam}]`,
          {
            headers: {
              Language: currentLanguage,
              Authorization: `Bearer ${token}`,
            },
          },
        )
        return response.data
      }
    },
    [chBackendApi, currentLanguage, token],
  )

  return useMemo(
    (): HealthCheckApi => ({ initialize, getQuestion, setAnswer, back, getReport, getPDF, getExaminationUrls }),
    [initialize, getQuestion, setAnswer, back, getReport, getPDF, getExaminationUrls],
  )
}
