import { Typography } from 'antd'
import React from 'react'
import droppy from '../../../assets/images/droppy-refresh.svg'
import { useI18n } from '../../../hooks'
import { StyledButton } from '../StyledButton/StyledButton'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import styles from './TokenExpiredRefresh.module.less'

export const TokenExpiredRefresh = () => {
  const { i18n } = useI18n()

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <img src={droppy} className={styles.droppy} alt="Page not found" />

      <Typography.Text
        style={{
          color: CSS_VARS.DARK_BLUE_COLOR,
          textAlign: 'center',
          fontSize: '26px',
          fontFamily: 'Nunito Sans',
          fontWeight: 'bold',
        }}
      >
        {i18n('errors.sessionExpired.title')}
      </Typography.Text>

      <Typography.Text
        style={{
          color: CSS_VARS.DARK_BLUE_COLOR,
          textAlign: 'center',
          fontSize: '26px',
          lineHeight: 1,
          fontFamily: 'Nunito Sans',
        }}
      >
        {i18n('errors.sessionExpired.message')}
      </Typography.Text>

      <StyledButton
        title="Refresh"
        type="outlined"
        style={{
          marginTop: '2rem',
          fontSize: '18px',
          color: CSS_VARS.PRIMARY_COLOR,
          borderColor: CSS_VARS.PRIMARY_COLOR,
        }}
        onClick={() => window.parent.postMessage({ type: 'refresh' }, '*')}
        size="large"
      />
    </div>
  )
}
