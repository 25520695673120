import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { ErrorResponseType } from '../components/ErrorBoundary'
import { SupportedLanguage, WebAppConfig, WebAppStyling } from '../models'
import { appendCustomizedStylesToDOM } from '../resources/customizedAntDesignStylings'
import { defaultWebAppConfig, defaultWebAppStyling } from '../resources/defaultWebAppConfig'
import initCssVariableConfig, { JS_STYLING } from '../resources/cssVariableConfig'
import { useBusinessAdmin } from './useBusinessAdmin'

const defaultConfig = {
  ...defaultWebAppConfig,
  welcomeGreeting: {},
  welcomeMessage: {},
  endCheckMessage: {},
  termsAndConditionsExplanatoryText: {},
}

export const useWebAppConfigLoader = (token: string | null) => {
  const [webAppConfig, setWebAppConfig] = useState<WebAppConfig>(defaultConfig)
  const [isWebAppConfigLoading, setIsWebAppConfigLoading] = useState(true)
  const [webAppConfigError, setWebAppConfigError] = useState<Error | ErrorResponseType | null>(null)

  const { code } = useParams<{ code: string }>()

  const { businessAdmin } = useBusinessAdmin(token)
  const isSoteMode = window.xundEnvironment.IS_SOTE_MODE === 'true'

  const isConfiguredWebapp = !!code || isSoteMode

  const getWebAppConfig = useCallback(async () => {
    const urlCode = isSoteMode ? 'SOTE' : code

    try {
      if (!token) {
        return
      }
      setIsWebAppConfigLoading(true)

      let config: WebAppConfig = defaultConfig
      let styling: WebAppStyling = defaultWebAppStyling
      window.parent.postMessage({ title: config.browserPageTitle, favicon: config.favicon }, '*')

      if (urlCode?.length) {
        const response = await businessAdmin.get(`/webAppConfigs/${urlCode}`)

        const { styling: customStyling, ...customConfig } = response.data

        styling = customStyling
        config = customConfig

        window.parent.postMessage({ title: customConfig.browserPageTitle, favicon: customConfig.favicon }, '*')
      }

      const currentLanguage: SupportedLanguage | null = window.appStorage.getItem(
        'xundWebAppLanguage',
      ) as SupportedLanguage

      if (currentLanguage) {
        const isCurrentLanguageSupported = config.supportedLanguages.includes(currentLanguage)

        if (!isCurrentLanguageSupported) {
          window.appStorage.removeItem('xundWebAppLanguage')
        }
      }

      setWebAppConfig({ ...config, urlCode })
      initCssVariableConfig(styling, code)

      loadWebAppFonts(JS_STYLING.fontCssUrl)
      appendCustomizedStylesToDOM()

      document.title = config.browserPageTitle

      if (config.favicon) {
        const favicon = document.getElementById('favicon') as HTMLLinkElement
        favicon.href = config.favicon
      }
    } catch (err) {
      setWebAppConfigError(err as Error)
    }

    setIsWebAppConfigLoading(false)
  }, [code, isSoteMode, businessAdmin, token])

  /**
   * Load the fonts for the given Web App
   *
   * @param cssUrl The URL for the stylesheet to be loaded
   */
  const loadWebAppFonts = (cssUrl: string) => {
    const fontLink = document.createElement('link')
    fontLink.type = 'text/css'
    fontLink.rel = 'stylesheet'
    fontLink.href = cssUrl
    document.head.appendChild(fontLink)
  }

  useEffect(() => {
    getWebAppConfig()
  }, [getWebAppConfig])

  return { webAppConfig, isWebAppConfigLoading, webAppConfigError, isConfiguredWebapp }
}
