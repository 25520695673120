import React, { FC, useCallback, useMemo, useState } from 'react'
import {
  MedicalEngineIllnessCheckReport,
  MedicalEngineReportCheckedIllness,
  MedicalEngineReportIllness,
  MedicalEngineSymptomCheckReport,
} from 'medical-engine-api'
import { Col, Row } from 'antd'
import { useWindowSize } from 'usehooks-ts'
import styled from '@emotion/styled'
import { WrapperWithTitleAndAnimation } from '../WrapperWithTitleAndAnimation'
import { mobileMaxWidth, useI18n } from '../../hooks'
import { useQuestionContext } from '../../context/QuestionContext'
import { capitalizeString } from '../../utils/capitalizeString'
import './Report.module.less'
import { CSS_VARS } from '../../resources/cssVariableConfig'
import { CHECK_TYPE_ILLNESS_CHECK, CHECK_TYPE_SYMPTOM_CHECK, useWebAppConfigContext } from '../../context'
import { FetchVirtualUrlServices } from './VirtualUrlServices'
import { ReportItem } from './ReportItem/ReportItem'

const StyledIframe = styled.iframe`
  width: 640px;
  height: 600px;
  border: none;
`

export interface MedicalEngineReportIllnessWithIllnessCategoryIdentifier extends MedicalEngineReportIllness {
  illnessCategoryIdentifier: string
}

const defaultNumberOfIllnessesToShow = 3

/**
 * @param props The props object
 * @param props.reportResponse The report data
 * @param props.animationStartsWhen The boolean that decides when to start the animation
 * @param props.fetchVirtualUrlServices Function to fetch virtual url services
 * @param props.location Geolocation of the user (if not given, virtual url services won't be displayed)
 * @returns The Report component
 */
export const Report: FC<{
  //TODO Rename if IllnessCheckReport is deleted
  reportResponse: MedicalEngineSymptomCheckReport | MedicalEngineIllnessCheckReport
  animationStartsWhen: boolean
  fetchVirtualUrlServices?: FetchVirtualUrlServices
  location?: GeolocationPosition | null
}> = ({ reportResponse, animationStartsWhen, fetchVirtualUrlServices, location }) => {
  const [loadLessProbableIllnesses, setLoadLessProbableIllnesses] = useState(false)

  const { i18n } = useI18n()
  const { checkType } = useQuestionContext()

  const {
    webAppConfig: { illnessCheckQuestionnaires },
  } = useWebAppConfigContext()

  const lowRiskRocheFormUrl = illnessCheckQuestionnaires?.lowRiskUrl
  const mediumRiskRocheFormUrl = illnessCheckQuestionnaires?.mediumRiskUrl
  const highRiskRocheFormUrl = illnessCheckQuestionnaires?.highRiskUrl

  const rocheFormToShow = useMemo(() => {
    if ('checkedIllness' in reportResponse) {
      if (reportResponse.checkedIllness.score < 33) {
        return lowRiskRocheFormUrl
      } else if (reportResponse.checkedIllness.score < 67) {
        return mediumRiskRocheFormUrl
      } else {
        return highRiskRocheFormUrl
      }
    }

    return undefined
  }, [highRiskRocheFormUrl, lowRiskRocheFormUrl, mediumRiskRocheFormUrl, reportResponse])

  const illnessWithCategoryIdentifier: MedicalEngineReportIllnessWithIllnessCategoryIdentifier[] = useMemo(
    () =>
      reportResponse.illnessCategories
        ?.map((illness) =>
          illness.illnesses.map((iterationIllness) => ({
            illnessCategoryIdentifier: illness.icon,
            ...iterationIllness,
          })),
        )
        .flat()
        .sort((a, b) => b.score - a.score),
    [reportResponse.illnessCategories],
  )

  const hasLessProbableIllnesses = useMemo(
    () => (illnessWithCategoryIdentifier.length ?? 0) > defaultNumberOfIllnessesToShow,
    [illnessWithCategoryIdentifier.length],
  )

  const getCheckedIllnessReportItem = useCallback(
    (checkedIllness: MedicalEngineReportCheckedIllness) => (
      <ReportItem
        key={checkedIllness?.id}
        illnessIndex={0}
        illness={checkedIllness}
        svgIcon={checkedIllness?.category.icon}
        fetchVirtualUrlServices={fetchVirtualUrlServices}
        location={location}
        startOpen={true}
        showFactorScores={true}
      />
    ),
    [fetchVirtualUrlServices, location],
  )

  /**
   * @param reportIllnesses The report illnesses
   * @returns The report items
   */
  const getReportItems = useMemo(
    () =>
      (reportIllnesses: MedicalEngineReportIllnessWithIllnessCategoryIdentifier[], beginningOfList = true) =>
        reportIllnesses.map((illness, index) => (
          <ReportItem
            key={illness?.id}
            illnessIndex={index}
            illness={illness}
            svgIcon={illness?.illnessCategoryIdentifier}
            fetchVirtualUrlServices={fetchVirtualUrlServices}
            location={location}
            startOpen={beginningOfList && index === 0}
          />
        )),
    [fetchVirtualUrlServices, location],
  )

  const { width: windowWidth } = useWindowSize()

  const isMobile = useMemo(() => windowWidth <= mobileMaxWidth, [windowWidth])

  return (
    <>
      {checkType === CHECK_TYPE_ILLNESS_CHECK && reportResponse && (
        <WrapperWithTitleAndAnimation
          title={(reportResponse as MedicalEngineIllnessCheckReport).checkedIllness.factorScores.title}
          isAnimated
          animationStartsWhen={animationStartsWhen}
          style={{
            marginTop: 6,
            height: 'fit-content',
            marginRight: isMobile ? 0 : 44,
            marginLeft: isMobile ? 0 : 44,
            marginBottom: 30,
          }}
        >
          <>{getCheckedIllnessReportItem((reportResponse as MedicalEngineIllnessCheckReport).checkedIllness)}</>
        </WrapperWithTitleAndAnimation>
      )}

      {rocheFormToShow && (
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
          <StyledIframe src={rocheFormToShow} />
        </div>
      )}

      {Boolean(reportResponse?.illnessCategories?.length) && (
        <WrapperWithTitleAndAnimation
          title={
            checkType === CHECK_TYPE_ILLNESS_CHECK ? i18n('xund.general.possibleCauses') : i18n('xund.general.results')
          }
          isAnimated
          animationStartsWhen={animationStartsWhen}
          style={{
            marginTop: 6,
            height: 'fit-content',
            marginRight: isMobile ? 0 : 44,
            marginLeft: isMobile ? 0 : 44,
          }}
        >
          <div className="buttonContainer" style={{ marginTop: 27, height: 'fit-content' }}>
            {getReportItems(
              illnessWithCategoryIdentifier.slice(0, defaultNumberOfIllnessesToShow),
              checkType === CHECK_TYPE_SYMPTOM_CHECK,
            )}

            {loadLessProbableIllnesses &&
              hasLessProbableIllnesses &&
              !!illnessWithCategoryIdentifier?.length &&
              getReportItems(illnessWithCategoryIdentifier.slice(defaultNumberOfIllnessesToShow), false)}
          </div>

          {illnessWithCategoryIdentifier.length > defaultNumberOfIllnessesToShow && (
            <Row justify="end">
              <p
                onClick={() => setLoadLessProbableIllnesses(!loadLessProbableIllnesses && hasLessProbableIllnesses)}
                style={{
                  color: CSS_VARS.skipButtonFontColor,
                  fontSize: CSS_VARS.chatFontSize,
                  cursor: 'pointer',
                  paddingRight: 20,
                  marginTop: 20,
                }}
              >
                {capitalizeString(
                  i18n(
                    !loadLessProbableIllnesses && hasLessProbableIllnesses
                      ? 'xund.general.showMore'
                      : 'xund.general.showLess',
                  ),
                )}
              </p>
            </Row>
          )}
        </WrapperWithTitleAndAnimation>
      )}

      <WrapperWithTitleAndAnimation
        isAnimated
        animationStartsWhen={animationStartsWhen}
        style={{
          marginTop: 6,
          height: 'fit-content',
          marginRight: isMobile ? 0 : 44,
          marginLeft: isMobile ? 0 : 44,
        }}
      >
        <Row>
          <Col>
            <div
              style={{
                fontSize: CSS_VARS.chatFontSize,
                fontWeight: CSS_VARS.chatFontWeight,
                marginTop: 24,
                marginBottom: 52,
              }}
            >
              <h4
                style={{
                  color: CSS_VARS.questionFontColor,
                  fontSize: CSS_VARS.questionFontSize,
                  fontWeight: CSS_VARS.questionFontWeight,
                  marginBottom: 3,
                }}
              >
                {i18n('xund.general.important')}
              </h4>

              <p style={{ lineHeight: '1.3', textAlign: 'justify' }}>{i18n('xund.report.bottomBoxText')}</p>
            </div>
          </Col>
        </Row>
      </WrapperWithTitleAndAnimation>
    </>
  )
}
