import React, { FC, useCallback, useEffect, useState } from 'react'
import { useI18n } from '../../../../hooks'
import { SelectQuestionWrapper } from '../SelectQuestionWrapper'
import { MAX_AGE, MIN_AGE } from 'health-check-api'
import { NumberInput } from '../../../../common'
import { useDebounce } from 'usehooks-ts'

interface Props {
  title: string
  isLoading: boolean
  onSelect: (answer: string | null) => void
}

export const AgeInput: FC<Props> = (props) => {
  const { title, isLoading, onSelect } = props
  const { i18n } = useI18n()

  const [ageValue, setAgeValue] = useState<number | null>()
  const debouncedValue = useDebounce(ageValue, 300)

  const isAgeValid = useCallback((value: number | null | undefined) => {
    if (typeof value !== 'number') {
      return false
    } else {
      return value >= MIN_AGE && value <= MAX_AGE
    }
  }, [])

  const onChange = useCallback((value: unknown) => {
    if (typeof value !== 'number') {
      return
    }
    setAgeValue(value)
  }, [])

  useEffect(() => {
    if (isAgeValid(debouncedValue)) {
      onSelect((debouncedValue as unknown as number).toString())
    } else {
      onSelect(null)
    }
  }, [debouncedValue, isAgeValid, onSelect])

  return (
    <SelectQuestionWrapper title={title} isLoading={isLoading}>
      <NumberInput
        label=""
        placeholder={i18n('components.age.placeholder')}
        isErroneous={debouncedValue !== null && debouncedValue !== undefined ? !isAgeValid(debouncedValue) : false}
        errorMessage={i18n('healthCheck.age.error')}
        onChange={(value) => onChange(value)}
      />
    </SelectQuestionWrapper>
  )
}
