import { Card, Typography } from 'antd'
import React, { FC } from 'react'
import { useServicesContext } from '../../../../context'
import { useI18n } from '../../../../hooks'
import { ServiceWithDetailedOpeningHours } from '../../../../models'
import { ListResponse } from '../../../../models/ListResponse'
import { LoadingIndicator } from '../../../LoadingIndicator/LoadingIndicator'
import { SearchItemMobile } from '../SearchItemMobile/SearchItemMobile'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'
import styles from './SearchItemLabeledListMobile.module.less'

/**
 * @param props The props object
 * @param props.servicesData The services data to display
 * @param props.isListVisible Whether the list of services is visible
 * @param props.isLoading Whether the application is loading
 * @returns The SearchItemLabeledListMobile component
 */
export const SearchItemLabeledListMobile: FC<{
  servicesData: ListResponse<ServiceWithDetailedOpeningHours>
  isListVisible: boolean
  isLoading: boolean
}> = ({ servicesData, isListVisible, isLoading }) => {
  const { i18n } = useI18n()

  const { setSelectedService } = useServicesContext()

  return (
    <>
      {isLoading && (
        <div style={{ marginTop: '50%', width: '100%', position: 'absolute', zIndex: 300 }}>
          <LoadingIndicator />
        </div>
      )}

      {isListVisible && (
        <div>
          {servicesData?.items?.map((service, index) => (
            <SearchItemMobile
              key={`${index}-${service.id}`}
              service={service}
              isLoading={isLoading}
              onClick={(ser) => setSelectedService(ser)}
            />
          ))}

          {!servicesData?.count && !isLoading && (
            <Card className={styles.noResults}>
              <Typography.Title level={5} style={{ color: CSS_VARS.questionFontColor, marginBottom: 0 }}>
                {i18n('xund.services.filters.noResults')}
              </Typography.Title>
            </Card>
          )}
        </div>
      )}
    </>
  )
}
