import { QuestionResponse, QUESTION_STATE_REPORT, QUESTION_TYPE_CONFIRM } from 'health-check-api'

export const mockInfoQuestion: QuestionResponse = {
  id: 'infoId',
  text: 'Title text;Subtext',
  description: '',
  type: QUESTION_TYPE_CONFIRM,
  state: QUESTION_STATE_REPORT,
  answers: [],
  params: null,
  canMoveBack: true,
}
