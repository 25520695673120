import React, { FC, useMemo } from 'react'
import { Col, Row } from 'antd'
import { RiskTopic } from 'health-check-api'
import { mobileMaxWidth, useI18n } from '../../../../hooks'
import { RiskDescriptionContainer, SectionTitle, StyledRow, StyledText } from './Report.styled'
import { useWindowSize } from 'usehooks-ts'
import { RiskCategory } from 'health-check-types'
import { CSSTransition } from 'react-transition-group'
import { RiskClassesBox } from './ExplanationSection/RiskClassesBox'

interface Props {
  riskTopic: Omit<RiskTopic, 'riskFactors'>
}

export const ScoreSection: FC<Props> = (props) => {
  const { riskTopic } = props
  const { i18n } = useI18n()
  const { width: windowWidth } = useWindowSize()

  const isMobile = useMemo(() => windowWidth <= mobileMaxWidth, [windowWidth])

  return (
    <CSSTransition in={true} timeout={500} classNames="innerWindowAnimation" appear={true} unmountOnExit={true}>
      <div>
        <StyledRow gutter={isMobile ? [20, 20] : [32, 32]} margin={isMobile ? '5px 0 60px 0' : '54px 0 60px 0'}>
          <Col lg={8} md={24} xs={24}>
            <RiskClassesBox selectedRiskCategory={riskTopic.score as RiskCategory} selectedRiskTopic={riskTopic} />
          </Col>
          <Col lg={16} md={24} xs={24}>
            <RiskDescriptionContainer>
              <StyledRow margin="0 0 35px 0">
                <Col span={24}>
                  <SectionTitle>{`${i18n('healthCheck.report.riskTopicDescription')} ${riskTopic.name}`}</SectionTitle>
                </Col>
              </StyledRow>
              <Row>
                <Col span={24}>
                  <StyledText>{riskTopic.description}</StyledText>
                </Col>
              </Row>
            </RiskDescriptionContainer>
          </Col>
        </StyledRow>
      </div>
    </CSSTransition>
  )
}
