import styled from '@emotion/styled'
import { CSS_VARS } from '../../../../../resources/cssVariableConfig'
import { StyledButton } from '../../../../../components/General/StyledButton/StyledButton'

export const BaseButton = styled(StyledButton)`
  height: 30px;
`

export const CheckIdText = styled.span`
  font-family: ${CSS_VARS.fontFamily};
`
