import styled from '@emotion/styled'
import { CSS_VARS, JS_STYLING } from '../../../../../resources/cssVariableConfig'

export const DetailedModeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 22px 180px 40px 180px;
  flex-wrap: wrap;
  row-gap: 40px;
  @media (max-width: ${JS_STYLING.TABLET_MAX_WIDTH}) {
    margin: 0 22px 50px 22px;
    flex-wrap: wrap-reverse;
  }
`

export const MainTitle = styled.h1`
  color: ${CSS_VARS.questionFontColor};
  font-family: ${CSS_VARS.fontFamily};
  font-size: 30px;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 0;
  grid-area: title;
  @media (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    width: 100%;
  }
`
