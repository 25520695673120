import React, { FC, useCallback, useEffect, useState } from 'react'
import Modal from 'antd/lib/modal/Modal'
import { useElementSize } from 'usehooks-ts'
import { Col, Row, Select } from 'antd'
import { WrapperWithTitleAndAnimation } from '../WrapperWithTitleAndAnimation'
import { AddedSymptom, OverviewResponse, Question, ResponseType } from '../../models'
import { useI18n } from '../../hooks'
import { StyledButton } from '../General/StyledButton/StyledButton'
import { ModalText } from '../Modals/ModalText/ModalText'
import { useApiGatewayContext } from '../../context'
import { SymptomSurveyDetailedData } from '../../models/api/response/detailedTypeData/SymptomSurveyDetailedData'
import { OpaqueListOverlay } from '../OpaqueListOverlay/OpaqueListOverlay'
import { useQuestionContext } from '../../context/QuestionContext'
import { CSS_VARS } from '../../resources/cssVariableConfig'
import { OverviewSymptomItem } from './OverviewSymptomItem/OverviewSymptomItem'
import styles from './Overview.module.less'

const ROW_GAP_SIZE = 5

/**
 * @param props The props object
 * @param props.question The current question
 * @param props.animationStartsWhen The boolean that decides when to start the animation
 * @param props.setIsOverview Sets the setIsOverview state boolean
 * @param props.chatbotQuestionData The previous questions
 * @param props.setChatbotQuestionData The setter for the previous questions
 * @returns The Overview component
 */
export const Overview: FC<{
  question: OverviewResponse
  chatbotQuestionData: ResponseType[]
  animationStartsWhen: boolean
  setChatbotQuestionData: (val: ResponseType[]) => void
  setIsOverview: (val: boolean) => void
}> = ({ question, chatbotQuestionData, animationStartsWhen, setChatbotQuestionData, setIsOverview }) => {
  const [overviewData, setOverviewData] = useState<OverviewResponse | null>(question)

  const [reloadOverviewData, setReloadOverviewData] = useState(false)
  const [currentOverviewSymptom, setCurrentOverviewSymptom] = useState<AddedSymptom | null>(null)
  const [currentOverviewSymptomQuestions, setCurrentOverviewSymptomQuestions] = useState<Question[] | null>(null)
  const [isDeleteQuestion, setIsDeleteQuestion] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error>()
  const [atScrollEnd, setAtScrollEnd] = useState(false)

  const [ref, { height }] = useElementSize()

  const { i18n } = useI18n()
  const { apiGateway } = useApiGatewayContext()
  const { nextButtonLogic, setNextButtonLogic, setCurrentResponse } = useQuestionContext()

  const getOverview = useCallback(async () => {
    setIsLoading(true)

    try {
      const { data } = await apiGateway.get<OverviewResponse>('/v1/chatbot/overview')

      setOverviewData(data)
    } catch (err) {
      setError(err as Error)
    } finally {
      setIsLoading(false)
    }
  }, [apiGateway])

  const addSymptomToOverview = useCallback(
    async (id: string) => {
      if (!isLoading) {
        setIsLoading(true)

        try {
          await apiGateway.post('/v1/chatbot/overview/symptoms', { symptomIds: [id] })

          setReloadOverviewData(true)
        } catch (err) {
          setError(err as Error)
        } finally {
          setIsLoading(false)
        }
      }
    },
    [apiGateway, isLoading],
  )

  const removeSymptomFromOverview = useCallback(
    async (id: string) => {
      if (!isLoading) {
        setIsLoading(true)

        try {
          await apiGateway.delete(`/v1/chatbot/overview/symptoms/${id}`)

          setChatbotQuestionData(
            chatbotQuestionData.filter(
              (item) => ((item as Question).detailedTypeData as SymptomSurveyDetailedData)?.linkedSymptom?.id !== id,
            ),
          )

          setReloadOverviewData(true)
        } catch (err) {
          setError(err as Error)
        } finally {
          setIsDeleteQuestion(false)
          setCurrentOverviewSymptom(null)
          setIsLoading(false)
        }
      }
    },

    [apiGateway, chatbotQuestionData, isLoading, setChatbotQuestionData],
  )

  const getCurrentOverviewSymptomQuestion = useCallback(
    async (id: string) => {
      setIsLoading(true)

      try {
        const { data } = await apiGateway.get<Question[]>(`/v1/chatbot/overview/symptoms/${id}/questions`)

        setCurrentOverviewSymptomQuestions(data)
      } catch (err) {
        setError(err as Error)
      } finally {
        setIsLoading(false)
      }
    },
    [apiGateway],
  )

  const answerOverviewSymptomQuestion = useCallback(
    async (questionId: string, id: string) => {
      if (!isLoading) {
        setIsLoading(true)

        const answer = id ? { questionId, answer: { id } } : { questionId }

        try {
          await apiGateway.patch(`/v1/chatbot/overview/answer`, answer)
        } catch (err) {
          setError(err as Error)
        } finally {
          setIsLoading(false)
        }
      }
    },
    [apiGateway, isLoading],
  )

  const confirmOverview = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true)

      try {
        await apiGateway.post('/v1/chatbot/overview/confirm')
      } catch (err) {
        setError(err as Error)
      } finally {
        setIsLoading(false)
        setCurrentResponse(null)
        setIsOverview(false)
      }
    }
  }, [apiGateway, isLoading, setIsOverview, setCurrentResponse])

  useEffect(() => {
    if (reloadOverviewData) {
      setReloadOverviewData(false)
      getOverview()
    }
  }, [reloadOverviewData, getOverview])

  useEffect(() => {
    if (!nextButtonLogic) {
      setNextButtonLogic(() => confirmOverview)
    }
  }, [isLoading, nextButtonLogic, setNextButtonLogic, confirmOverview])

  /**
   * @returns An edit button if it's necessary
   */
  const displayEditButtonIfNeeded = useCallback(() => {
    return (
      currentOverviewSymptom?.actionInfo.actions.find((action) => action.type === 'EDIT') && (
        <StyledButton
          type="filled"
          disabled={isLoading}
          title={currentOverviewSymptom?.actionInfo.actions.find((action) => action.type === 'EDIT')?.title as string}
          style={{ display: 'block', width: '100%', marginBottom: 10 }}
          onClick={() => getCurrentOverviewSymptomQuestion(currentOverviewSymptom.id)}
        />
      )
    )
  }, [
    currentOverviewSymptom?.actionInfo.actions,
    currentOverviewSymptom?.id,
    getCurrentOverviewSymptomQuestion,
    isLoading,
  ])

  if (error) {
    throw error
  }

  return (
    <div style={{ position: 'relative' }}>
      {!isLoading && <OpaqueListOverlay isHidden={atScrollEnd} height={height} />}
      <div
        className={styles.container}
        onScroll={(event) => {
          const target = event.target as HTMLElement
          setAtScrollEnd(target.offsetHeight + target.scrollTop + ROW_GAP_SIZE >= target.scrollHeight)
        }}
      >
        <WrapperWithTitleAndAnimation
          title={i18n('xund.overview.title')}
          isAnimated
          animationStartsWhen={animationStartsWhen}
          style={{ height: 'fit-content' }}
          hasTopMargin={false}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div style={{ marginBottom: 10 }} ref={ref}>
              <p
                style={{
                  fontSize: CSS_VARS.chatFontSize,
                  marginTop: 13,
                  marginBottom: 13,
                  color: CSS_VARS.DARK_GREY_COLOR,
                }}
              >
                {i18n('xund.overview.addedSymptoms')}
              </p>
              <div>
                {overviewData?.addedSymptoms?.length ? (
                  overviewData?.addedSymptoms?.map((item, index) => {
                    return (
                      <OverviewSymptomItem
                        elementReference={index === 0 ? ref : undefined}
                        key={item.id}
                        itemId={item.id}
                        title={item.name}
                        icon="edit"
                        isLoading={isLoading}
                        onClick={() => setCurrentOverviewSymptom(item)}
                        isAdded={true}
                      />
                    )
                  })
                ) : (
                  <OverviewSymptomItem title={i18n('xund.overview.noSymptomsAdded')} isLoading={isLoading} />
                )}
              </div>
            </div>

            <div>
              <p
                style={{
                  fontSize: CSS_VARS.chatFontSize,
                  marginTop: 13,
                  marginBottom: 13,
                  color: CSS_VARS.DARK_GREY_COLOR,
                }}
              >
                {i18n('xund.overview.notAddedSymptoms')}
              </p>
              {overviewData?.suggestedNotAddedSymptoms?.length ? (
                overviewData.suggestedNotAddedSymptoms.map((item, index) => {
                  return (
                    <OverviewSymptomItem
                      elementReference={index === 0 ? ref : undefined}
                      key={item.id}
                      itemId={item.id}
                      title={item.name}
                      icon="addCircle"
                      isLoading={isLoading}
                      onClick={(e) => (!isLoading && e.detail === 1 ? addSymptomToOverview(item.id) : null)}
                    />
                  )
                })
              ) : (
                <OverviewSymptomItem title={i18n('xund.overview.noMoreSymptomsToAdd')} isLoading={isLoading} />
              )}
            </div>
            {/*TODO move to separate component?*/}
            <Modal
              className={styles.modal}
              width={784}
              bodyStyle={{ height: currentOverviewSymptomQuestions ? 366 : 'initial', overflow: 'auto' }}
              title={
                <ModalText
                  style={{
                    display: 'flex',
                    marginTop: 30,
                    marginBottom: 24,
                    justifyContent: 'center',
                    color: CSS_VARS.modalTitleFontColor,
                  }}
                  title={i18n('xund.overview.editSymptomModalTitle', { symptom: currentOverviewSymptom?.name })}
                  isModalTitle
                />
              }
              open={!!currentOverviewSymptom}
              onCancel={() => {
                setCurrentOverviewSymptomQuestions(null)
                setIsDeleteQuestion(false)
                setCurrentOverviewSymptom(null)
              }}
              footer={
                currentOverviewSymptomQuestions ? (
                  <>
                    {/*TODO reactivate once save logic is changed*/}
                    {/*<StyledButton*/}
                    {/*  type="skip"*/}
                    {/*  title={i18n('xund.general.cancel')}*/}
                    {/*  onClick={() => {*/}
                    {/*    setCurrentOverviewSymptomQuestions(null)*/}
                    {/*    setIsDeleteQuestion(false)*/}
                    {/*    setCurrentOverviewSymptom(null)*/}
                    {/*  }}*/}
                    {/*/>*/}
                    {/*<StyledButton*/}
                    {/*  type="confirm"*/}
                    {/*  title={i18n('xund.general.save')}*/}
                    {/*  onClick={() => {*/}
                    {/*    setCurrentOverviewSymptomQuestions(null)*/}
                    {/*    setIsDeleteQuestion(false)*/}
                    {/*    setCurrentOverviewSymptom(null)*/}
                    {/*  }}*/}
                    {/*/>*/}
                  </>
                ) : null
              }
              destroyOnClose
            >
              <div className={styles.modalContent}>
                {isDeleteQuestion && (
                  <>
                    <Row justify="center">
                      <Col>
                        <ModalText
                          title={i18n('xund.overview.symptomRemovalQuestion')}
                          style={{ color: CSS_VARS.DARK_BLUE_COLOR, marginBottom: 10 }}
                        />
                      </Col>
                      <Col span={24}>
                        <StyledButton
                          type="outlined"
                          title={i18n('xund.general.yes')}
                          style={{ display: 'block', width: '100%', marginBottom: 10 }}
                          onClick={() =>
                            !isLoading ? removeSymptomFromOverview(currentOverviewSymptom?.id as string) : null
                          }
                        />
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col span={24}>
                        <StyledButton
                          type="outlined"
                          title={i18n('xund.general.no')}
                          style={{ display: 'block', width: '100%', marginBottom: 30 }}
                          onClick={() => setIsDeleteQuestion(false)}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                {!isDeleteQuestion && !currentOverviewSymptomQuestions && (
                  <>
                    <Row justify="center">
                      <Col span={24}>{displayEditButtonIfNeeded()}</Col>
                    </Row>
                    <Row justify="center">
                      <Col span={24}>
                        <StyledButton
                          type="outlined"
                          title={
                            currentOverviewSymptom?.actionInfo.actions.find((action) => action.type === 'DELETE')
                              ?.title as string
                          }
                          style={{ display: 'block', width: '100%', marginBottom: 10 }}
                          onClick={() => setIsDeleteQuestion(true)}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                {(currentOverviewSymptomQuestions?.length ?? 0) > 0 &&
                  currentOverviewSymptomQuestions?.map((symptomQuestion) => (
                    <div key={symptomQuestion.id}>
                      <ModalText title={symptomQuestion.text} style={{ margin: '15px 0 10px', lineHeight: 1.4 }} />

                      <Select
                        defaultValue={
                          symptomQuestion?.options?.values?.find((item) => item.isSelected)?.id ||
                          i18n('xund.general.skip')
                        }
                        style={{ paddingLeft: 0, width: '100%', fontSize: CSS_VARS.modalFontSize }}
                        onChange={(val) => answerOverviewSymptomQuestion(symptomQuestion.id, val)}
                        size="large"
                      >
                        {symptomQuestion?.options?.values?.map((answer) => (
                          <Select.Option key={answer.id} value={answer.id}>
                            {answer.text}
                          </Select.Option>
                        ))}
                        <Select.Option value={''}>{i18n('xund.general.skip')}</Select.Option>
                      </Select>
                    </div>
                  ))}
              </div>
            </Modal>
          </div>
        </WrapperWithTitleAndAnimation>
      </div>
    </div>
  )
}
