import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useI18n } from '../../hooks'
import { WrapperWithTitleAndAnimation } from '../WrapperWithTitleAndAnimation'
import { Question } from '../../models'
import { useApiGatewayContext, useAppStateContext } from '../../context'
import { StyledButton } from '../General/StyledButton/StyledButton'

const checkIdLength = 8

/**
 * @param props The props object
 * @param props.question The current question
 * @param props.animationStartsWhen The boolean that decides when to start the animation
 * @param props.setIsCheckFinished The method for the boolean which decides whether the check is finished
 * @returns The CheckFinished component
 */
export const CheckFinished: FC<{
  question: Question
  animationStartsWhen: boolean
  setIsCheckFinished: (val: boolean) => void
}> = ({ question, animationStartsWhen, setIsCheckFinished }) => {
  const [isCheckCompleted, setIsCheckCompleted] = useState(false)
  const [error, setError] = useState<Error>()

  const { getCustomTranslationWithLocalFallback, i18n } = useI18n()
  const { apiGateway, checkId } = useApiGatewayContext()
  const { isCheckOnlyMode } = useAppStateContext()

  const isSoteMode = window.xundEnvironment.IS_SOTE_MODE === 'true'

  const endCheckMessage = useMemo(
    () => getCustomTranslationWithLocalFallback('endCheckMessage'),
    [getCustomTranslationWithLocalFallback],
  )

  const checkIdDisclaimer = useMemo(
    () => `
  ${endCheckMessage}
  <br />
  <br />
  ${checkId.slice(0, checkIdLength)}
  `,
    [checkId, endCheckMessage],
  )

  /**
   * Ends the check when the SOTE mode is being used
   *
   * Since in SOTE mode the legal disclaimer screen should not be visible,
   * BUT the report should be confirmed, the following steps are done:
   *
   * - confirm the legal disclaimer
   * - get the report
   * - confirm the report
   */
  const endCheck = useCallback(async () => {
    try {
      setIsCheckFinished(true)
      setIsCheckCompleted(true)

      await apiGateway.post('/v1/chatbot/answer', {
        questionId: question?.id,
        answer: {
          id: question?.options?.values[0]?.id,
        },
      })

      await apiGateway.get('/v1/chatbot/report')
      await apiGateway.post('/v1/chatbot/report/endCheck')
    } catch (err) {
      setError(err as Error)
    }
  }, [apiGateway, question, setIsCheckFinished])

  useEffect(() => {
    if (!isCheckCompleted && isSoteMode && question.detailedType !== 'NO_DIAGNOSIS') {
      endCheck()
    }
  }, [endCheck, isCheckCompleted, isSoteMode, question.detailedType])

  if (error) {
    throw error
  }

  return (
    <WrapperWithTitleAndAnimation
      title={isSoteMode ? checkIdDisclaimer : endCheckMessage}
      isAnimated
      animationStartsWhen={animationStartsWhen}
      tagline={i18n('xund.general.endCheck')}
    >
      {!isCheckOnlyMode && (
        <div className="buttonContainer">
          <StyledButton
            type="outlined"
            title={i18n('xund.general.backToHome')}
            style={{ marginTop: 10 }}
            onClick={() => window.appLocation.reload()}
          />
        </div>
      )}
    </WrapperWithTitleAndAnimation>
  )
}
