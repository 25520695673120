import { Button, ButtonProps, Card, CardProps } from 'antd'
import React from 'react'
import { RightOutlined } from '@ant-design/icons'
import { VirtualUrlService as VirtualUrlServiceModel } from '../../../models/virtualServices/VirtualUrlService'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import styles from './VirtualUrlService.module.less'
import { VirtualUrlServiceLogo, VirtualUrlServiceLogoProps } from './VirtualUrlServiceLogo'
import { CHECK_TYPE_SYMPTOM_CHECK } from '../../../context'
import { ACTION_REPORT, CATEGORY_ILLNESS_CHECK, CATEGORY_SYMPTOM_CHECK } from '../../../models/Matomo'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useQuestionContext } from '../../../context/QuestionContext'

export type VirtualUrlServiceProps = {
  virtualUrlService: VirtualUrlServiceModel
  cardProps?: CardProps
  nameProps?: Record<string, string>
  urlProps?: ButtonProps
  descriptionProps?: Record<string, string>
  logoProps?: VirtualUrlServiceLogoProps
}

/**
 * @param props - props
 * @param props.virtualUrlService the object to display
 * @param props.virtualUrlService.name the name
 * @param props.virtualUrlService.url the url
 * @param props.virtualUrlService.description the description
 * @param props.urlProps props of the component displaying the url
 * @param props.nameProps props of the component displaying the name
 * @param props.cardProps props of the card
 * @param props.descriptionProps props of the component displaying the description
 * @param props.logoProps props of the logo
 * @returns the VirtualUrlService component
 */
export const VirtualUrlService: React.FC<VirtualUrlServiceProps> = ({
  virtualUrlService: { name, url, description, profileImageUrl },
  cardProps,
  nameProps,
  urlProps,
  descriptionProps,
  logoProps,
}) => {
  const { trackEvent } = useMatomo()
  const { checkType } = useQuestionContext()

  return (
    <Card
      className={styles.virtualUrlService}
      title={
        <div className={styles.virtualUrlServiceCardTitle}>
          <div>
            <VirtualUrlServiceLogo
              {...logoProps}
              logoImg={profileImageUrl && <img src={profileImageUrl} style={{ width: '100%' }} />}
            />
          </div>
          <div
            style={{
              color: CSS_VARS.questionFontColor,
              fontWeight: 500,
              whiteSpace: 'pre-wrap',
              overflowWrap: 'anywhere',
            }}
            {...nameProps}
          >
            {name}
          </div>

          <Button
            type={'text'}
            href={url}
            target={'_blank'}
            rel={'noopener noreferrer'}
            style={{ marginLeft: 'auto' }}
            {...urlProps}
            onClick={() => {
              trackEvent({
                category: checkType === CHECK_TYPE_SYMPTOM_CHECK ? CATEGORY_SYMPTOM_CHECK : CATEGORY_ILLNESS_CHECK,
                action: ACTION_REPORT,
                name: 'Ecosystem management link clicked on Report',
              })
            }}
          >
            <RightOutlined color={CSS_VARS.questionFontColor} />
          </Button>
        </div>
      }
      hoverable={false}
      bordered={false}
      {...cardProps}
    >
      <p className={styles.description} {...descriptionProps}>
        {description}
      </p>
    </Card>
  )
}
