import React, { FC, ReactNode, useCallback, useState } from 'react'
import replace from 'react-string-replace'
import { useWebAppConfigContext } from '../../context'
import { WrapperWithTitleAndAnimation } from '../WrapperWithTitleAndAnimation'
import { useI18n } from '../../hooks'
import { StyledButton } from '../General/StyledButton/StyledButton'
import { ChatText } from '../General/ChatText/ChatText'
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator'
import { CSS_VARS } from '../../resources/cssVariableConfig'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useQuestionContext } from '../../context/QuestionContext'
import { CATEGORY_ILLNESS_CHECK, CATEGORY_SYMPTOM_CHECK } from '../../models/Matomo'

/**
 * @param props The props object
 * @param props.animationStartsWhen The boolean that decides when to start the animation
 * @param props.startCheck Starts the check
 * @param props.cancelCheck Cancels the check
 * @returns The LegalDisclaimer component
 */
export const LegalDisclaimer: FC<{
  animationStartsWhen: boolean
  startCheck: () => void
  cancelCheck: () => void
}> = ({ animationStartsWhen, startCheck, cancelCheck }) => {
  const { i18n, getCustomTranslationWithLocalFallback } = useI18n()
  const {
    webAppConfig: { privacyPolicyUrl, termsAndConditionsUrl },
  } = useWebAppConfigContext()
  const { checkType } = useQuestionContext()
  const { trackEvent } = useMatomo()
  const [isLoading, setLoading] = useState(false)

  /**
   * Gets the content for the terms and conditions
   *
   * @returns The terms and conditions object
   */
  const getTermsContent = useCallback(() => {
    const termsAndConditionsUrlLink = (
      <a
        key="termsAndConditionsUrl"
        style={{ color: CSS_VARS.linkColor }}
        rel="noopener noreferrer"
        href={termsAndConditionsUrl || ''}
        target="_blank"
      >
        {i18n('xund.general.generalTerms')}
      </a>
    )

    const privacyPolicyUrlLink = (
      <a
        key="privacyPolicyUrl"
        style={{ color: CSS_VARS.linkColor }}
        rel="noopener noreferrer"
        href={privacyPolicyUrl || ''}
        target="_blank"
      >
        {i18n('xund.termsAndConditions.dataPrivacy')}
      </a>
    )

    const loremIpsumTermsExplanatoryText = getCustomTranslationWithLocalFallback('termsAndConditionsExplanatoryText')
    let termsAndConditions: string | ReactNode[] = loremIpsumTermsExplanatoryText

    termsAndConditions = replace(
      termsAndConditions,
      /(\{generalTerms\})/g,
      () => termsAndConditionsUrlLink,
    ) as ReactNode[]

    termsAndConditions = replace(termsAndConditions, /(\{dataPrivacy\})/g, () => privacyPolicyUrlLink) as ReactNode[]

    termsAndConditions = replace(termsAndConditions, /(<br>)/g, (_, index) => <br key={index} />) as ReactNode[]

    return termsAndConditions
  }, [getCustomTranslationWithLocalFallback, i18n, privacyPolicyUrl, termsAndConditionsUrl])

  const handleAcceptClick = useCallback(() => {
    setLoading(true)
    trackEvent({
      category: checkType === 'SYMPTOM_CHECK' ? CATEGORY_SYMPTOM_CHECK : CATEGORY_ILLNESS_CHECK,
      action: 'Terms and conditions accepted',
    })
    startCheck()
  }, [checkType, startCheck, trackEvent])

  const handleDeclineClick = useCallback(() => {
    setLoading(true)
    trackEvent({
      category: checkType === 'SYMPTOM_CHECK' ? CATEGORY_SYMPTOM_CHECK : CATEGORY_ILLNESS_CHECK,
      action: 'Terms and conditions declined',
    })
    cancelCheck()
  }, [cancelCheck, checkType, trackEvent])

  return (
    <WrapperWithTitleAndAnimation
      title={i18n('xund.general.generalTerms')}
      isAnimated
      animationStartsWhen={animationStartsWhen}
    >
      <ChatText title={getTermsContent()} style={{ fontSize: CSS_VARS.chatFontSize, textAlign: 'center' }} />

      <div className="buttonContainer" style={{ marginTop: 10 }}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <StyledButton type="outlined" title={i18n('xund.general.accept')} onClick={handleAcceptClick} />

            <StyledButton
              type="outlined"
              title={i18n('xund.general.doNotAccept')}
              style={{ marginTop: 10 }}
              onClick={handleDeclineClick}
            />
          </>
        )}
      </div>
    </WrapperWithTitleAndAnimation>
  )
}
