import { FamilyHistoryAnswer, Gender } from 'health-check-api'
import { FAMILY_HISTORY_I_DONT_KNOW_ANSWER, FAMILY_HISTORY_NONE_ANSWER } from '../components'

export type HealthCheckAnswer = string | null | string[] | FamilyHistoryAnswer

export type InitializeBuilder = {
  age?: number
  gender?: Gender
  height?: number
  weight?: number
  selectedRiskTopics?: string[]
}

export const denormalizeFamilyHistoryAnswer = (answer: HealthCheckAnswer) => {
  const transformedAnswer: FamilyHistoryAnswer = [[], [], []]
  ;(answer as FamilyHistoryAnswer).forEach((familyMember, index) => {
    transformedAnswer[index] = familyMember.filter(
      (condition) => ![FAMILY_HISTORY_NONE_ANSWER, FAMILY_HISTORY_I_DONT_KNOW_ANSWER].includes(condition),
    )
  })
  return transformedAnswer
}

export const transformInitializeBody = (initializeBuilder: InitializeBuilder) => {
  const body: Record<string, any> = {}
  if (initializeBuilder.age) {
    body.age = initializeBuilder.age
  }
  if (initializeBuilder.gender) {
    body.gender = initializeBuilder.gender
  }
  if (initializeBuilder.height && initializeBuilder.weight) {
    body.bmi = {}
    body.bmi.height = initializeBuilder.height
    body.bmi.weight = initializeBuilder.weight
  }
  if (initializeBuilder.selectedRiskTopics) {
    body.topics = initializeBuilder.selectedRiskTopics
  }
  return body
}
