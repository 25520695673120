import { jwtDecode } from 'jwt-decode'
import { useAuthToken } from './useAuthToken'
import { z } from 'zod'

type ApiKeyScope =
  | 'execute_symptom_checks'
  | 'execute_illness_checks'
  | 'execute_health_checks'
  | 'query_services'
  | 'medical_library'

export const useApiKeyScopes = () => {
  const token = useAuthToken()
  return {
    hasApiKeyScope: (scope: ApiKeyScope): boolean => {
      if (!token) {
        return false
      }

      const payload = jwtDecode(token)
      const parse = z.object({ scopes: z.string().array() }).safeParse(payload)

      if (parse.success) {
        return parse.data.scopes.includes(scope)
      }
      return false
    },
  }
}
