import { BasicQuestion, QUESTION_STATE_HEALTH_DATA, QUESTION_TYPE_SINGLESELECT } from 'health-check-api'

export const mockSingleSelectQuestion: BasicQuestion = {
  id: 'question1',
  text: 'Have you ever smoked a cigarette, pipe etc.?',
  description: '',
  type: QUESTION_TYPE_SINGLESELECT,
  state: QUESTION_STATE_HEALTH_DATA,
  answers: [
    {
      id: 'q1a1',
      text: 'No',
      params: null,
    },
    {
      id: 'q1a2',
      text: 'Passive smoker',
      params: null,
    },
    {
      id: 'q1a3',
      text: 'Yes, occasionally',
      params: null,
    },
    {
      id: 'q1a4',
      text: 'Yes, regularly',
      params: null,
    },
  ],
  params: null,
  canMoveBack: true,
}

export const mockSingleSelectQuestionWithMoreAnswers: BasicQuestion = {
  ...mockSingleSelectQuestion,
  answers: [
    ...mockSingleSelectQuestion.answers,
    { id: 'q1a5', text: 'Answer 5', params: null },
    { id: 'q1a6', text: 'Answer 6', params: null },
    { id: 'q1a6', text: 'Answer 7', params: null },
  ],
}
