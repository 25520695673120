import React, { FC } from 'react'
import { SectionTitle } from '../Report.styled'
import { riskCategories, RiskCategory } from 'health-check-types'
import { RiskClassExplanation } from './RiskClassExplanation'
import { RiskClassesContainer } from './ExplanationSection.styled'
import { useI18n } from '../../../../../hooks'
import { RiskTopic } from 'health-check-api'

interface Props {
  selectedRiskCategory?: RiskCategory
  selectedRiskTopic?: Omit<RiskTopic, 'riskFactors'>
}

export const RiskClassesBox: FC<Props> = (props) => {
  const { selectedRiskCategory, selectedRiskTopic } = props
  const { i18n } = useI18n()

  return (
    <RiskClassesContainer>
      <SectionTitle fontSize={16} color="black">
        {!selectedRiskTopic && i18n('healthCheck.report.explanation.subTitle')}
        {selectedRiskTopic && selectedRiskTopic.name}
      </SectionTitle>
      {riskCategories
        .slice()
        .reverse()
        .map((category) => (
          <RiskClassExplanation
            key={`explanation-${category}`}
            riskCategory={category}
            selectedRiskCategory={selectedRiskCategory}
          />
        ))}
    </RiskClassesContainer>
  )
}
