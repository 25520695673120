import React, { FC } from 'react'
import { CustomIcon } from '../../../../../components/CustomIcon/CustomIcon'
import { WebAppIcon } from '../../../../../assets/icons/icons'
import { UnchangeableRiskFactorContainer } from './UnchangeableRiskFactorSection.styled'
import { StyledText } from '../Report.styled'

interface Props {
  icon: WebAppIcon
  title: string
}

export const RiskFactorTitle: FC<Props> = (props) => {
  const { icon, title } = props
  return (
    <UnchangeableRiskFactorContainer>
      <CustomIcon icon={icon} style={{ fill: 'black', stroke: 'unset', marginRight: 10 }} />
      <StyledText fontSize={16} isBold={true}>
        {title}
      </StyledText>
    </UnchangeableRiskFactorContainer>
  )
}
