import React, { FC, useMemo } from 'react'
import { Flex } from '../../Flex'
import { LegendScoreDot, StyledText } from '../Report.styled'
import { useI18n } from '../../../../../hooks'
import { RiskCategory } from 'health-check-types'
import { RiskClassSeverityBar } from './ExplanationSection.styled'
import { hexToRgba } from '../../../utils/hexToRgba'
import { TranslationCodeLiteral } from '../../../../../models'

interface Props {
  riskCategory: RiskCategory
  selectedRiskCategory?: RiskCategory
}

export const riskCategoryShortNames = {
  0: 'healthCheck.report.score.short.low',
  1: 'healthCheck.report.score.short.average',
  2: 'healthCheck.report.score.short.slightlyIncreased',
  3: 'healthCheck.report.score.short.moderate',
  4: 'healthCheck.report.score.short.high',
}

export const RiskClassExplanation: FC<Props> = (props) => {
  const { riskCategory, selectedRiskCategory } = props
  const { i18n } = useI18n()

  const calculateColor = (hexColor: string, hasFullOpacity: boolean) => {
    if (hasFullOpacity) {
      return hexColor
    }
    return hexToRgba(hexColor, 0.5)
  }

  const hasFullOpacity = useMemo(
    () => selectedRiskCategory === undefined || selectedRiskCategory === riskCategory,
    [riskCategory, selectedRiskCategory],
  )

  const { color, length, name } = useMemo(() => {
    switch (riskCategory) {
      case 4:
        return {
          color: calculateColor('#ff6666', hasFullOpacity),
          length: 100,
          name: i18n(riskCategoryShortNames[riskCategory] as TranslationCodeLiteral),
        }
      case 3:
        return {
          color: calculateColor('#FF9248', hasFullOpacity),
          length: 80,
          name: i18n(riskCategoryShortNames[riskCategory] as TranslationCodeLiteral),
        }
      case 2:
        return {
          color: calculateColor('#FFD301', hasFullOpacity),
          length: 60,
          name: i18n(riskCategoryShortNames[riskCategory] as TranslationCodeLiteral),
        }
      case 1:
        return {
          color: calculateColor('#66D096', hasFullOpacity),
          length: 40,
          name: i18n(riskCategoryShortNames[riskCategory] as TranslationCodeLiteral),
        }
      case 0:
        return {
          color: calculateColor('#00ADEF', hasFullOpacity),
          length: 20,
          name: i18n(riskCategoryShortNames[riskCategory] as TranslationCodeLiteral),
        }
      default:
        return { color: 'black', length: 0, name: '' }
    }
  }, [hasFullOpacity, i18n, riskCategory])

  return (
    <Flex flexDirection="column" style={{ width: '100%', marginTop: 20 }}>
      <StyledText color={hasFullOpacity ? '#000000' : hexToRgba('#000000', 0.5)} style={{ marginBottom: 5 }}>
        {name}
      </StyledText>
      <Flex alignItems="center" style={{ width: '100%' }}>
        <LegendScoreDot color={color} />
        <RiskClassSeverityBar color={color} width={length} />
      </Flex>
    </Flex>
  )
}
