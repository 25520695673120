import React, { FC, useCallback, useMemo } from 'react'
import { Flex } from '../../Flex'
import { BackToOverviewButton } from '../BackToOverviewButton'
import { DetailedModeContainer, MainTitle } from './ReportTitle.styled'
import styles from '../../../../../components/Footer/Footer.module.less'
import { BaseButton } from '../FooterSection/FooterSection.styled'
import { useI18n } from '../../../../../hooks'
import { LegendScoreDot, StyledText } from '../Report.styled'
import { RiskCategory, riskCategoryColors, RiskTopicWithRecommendation } from 'health-check-api'
import { TranslationCodeLiteral } from '../../../../../models'
import { riskCategoryLongNames } from '../RiskTopicSection'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { ACTION_REPORT, CATEGORY_HEALTH_CHECK } from '../../../../../models/Matomo'

interface Props {
  isCheckEnded: boolean
  isDetailedViewMode: boolean
  onBackClick: () => void
  isPDFLoading: boolean
  onDownloadPDF: () => void
  isSingleTopicView: boolean
  isMobile: boolean
  selectedRiskTopic?: RiskTopicWithRecommendation
}

export const ReportTitle: FC<Props> = (props) => {
  const { isCheckEnded, isDetailedViewMode, onBackClick, isPDFLoading, onDownloadPDF, selectedRiskTopic, isMobile } =
    props
  const { i18n } = useI18n()
  const { trackEvent } = useMatomo()

  const title = useMemo(() => {
    return selectedRiskTopic?.name ?? i18n('healthCheck.report.multiTopic.title')
  }, [i18n, selectedRiskTopic])

  const handleBackClick = useCallback(() => {
    trackEvent({
      category: CATEGORY_HEALTH_CHECK,
      action: ACTION_REPORT,
      name: 'Navigated back to Overview report',
    })
    onBackClick()
  }, [onBackClick, trackEvent])

  return (
    <>
      {!isCheckEnded && (
        <DetailedModeContainer>
          <Flex alignItems="center">
            <div>
              <MainTitle>{title}</MainTitle>
              {isMobile && selectedRiskTopic && (
                <Flex alignItems="center">
                  <LegendScoreDot color={riskCategoryColors[selectedRiskTopic?.score as RiskCategory]} />
                  <StyledText isBold={true} fontSize={20} style={{ marginLeft: 5 }}>
                    {i18n(riskCategoryLongNames[selectedRiskTopic?.score as RiskCategory] as TranslationCodeLiteral)}
                  </StyledText>
                </Flex>
              )}
            </div>
            {!isMobile && (
              <Flex alignItems="center" style={{ marginLeft: 40 }}>
                <LegendScoreDot color={riskCategoryColors[selectedRiskTopic?.score as RiskCategory]} />
                <StyledText isBold={true} fontSize={20} style={{ marginLeft: 5 }}>
                  {i18n(riskCategoryLongNames[selectedRiskTopic?.score as RiskCategory] as TranslationCodeLiteral)}
                </StyledText>
              </Flex>
            )}
          </Flex>
          <Flex
            alignItems="center"
            style={{ width: isMobile ? '100%' : 'fit-content' }}
            justifyContent={!isDetailedViewMode && isMobile ? 'end' : isMobile ? 'space-between' : 'start'}
          >
            {isDetailedViewMode && <BackToOverviewButton onClick={handleBackClick} />}
            <BaseButton
              type="outlined"
              title={i18n('xund.general.downloadReport')}
              onClick={onDownloadPDF}
              disabled={isPDFLoading}
              classNames={[styles.reportButton, styles.pdfButton]}
              style={{
                fontSize: 14,
                gridArea: 'downloadButton',
                marginBottom: 0,
                borderColor: document.documentElement.style.getPropertyValue('--filledButtonBackgroundColor'),
                color: document.documentElement.style.getPropertyValue('--filledButtonBackgroundColor'),
                padding: '6px 16px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...(isMobile && { width: 'fit-content' }),
              }}
              isLoading={isPDFLoading}
            />
          </Flex>
        </DetailedModeContainer>
      )}
      {isCheckEnded && (
        <DetailedModeContainer>
          <MainTitle style={{ padding: '0 20px', fontSize: 20 }}>{i18n('healthCheck.report.endCheck')}</MainTitle>
        </DetailedModeContainer>
      )}
    </>
  )
}
