import React, { FC, useMemo } from 'react'
import { RiskTopicAnswer } from 'health-check-api'
import { WrapperWithTitleAndAnimation } from '../../../../components/WrapperWithTitleAndAnimation'
import { useMockRiskTopics } from '../../mock'
import { RiskTopicSelectorContainer } from './RiskTopicSelector.styled'
import { RiskTopicList } from './RiskTopicList'

interface Props {
  title: string
  isLoading: boolean
  answers: RiskTopicAnswer[]
  onSelect: (answer: string[] | null) => void
}

export const RiskTopicSelector: FC<Props> = (props) => {
  const { title, isLoading, answers, onSelect } = props
  const mockRiskTopics = useMockRiskTopics()
  const riskTopicViews = useMemo(() => {
    return [
      ...answers.map((answer) => ({
        id: answer.id,
        name: answer.text,
        isDisabled: false,
        description: answer.params.description,
      })),
      ...mockRiskTopics,
    ].map((riskTopic) => ({
      id: riskTopic.id,
      name: riskTopic.name,
      description: riskTopic.description,
      isDisabled: riskTopic.isDisabled ?? false,
      isSelected: false,
    }))
  }, [answers, mockRiskTopics])

  return (
    <div>
      {!isLoading && (
        <WrapperWithTitleAndAnimation
          title={title}
          isAnimated
          animationStartsWhen={!isLoading}
          style={{ height: 'fit-content' }}
          hasTopMargin={false}
        >
          <RiskTopicSelectorContainer>
            <RiskTopicList riskTopicViews={riskTopicViews} onSelect={onSelect} isLoading={isLoading} />
          </RiskTopicSelectorContainer>
        </WrapperWithTitleAndAnimation>
      )}
    </div>
  )
}
