import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { CSS_VARS } from '../../../../../../resources/cssVariableConfig'

export const Title = styled.p`
  font-size: ${CSS_VARS.chatFontSize};
  color: ${CSS_VARS.lightTextColor};
  font-family: ${CSS_VARS.fontFamily};
  margin-bottom: 0;
`

export const InnerContainer = styled.div`
  height: calc(100vh - 210px);
  overflow: scroll;
`

export const specializationCommon = css`
  width: 100%;
  color: white;
  margin: 15px 0;
  line-height: 1.1;
  cursor: pointer;
`

export const specializationArticleCommon = css`
  width: 100%;
  color: ${CSS_VARS.darkTextColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Specialization = styled.div`
  ${specializationCommon}

  article {
    ${specializationArticleCommon}

    span {
      font-family: ${CSS_VARS.fontFamily};
      font-size: ${CSS_VARS.chatFontSize} !important;
    }
  }
`
