import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { PageContainer } from '../../../../components/MainPage/MainPage.styled'
import { ContentLibraryTitle } from '../../../../components/ContentLibrary/ContentLibraryTitle/ContentLibraryTitle'
import { Footer, Renderer, Report } from '../'
import { PoweredByXund } from '../../../../components/General/PoweredByXund/PoweredByXund'
import { Services } from '../../../../components/Services/Services'
import { FullSizeContainer, MainCol, MainRow } from './MainContainer.styled'
import { FamilyHistoryAnswer, QuestionResponse, ReportResponse } from 'health-check-api'
import { startQuestion } from '../../mock'
import { useApi } from '../../hooks/useApi'
import { FooterButtonState, StatusStep } from '../../models'
import { QuestionContext } from '../../context'
import { useLocation } from 'react-router'
import { z } from 'zod'

interface Props {
  isBoxShadowHidden?: boolean
  isServicesOpen: boolean
  isContentLibraryOpen: boolean
  ref: (node: HTMLDivElement | null) => void
  medicalContent: JSX.Element
  onSetContentLibraryOpen: (value: boolean) => void
  onSetContentLibraryItemId: Dispatch<SetStateAction<string>>
  contentLibraryItemId: string
  onSetProgressPercent: Dispatch<SetStateAction<number>>
  statusStep: StatusStep
  onSetStatusStep: Dispatch<SetStateAction<StatusStep>>
}

export const MainContainer: FC<Props> = (props) => {
  const {
    isBoxShadowHidden,
    isServicesOpen,
    isContentLibraryOpen,
    ref,
    medicalContent,
    onSetContentLibraryOpen,
    onSetContentLibraryItemId,
    contentLibraryItemId,
    onSetProgressPercent,
    statusStep,
    onSetStatusStep,
  } = props

  const [currentQuestion, setCurrentQuestion] = useState<QuestionResponse>(startQuestion)
  const [report, setReport] = useState<ReportResponse | undefined>(undefined)
  const [checkId, setCheckId] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedAnswer, setSelectedAnswer] = useState<string | string[] | null | FamilyHistoryAnswer>(null)
  const api = useApi()
  const [footerButtonState, setFooterButtonState] = useState<FooterButtonState>({
    nextButtonTitle: '',
    onNextButtonClick: null,
    isNextButtonDisabled: false,
    isNextButtonHidden: false,
    isSkipButtonHidden: true,
    onSkipButtonClick: null,
    isBackButtonHidden: true,
  })

  const { search: queryString } = useLocation()

  const showBox = useMemo(() => {
    if (!report) {
      return true
    }
    if (report && isContentLibraryOpen) {
      return true
    }
    return false
  }, [isContentLibraryOpen, report])

  const selectedRiskTopics = useMemo(() => {
    const queryParams = new URLSearchParams(queryString)
    const riskTopicParam = queryParams.get('riskTopics')?.split(',')
    if (riskTopicParam?.every((topicId) => z.string().uuid().safeParse(topicId).success)) {
      return riskTopicParam
    }
  }, [queryString])

  const onGoBack = useCallback(() => {
    setIsLoading(true)
    api.back(checkId).then((checkStatus) => {
      api.getQuestion(checkId).then((newQuestion) => {
        setCurrentQuestion(newQuestion)
        setIsLoading(false)
      })
    })
  }, [setIsLoading, api, checkId, setCurrentQuestion])

  const onBackClick = useCallback(() => {
    if (isLoading) {
      return
    }

    if (contentLibraryItemId) {
      onSetContentLibraryItemId('')
    }
    if (!isContentLibraryOpen) {
      onGoBack()
    }

    if (isContentLibraryOpen && !contentLibraryItemId) {
      onSetContentLibraryOpen(false)
    }
  }, [
    contentLibraryItemId,
    isContentLibraryOpen,
    isLoading,
    onGoBack,
    onSetContentLibraryItemId,
    onSetContentLibraryOpen,
  ])

  useEffect(() => {
    if (report) {
      onSetProgressPercent(100)
    }
  }, [onSetProgressPercent, report])

  return (
    <QuestionContext.Provider
      value={{
        report,
        setReport,
        currentQuestion,
        setCurrentQuestion,
        checkId,
        setCheckId,
        isLoading,
        setLoading: setIsLoading,
        selectedAnswer,
        setSelectedAnswer,
        api,
        footerButtonState,
        setFooterButtonState,
        statusStep,
        setStatusStep: onSetStatusStep,
      }}
    >
      <PageContainer isServicesOpen={isServicesOpen} isOnStartScreen={false} style={{ marginTop: report ? 20 : 50 }}>
        <CSSTransition in={true} timeout={500} classNames="innerWindowAnimation" appear>
          <MainRow justify="center">
            {report && !isContentLibraryOpen && <Report isLoading={isLoading} report={report} />}
            {isContentLibraryOpen && <ContentLibraryTitle />}
            {showBox && (
              <MainCol
                ref={ref}
                lg={{ span: 15 }}
                md={{ span: 19 }}
                sm={{ span: 19 }}
                data-testid="testShadow"
                isBoxShadowHidden={isBoxShadowHidden}
                isReport={!!report}
              >
                <FullSizeContainer>
                  {isContentLibraryOpen ? (
                    medicalContent
                  ) : (
                    <Renderer selectedRiskTopics={selectedRiskTopics} onSetProgressPercent={onSetProgressPercent} />
                  )}
                </FullSizeContainer>
                <Footer onBackClick={onBackClick} />
              </MainCol>
            )}
          </MainRow>
        </CSSTransition>
        <PoweredByXund displayWave={false} />
      </PageContainer>
      {isServicesOpen && <Services />}
    </QuestionContext.Provider>
  )
}
