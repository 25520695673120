import React, { FC, useMemo, useState } from 'react'
import { useElementSize } from 'usehooks-ts'
import { ChatbotQuestionAnswer, Question } from '../../../models'
import { useI18n } from '../../../hooks'
import { OptionItem } from '../../../models/api/response/OptionItem'
import { OpaqueListOverlay } from '../../OpaqueListOverlay/OpaqueListOverlay'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import styles from './SymptomAndIllnessList.module.less'
import { ListItem } from './ListItem/ListItem'

/**
 * @param props The props object
 * @param props.question The current question
 * @param props.options The possible answer options
 * @param props.searchText The search text used for searching
 * @param props.isLoading The boolean for whether the app is loading
 * @param props.sendAnswer The method that sends the answer
 * @returns The SymptomAndIllnessList component
 */
export const SymptomAndIllnessList: FC<{
  question: Question
  options: OptionItem[]
  searchText: string
  isLoading: boolean
  sendAnswer: (val: ChatbotQuestionAnswer) => void
}> = ({ question, options, searchText, isLoading, sendAnswer }) => {
  const borderWidth = 0.5
  const { i18n } = useI18n()

  const [atScrollEnd, setAtScrollEnd] = useState(false)
  const [ref, { height }] = useElementSize()

  const isSymptomSelect = useMemo(() => question.detailedType === 'SYMPTOM_SELECTOR', [question.detailedType])

  return (
    <div style={{ position: 'relative' }}>
      <div
        className={styles.symptomContainer}
        onScroll={(event) => {
          const target = event.target as HTMLElement
          setAtScrollEnd(target.offsetHeight + target.scrollTop + borderWidth >= target.scrollHeight)
        }}
      >
        {!searchText && (
          <>
            <span style={{ fontSize: CSS_VARS.chatFontSize, marginBottom: 13, color: CSS_VARS.DARK_GREY_COLOR }}>
              {i18n(isSymptomSelect ? 'xund.general.commonSymptoms' : 'xund.general.commonIllnesses')}
            </span>
            {options
              .filter((option) => option?.additionalData?.isFrequent === true)
              .map((item, index) => (
                <ListItem
                  elementReference={index === 0 ? ref : undefined}
                  key={item.id}
                  item={item}
                  questionId={question.id}
                  sendAnswer={sendAnswer}
                />
              ))}
          </>
        )}

        <span
          style={{
            fontSize: CSS_VARS.chatFontSize,
            marginTop: 20,
            marginBottom: 13,
            color: CSS_VARS.DARK_GREY_COLOR,
          }}
        >
          {i18n(isSymptomSelect ? 'xund.general.symptomsAZ' : 'xund.general.illnessesAZ')}
        </span>

        {options
          .filter((option) => option?.additionalData?.isFrequent === false || searchText)
          .map((item) => (
            <ListItem key={item.id} item={item} questionId={question.id} sendAnswer={sendAnswer} />
          ))}
      </div>
      <OpaqueListOverlay isHidden={atScrollEnd} height={height} />
    </div>
  )
}
