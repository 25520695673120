import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Row } from 'antd'
import { CSS_VARS } from '../../../../../resources/cssVariableConfig'

export const Container = styled.div`
  padding-top: 20px;
  border-top: 1px solid #f0f0f0;
  margin-top: 20px;
  font-family: ${CSS_VARS.fontFamily};

  article,
  div {
    font-family: ${CSS_VARS.fontFamily};
  }

  article {
    font-size: 11px;
    line-height: 30px;
  }
`

export const DateRowContainer = styled(Row)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
`

export const TimePeriodContainer = styled(Row)`
  width: 85%;
  justify-content: space-between;
  height: 21px;
`

const dateRowCommon = css`
  color: white;
  border-radius: 7px;
  text-align: center;
  height: 20px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const DateRowClosed = styled.div`
  ${dateRowCommon};

  background: ${CSS_VARS.RED_COLOR};
  width: 100%;
`

export const DateRowOpen = styled.div`
  ${dateRowCommon};

  background: ${CSS_VARS.GREEN_COLOR};
  width: 35%;
  position: absolute;
`

export const NotAvailableContainer = styled(Container)`
  margin-left: 30px;
`

export const NotAvailableHeader = styled.p`
  font-family: ${CSS_VARS.fontFamily};
  color: ${CSS_VARS.darkTextColor};
`

export const NotAvailable = styled.p`
  font-family: ${CSS_VARS.fontFamily};
  font-size: 11px;
  color: ${CSS_VARS.lightTextColor};
  line-height: 30px;
`
