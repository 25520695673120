import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Divider, Row } from 'antd'
import { RecommendedExamination, ReportResponse, RiskTopicWithRecommendation } from 'health-check-api'
import { useQuestionContext as useMainPageQuestionContext } from '../../../../context/QuestionContext'
import { BaseModal } from '../../../../components/Modals/BaseModal/BaseModal'
import { ReportContainer, SectionTitle } from './Report.styled'
import { ExaminationSection } from './ExaminationSection'
import { ScoreSection } from './ScoreSection'
import { ProfileSection } from './ProfileSection'
import { ProtectiveRiskFactorSection } from './ProtectiveRiskFactorSection'
import { RecommendationSection } from './RecommendationSection'
import { DisclaimerSection } from './DisclaimerSection'
import { useWindowSize } from 'usehooks-ts'
import { mobileMaxWidth, tabletMaxWidth, useI18n } from '../../../../hooks'
import { RiskTopicSection } from './RiskTopicSection'
import { usePreventNavigationHook } from '../../hooks/usePreventNavigationHook'
import { WrapperWithTitleAndAnimation } from '../WrapperWithTitleAndAnimation'
import { FooterSection } from './FooterSection'
import { StyledButton } from '../../../../components/General/StyledButton/StyledButton'
import { useApi } from '../../hooks/useApi'
import { CSSTransition } from 'react-transition-group'
import { ExplanationSection } from './ExplanationSection'
import { UnchangeableRiskFactorSection } from './UnchangeableRiskFactorSection'
import { ReportTitle } from './ReportTitle'
import { useQuestionContext } from '../../context'
import { useReportDetails } from '../../hooks/useReportDetails'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { ACTION_REPORT, CATEGORY_HEALTH_CHECK } from '../../../../models/Matomo'

export const NOT_STARTED_RISK_COLOR = '#F2F2F2'
export const COMPLETED_TOPIC_COLOR = '#EBF3FA'
export const LABEL_COLOR = '#808080'
export const SELECTED_LABEL_COLOR = '#00417A'

interface Props {
  isLoading: boolean
  report: ReportResponse
}

export const Report: FC<Props> = (props) => {
  const { isLoading, report } = props
  const { setNextButtonLogic, nextButtonLogic } = useMainPageQuestionContext()
  const { getExaminationUrls } = useApi()
  const { i18n } = useI18n()
  const { checkId } = useQuestionContext()
  const { getPDF } = useApi()
  const { trackEvent } = useMatomo()

  const [isExaminationModalOpen, setExaminationModalOpen] = useState<boolean>(false)
  const [selectedChartTopic, setSelectedChartTopic] = useState<RiskTopicWithRecommendation | undefined>(undefined)
  const [isDetailedViewMode, setDetailedViewMode] = useState<boolean>(false)
  const [isCheckEnded, setCheckEnd] = useState<boolean>(false)
  const [isNavigationPreventionDisabled, setNavigationPreventionDisabled] = useState<boolean>(false)
  const [examinations, setExaminations] = useState<RecommendedExamination[]>([])
  const [isPDFLoading, setPDFLoading] = useState(false)

  const { width: windowWidth } = useWindowSize()

  const isMobile = useMemo(() => windowWidth <= mobileMaxWidth, [windowWidth])
  const isIntermediateSize = useMemo(() => windowWidth < 900 && windowWidth > tabletMaxWidth, [windowWidth])
  const isMultipleRiskTopicMode = useMemo(() => report.topics.length > 1, [report])

  const { protectiveFactors, negativeFactors, filteredExaminations } = useReportDetails(
    report.topics,
    examinations,
    isMultipleRiskTopicMode,
    isDetailedViewMode,
    selectedChartTopic,
  )

  const showScoreSection = useMemo(() => {
    if (!isMultipleRiskTopicMode) {
      return true
    }
    if (isMultipleRiskTopicMode && isDetailedViewMode && selectedChartTopic) {
      return true
    }
    return false
  }, [isDetailedViewMode, isMultipleRiskTopicMode, selectedChartTopic])

  const showExplanatoryTexts = useMemo(
    () => (isMultipleRiskTopicMode && !isDetailedViewMode) || !isMultipleRiskTopicMode,
    [isDetailedViewMode, isMultipleRiskTopicMode],
  )

  const downloadPDF = useCallback(async () => {
    setPDFLoading(true)
    try {
      const data = await getPDF(checkId)
      const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
      window.open(url)
    } catch (e) {
      console.log(e)
    } finally {
      setPDFLoading(false)
    }
  }, [checkId, getPDF])

  useEffect(() => {
    if (nextButtonLogic) {
      setNextButtonLogic(null)
    }
  }, [nextButtonLogic, setNextButtonLogic])

  useEffect(() => {
    async function setMappedExaminations() {
      try {
        const urls = await getExaminationUrls(report.recommendedExaminations.map((examination) => examination.id))
        const mappedExaminations = report.recommendedExaminations.map((examination) => ({
          ...examination,
          url: urls?.items?.find((examinationResponse) => examinationResponse.examinationId === examination.id)?.url,
        }))
        setExaminations(mappedExaminations)
      } catch (e) {
        setExaminations(report.recommendedExaminations)
      }
    }

    void setMappedExaminations()
  }, [getExaminationUrls, report])

  usePreventNavigationHook(isNavigationPreventionDisabled)

  return (
    <WrapperWithTitleAndAnimation
      hasTopMargin={!isMobile}
      title={
        <ReportTitle
          isCheckEnded={isCheckEnded}
          isDetailedViewMode={isDetailedViewMode}
          onBackClick={() => {
            setSelectedChartTopic(undefined)
            setDetailedViewMode(false)
          }}
          onDownloadPDF={downloadPDF}
          isPDFLoading={isPDFLoading}
          selectedRiskTopic={!isMultipleRiskTopicMode ? report.topics[0] : selectedChartTopic}
          isSingleTopicView={!isMultipleRiskTopicMode}
          isMobile={isIntermediateSize || isMobile}
        />
      }
      isAnimated
      animationStartsWhen={!isLoading}
    >
      {!isCheckEnded && (
        <ReportContainer>
          <ProfileSection report={report} />
          {isMultipleRiskTopicMode && !isDetailedViewMode && (
            <CSSTransition
              in={isMultipleRiskTopicMode && !isDetailedViewMode}
              timeout={500}
              classNames="innerWindowAnimation"
              appear={true}
              unmountOnExit={true}
            >
              <RiskTopicSection
                riskTopics={report.topics}
                onSelectChartTopic={(topic) => {
                  setSelectedChartTopic(topic)
                  trackEvent({
                    category: CATEGORY_HEALTH_CHECK,
                    action: ACTION_REPORT,
                    name: 'Navigated to the Detailed view of the report',
                  })
                }}
                onSetDetailedMode={setDetailedViewMode}
              />
            </CSSTransition>
          )}
          {showScoreSection && <ScoreSection riskTopic={selectedChartTopic ?? report.topics[0]} />}
          {showExplanatoryTexts && (
            <ExplanationSection isMobile={isMobile} showRiskClasses={isMultipleRiskTopicMode && !isDetailedViewMode} />
          )}
          <Divider style={{ marginTop: 50, marginBottom: 50 }} />
          <ProtectiveRiskFactorSection
            protectiveRiskFactors={protectiveFactors}
            riskTopicName={isDetailedViewMode && selectedChartTopic ? selectedChartTopic?.name : undefined}
          />
          <Divider style={{ marginTop: 50, marginBottom: 50 }} />
          <RecommendationSection negativeRiskFactors={negativeFactors} />
          {filteredExaminations.length > 0 && (
            <ExaminationSection
              onOpenPlaceholderModal={() => setExaminationModalOpen(true)}
              examinations={filteredExaminations}
            />
          )}
          {filteredExaminations.length === 0 && showExplanatoryTexts && (
            <Divider style={{ marginTop: 50, marginBottom: 50 }} />
          )}
          {showExplanatoryTexts && <UnchangeableRiskFactorSection />}
          <Divider style={{ marginTop: 50 }} />
          <DisclaimerSection />
          <FooterSection
            onEndCheck={setCheckEnd}
            onSetNavigationPreventionDisabled={setNavigationPreventionDisabled}
            isPDFLoading={isPDFLoading}
            onDownloadPDF={downloadPDF}
            isMobile={isMobile}
            isIntermediateSize={isIntermediateSize}
          />
        </ReportContainer>
      )}
      {isCheckEnded && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '30vh',
          }}
        >
          <StyledButton
            title={i18n('healthCheck.endCheck.title')}
            type="skip"
            onClick={() => {
              window.location.reload()
            }}
          />
        </div>
      )}
      {isExaminationModalOpen && (
        <BaseModal
          hasButtons={false}
          onCancel={() => setExaminationModalOpen(false)}
          title={<SectionTitle>{i18n('healthCheck.report.examination.modal.title')}</SectionTitle>}
        >
          <Row>
            <Col span={24}>
              <p>{i18n('healthCheck.report.examination.modal.description')}</p>
            </Col>
          </Row>
        </BaseModal>
      )}
    </WrapperWithTitleAndAnimation>
  )
}
