import React, { FC } from 'react'
import { Col, Row, Typography } from 'antd'
import { useServicesContext } from '../../../../../../context'
import { AutocompleteResult } from '../../../../../../models'
import { CustomIcon } from '../../../../../CustomIcon/CustomIcon'
import { FilterDetailedViewContainer, FilterDetailedViewSubmitButton } from '../../Filters.common.styled'
import { useI18n } from '../../../../../../hooks'
import { CSS_VARS } from '../../../../../../resources/cssVariableConfig'
import { InnerContainer, Location, LocationRow } from './LocationsFilterDetailedView.styled'

/**
 * @param props The props object
 * @param props.autocompleteResults The autocomplete search results
 * @param props.setIsLocationsDetailedViewVisible The setter for whether the locations detailed view is visible
 * @returns The LocationsFilterDetailedView component
 */
export const LocationsFilterDetailedView: FC<{
  autocompleteResults: AutocompleteResult[]
  setIsLocationsDetailedViewVisible: (val: boolean) => void
}> = ({ autocompleteResults, setIsLocationsDetailedViewVisible }) => {
  const { i18n } = useI18n()

  const { filterObject, setFilterObject } = useServicesContext()

  return (
    <FilterDetailedViewContainer>
      <InnerContainer style={{ fontSize: CSS_VARS.chatFontSize }}>
        {autocompleteResults?.map((sug) => {
          const country = sug.context?.find((item) => item.id.includes('country'))
          const [lng, lat] = sug.center

          const isSelected = filterObject?.location?.latitude === lat && filterObject?.location?.longitude === lng

          return (
            <Location
              key={sug.id}
              style={
                isSelected
                  ? { color: CSS_VARS.filledButtonBackgroundColor, background: CSS_VARS.chatBackgroundColor }
                  : {}
              }
              onClick={() =>
                setFilterObject({
                  ...filterObject,
                  location: isSelected ? undefined : { text: sug.text, latitude: lat, longitude: lng },
                })
              }
            >
              <LocationRow>
                <div>
                  <Typography
                    style={{
                      fontWeight: isSelected ? 'bold' : 'normal',
                      color: CSS_VARS.darkTextColor,
                    }}
                  >
                    {sug.text}
                  </Typography>

                  <Typography style={{ fontWeight: isSelected ? 'bold' : 'normal', color: CSS_VARS.darkTextColor }}>
                    {country?.text}
                  </Typography>
                </div>

                {isSelected && <CustomIcon icon="check" />}
              </LocationRow>
            </Location>
          )
        })}
      </InnerContainer>

      <Row justify="end">
        <Col>
          <FilterDetailedViewSubmitButton
            type="primary"
            style={{
              background: CSS_VARS.filledButtonBackgroundColor,
              color: CSS_VARS.filledButtonFontColor,
            }}
            onClick={() => setIsLocationsDetailedViewVisible(false)}
          >
            {i18n('xund.general.done')}
          </FilterDetailedViewSubmitButton>
        </Col>
      </Row>
    </FilterDetailedViewContainer>
  )
}
