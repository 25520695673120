import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { TransitionGroup } from 'react-transition-group'
import { RiskTopicItem } from './RiskTopicItem'
import { RiskTopicListContainer } from './RiskTopicSelector.styled'
import { OpaqueListOverlay } from '../../../../components/OpaqueListOverlay/OpaqueListOverlay'
import { useElementSize } from 'usehooks-ts'

export interface RiskTopicView {
  id: string
  name: string
  description: string
  isDisabled: boolean
  isSelected: boolean
}

interface Props {
  riskTopicViews: RiskTopicView[]
  isLoading: boolean
  onSelect: (answer: string[] | null) => void
}

export const RiskTopicList: FC<Props> = (props) => {
  const { riskTopicViews, isLoading, onSelect } = props
  const [selectedRiskTopicIds, setSelectedRiskTopicIds] = useState<string[]>([])

  const [atScrollEnd, setAtScrollEnd] = useState(false)
  const [ref, { height }] = useElementSize()
  const borderWidth = 0.5

  const onRiskTopicSelect = useCallback(
    (selectedId: string) => {
      setSelectedRiskTopicIds((prevState) => {
        const currentState = prevState
        if (currentState.includes(selectedId)) {
          return currentState.filter((alreadyAddedId) => alreadyAddedId !== selectedId)
        }
        return [...currentState, selectedId]
      })
    },
    [setSelectedRiskTopicIds],
  )

  const riskTopicViewsWithSelection = useMemo(
    () =>
      riskTopicViews.map((riskTopic) => ({ ...riskTopic, isSelected: selectedRiskTopicIds.includes(riskTopic.id) })),
    [riskTopicViews, selectedRiskTopicIds],
  )

  useEffect(() => {
    onSelect(selectedRiskTopicIds)
  }, [onSelect, selectedRiskTopicIds])

  return (
    <>
      <TransitionGroup component={null}>
        <RiskTopicListContainer
          onScroll={(event) => {
            const target = event.target as HTMLElement
            setAtScrollEnd(target.offsetHeight + target.scrollTop + borderWidth >= target.scrollHeight)
          }}
        >
          {riskTopicViewsWithSelection.map((riskTopicView, index) => (
            <RiskTopicItem
              elementReference={index === 0 ? ref : undefined}
              key={riskTopicView.id}
              onSelected={() => onRiskTopicSelect(riskTopicView.id)}
              isLoading={isLoading}
              riskTopicView={riskTopicView}
            />
          ))}
        </RiskTopicListContainer>
      </TransitionGroup>
      <OpaqueListOverlay isHidden={atScrollEnd} height={height} />
    </>
  )
}
