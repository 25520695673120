import React, { FC } from 'react'
import { Input, Label } from './NumberInput.styled'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useI18n } from '../../hooks'

interface Props {
  label: string
  isErroneous?: boolean
  value?: number
  id?: string
  placeholder?: string
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
  onChange?: (value: unknown) => void
  errorMessage?: string
}

export const NumberInput: FC<Props> = (props) => {
  const { label, isErroneous = false, placeholder, id, onBlur, value, errorMessage, onChange } = props
  const { i18n } = useI18n()
  return (
    <>
      <Label style={{ color: isErroneous ? 'red' : 'black' }}>{label}</Label>
      <Input
        id={id}
        status={isErroneous ? 'error' : undefined}
        type="number"
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        size="large"
        value={value}
        controls={false}
      />
      {isErroneous && (
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
          <InfoCircleOutlined width={12} style={{ color: 'red', marginRight: 5 }} />
          <span style={{ color: 'red' }}>{errorMessage ?? i18n('components.numberInput.error')}</span>
        </div>
      )}
    </>
  )
}
