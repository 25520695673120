import React, { FC, useCallback, useMemo } from 'react'
import { Popover, Typography } from 'antd'
import { DAYS_OF_THE_WEEK, NAME_OF_TODAYS_DAY } from '../../../../../constants'
import { DetailedOpeningHours, OpeningDays } from '../../../../../models'
import { useI18n, useScreenSize } from '../../../../../hooks'
import { getHoursValueAsNumberFromTimeString } from '../../../../../utils'
import { CSS_VARS } from '../../../../../resources/cssVariableConfig'
import {
  Container,
  DateRowClosed,
  DateRowContainer,
  NotAvailableContainer,
  NotAvailableHeader,
  NotAvailable,
  DateRowOpen,
  TimePeriodContainer,
} from './OpeningHoursDisplay.styled'

/**
 * @param props The props object
 * @param props.openingHours The opening hours of the current service
 * @returns The OpeningHoursDisplay component
 */
export const OpeningHoursDisplay: FC<{
  openingHours: DetailedOpeningHours
}> = ({ openingHours }) => {
  const { i18n } = useI18n()
  const { isTabletView } = useScreenSize()

  /**
   * @returns An object with the days of the week mapped to the opening hours
   */
  const currentServiceOpeningHours = useCallback(
    (): Array<Array<{ start: string; finish: string }>> =>
      Object.values(OpeningDays.reduce((acc, day) => ({ ...acc, [day]: openingHours[day] }), {})),
    [openingHours],
  )

  const isServiceNotAvailable = useMemo(
    () => Object.values(currentServiceOpeningHours()).every((item) => !item.length),
    [currentServiceOpeningHours],
  )

  const dynamicFontWeightAndColor = useCallback((index: number) => {
    if (NAME_OF_TODAYS_DAY === DAYS_OF_THE_WEEK[index]) {
      return {
        fontWeight: 'bold',
        color: CSS_VARS.sideMenuBackgroundColor,
      }
    }

    return {
      fontWeight: 'normal',
      color: CSS_VARS.lightTextColor,
    }
  }, [])

  /**
   * @param timeString The time string to convert
   * @returns The percentage how wide the component should be based on the length represented by the time string
   */
  const convertHoursToStylingPercentage = useCallback(
    (timeString: string) => {
      const hoursValues = currentServiceOpeningHours()
        .filter((item) => item.length)
        .flat()

      const startValue = [...hoursValues].sort((a, b) => +a.start.replace(':', '') - +b.start.replace(':', ''))[0].start

      const minIsNotRounded = startValue.includes('30')

      const min = minIsNotRounded
        ? getHoursValueAsNumberFromTimeString(startValue) + 0.5
        : getHoursValueAsNumberFromTimeString(startValue)

      const finishValue = [...hoursValues].sort((a, b) => +b.finish.replace(':', '') - +a.finish.replace(':', ''))[0]
        .finish

      const maxIsNotRounded = finishValue.includes('30')

      const max = maxIsNotRounded
        ? getHoursValueAsNumberFromTimeString(finishValue) + 0.5
        : getHoursValueAsNumberFromTimeString(finishValue)

      const basePercentage = 100 / ((max - min) * 2)

      const percentageToAddIfTimeStringIsNotRounded =
        (timeString !== startValue || timeString !== finishValue) && timeString.includes(':30') ? basePercentage : 0

      const hours = getHoursValueAsNumberFromTimeString(timeString)

      return (hours - min) * (basePercentage * 2) + percentageToAddIfTimeStringIsNotRounded
    },
    [currentServiceOpeningHours],
  )

  const responsiveWidth = useMemo(() => (isTabletView ? 34 : 30), [isTabletView])

  if (isServiceNotAvailable) {
    return (
      <NotAvailableContainer>
        <NotAvailableHeader>{i18n('xund.services.openingHours.header')}</NotAvailableHeader>
        <NotAvailable>{i18n('xund.services.openingHours.noInfo')}</NotAvailable>
      </NotAvailableContainer>
    )
  }

  return (
    <Container>
      {currentServiceOpeningHours().map((openingHour, index) => {
        return (
          <DateRowContainer key={index} justify="space-between">
            <Typography
              style={{
                ...dynamicFontWeightAndColor(index),
              }}
            >
              {i18n(`xund.services.${DAYS_OF_THE_WEEK[index]}`)}
            </Typography>

            <TimePeriodContainer style={{ position: 'relative' }}>
              {openingHour?.length ? (
                openingHour.map((time, idx) => {
                  const elementWidth =
                    convertHoursToStylingPercentage(time.finish) - convertHoursToStylingPercentage(time.start)

                  return (
                    <Popover key={idx} content={`${time.start} - ${time.finish}`}>
                      <DateRowOpen
                        style={{
                          left: `${convertHoursToStylingPercentage(time.start)}%`,
                          width: `${elementWidth}%`,
                        }}
                      >
                        {elementWidth < responsiveWidth ? '' : `${time.start} - ${time.finish}`}
                      </DateRowOpen>
                    </Popover>
                  )
                })
              ) : (
                <Popover content={i18n('xund.services.closed')}>
                  <DateRowClosed>{i18n('xund.services.closed')}</DateRowClosed>
                </Popover>
              )}
            </TimePeriodContainer>
          </DateRowContainer>
        )
      })}
    </Container>
  )
}
