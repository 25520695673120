import styled from '@emotion/styled'

export const UnchangeableRiskFactorContainer = styled.div`
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 15px;
  display: flex;
  min-height: 55px;
  margin-top: 10px;
  padding: 20px;
  width: 100%;
`
