import styled from '@emotion/styled'
import { Row } from 'antd'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'

export const Label = styled.p`
  font-family: ${CSS_VARS.fontFamily};
  color: ${CSS_VARS.DARK_GREY_COLOR};
`

export const StyledRow = styled(Row)`
  margin-bottom: 16px;
`
