import styled from '@emotion/styled'

export const SliderContainer = styled.div`
  margin: 30px auto 0;
  width: 100%;
  flex: calc(100% - 40px);

  :global {
    .ant-slider-handle {
      width: 20px;
      height: 20px;
      inset-block-start: -4px;
    }

    .ant-slider-horizontal.ant-slider-with-marks {
      margin-bottom: 11px;
    }

    .ant-slider-handle::after {
      width: 20px;
      height: 20px;
      box-shadow: 0 2px 7px rgba(0, 0, 0, 0.15);
      transition: box-shadow 0.3s;
    }

    .ant-slider-handle:hover::after,
    .ant-slider-handle:focus::after {
      width: 20px;
      height: 20px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
      inset-block-start: 0;
      inset-inline-start: 0;
    }

    .ant-slider:hover .ant-slider-handle::after {
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    }
  }
`
