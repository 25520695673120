import React, { FC, Ref, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { Col, Collapse, Typography } from 'antd'
import { UpOutlined } from '@ant-design/icons'
import { Flex } from '../Flex'
import { mobileMaxWidth, useI18n } from '../../../../hooks'
import {
  BookingButton,
  FullWidthBackground,
  ExaminationPanel,
  ExaminationRow,
  RecommendationContainer,
  SectionTitle,
  ShowMoreButton,
  StyledText,
} from './Report.styled'
import { useWindowSize } from 'usehooks-ts'
import { RecommendedExamination } from 'health-check-api'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { ACTION_REPORT, CATEGORY_HEALTH_CHECK } from '../../../../models/Matomo'

interface Props {
  onOpenPlaceholderModal: () => void
  examinations: RecommendedExamination[]
}

const LIST_MAX_LENGTH = 4

export const ExaminationSection: FC<Props> = (props) => {
  const { onOpenPlaceholderModal, examinations } = props
  const ref: Ref<HTMLDivElement> = useRef(null)
  const [rowLeftBorder, setRowLeftBorder] = useState(0)
  const [isAllVisible, setAllVisible] = useState<boolean>()
  const { i18n } = useI18n()
  const { width: windowWidth } = useWindowSize()
  const { trackEvent } = useMatomo()

  const isMobile = useMemo(() => windowWidth <= mobileMaxWidth, [windowWidth])

  const visibleExaminations = useMemo(
    () => (isAllVisible ? examinations : examinations.slice(0, LIST_MAX_LENGTH)),
    [isAllVisible, examinations],
  )

  useLayoutEffect(() => {
    const setter = () => {
      const rectangle = ref?.current?.getBoundingClientRect()
      setRowLeftBorder(rectangle?.left ?? 0)
    }
    setter()
    window.addEventListener('resize', setter)

    return () => {
      window.removeEventListener('resize', setter)
    }
  }, [])

  return (
    <ExaminationRow ref={ref} style={{ position: 'relative' }}>
      <FullWidthBackground left={rowLeftBorder} />
      <Col span={24}>
        <Col span={24}>
          <SectionTitle>{i18n('healthCheck.examination.cardiovascular.title')}</SectionTitle>
        </Col>
        <Col span={24}>
          <Typography.Text>{i18n('healthCheck.examination.cardiovascular.description')}</Typography.Text>
        </Col>
        <Col span={24}>
          <Collapse
            defaultActiveKey={['0']}
            ghost={true}
            expandIconPosition="end"
            expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 0 : 180} />}
            style={{ marginTop: 40 }}
            collapsible={isMobile ? undefined : 'icon'}
          >
            {visibleExaminations.map((examination, index) => (
              <ExaminationPanel
                key={index}
                header={
                  <Flex alignItems="center" justifyContent="space-between" style={{ flexWrap: 'wrap' }}>
                    <StyledText
                      color={document.documentElement.style.getPropertyValue('--questionFontColor')}
                      isBold={true}
                      fontSize={16}
                    >
                      {examination.name}
                    </StyledText>
                    <div onClick={(event) => event.stopPropagation()}>
                      <BookingButton
                        onClick={(event) => {
                          if (examination.url) {
                            trackEvent({
                              category: CATEGORY_HEALTH_CHECK,
                              action: ACTION_REPORT,
                              name: 'Booking link opened',
                            })
                            window.open(examination.url, '_blank')
                          } else {
                            trackEvent({
                              category: CATEGORY_HEALTH_CHECK,
                              action: ACTION_REPORT,
                              name: 'Placeholder modal opened',
                            })
                            onOpenPlaceholderModal()
                          }
                        }}
                      >
                        {i18n('healthCheck.examination.book')}
                      </BookingButton>
                    </div>
                  </Flex>
                }
              >
                <RecommendationContainer>
                  <Col span={24}>
                    <StyledText isBold={true}>{i18n('healthCheck.report.examination')}</StyledText>
                  </Col>
                  <Col span={24}>
                    <StyledText>{examination.description}</StyledText>
                  </Col>
                </RecommendationContainer>
              </ExaminationPanel>
            ))}
          </Collapse>
          {examinations.length > LIST_MAX_LENGTH && (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
              <ShowMoreButton
                onClick={() => {
                  setAllVisible((prevState) => !prevState)
                }}
              >
                {isAllVisible ? i18n('xund.general.showLess') : i18n('xund.general.showMore')}
              </ShowMoreButton>
            </div>
          )}
        </Col>
      </Col>
    </ExaminationRow>
  )
}
