import React, { FC } from 'react'
import { ModalTitle, StyledModal } from './Modal.styled'

interface Props {
  isOpen: boolean
  onCancel: () => void
  children?: React.ReactNode
  width?: number
  title?: string
}

export const Modal: FC<Props> = (props) => {
  const { isOpen, onCancel, children, width, title } = props

  return (
    <StyledModal
      centered={true}
      width={width ?? 784}
      open={isOpen}
      onCancel={onCancel}
      footer={<></>}
      maskStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.3)', backdropFilter: 'blur(3px)' }}
    >
      {title && <ModalTitle>{title}</ModalTitle>}
      {children}
    </StyledModal>
  )
}
