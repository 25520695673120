import React, { FC, useMemo } from 'react'
import { Col } from 'antd'
import { Label, LabelContainer } from './FamilyHistory.styled'
import { useI18n } from '../../../../hooks'
import { FamilyMember } from './FamilyHistory'
import { SelectInput } from '../../../../common'

interface Props {
  groupedOptions: Array<Record<string, any>>
  familyMember: FamilyMember
  visibleValue: string[]
  onSelect: (familyMember: FamilyMember, value: string[], option: Record<string, any>) => void
}

export const FamilyMemberSection: FC<Props> = (props) => {
  const { groupedOptions, familyMember, visibleValue, onSelect } = props
  const { i18n } = useI18n()

  const label: string = useMemo(() => {
    switch (familyMember) {
      case 'mother':
        return i18n('components.familyHistory.mother')
      case 'father':
        return i18n('components.familyHistory.father')
      case 'grandMother':
        return i18n('components.familyHistory.grandMother')
      case 'grandFather':
        return i18n('components.familyHistory.grandFather')
      default:
        return ''
    }
  }, [familyMember, i18n])

  return (
    <>
      <Col md={5} xs={24}>
        <LabelContainer>
          <Label>{label}</Label>
        </LabelContainer>
      </Col>
      <Col md={19} xs={24}>
        <SelectInput
          hasSearch={true}
          isMulti={true}
          options={groupedOptions}
          onGroupedSelect={(value: string[], option: Record<string, any>) => onSelect(familyMember, value, option)}
          placeholder={i18n('components.multiSelect.placeholder')}
          customValue={visibleValue}
          size="middle"
        />
      </Col>
    </>
  )
}
