import React, { FC, useEffect, useMemo, useRef } from 'react'
import { ChatbotQuestionAnswer, Question } from '../../models'
import { WrapperWithTitleAndAnimation } from '../WrapperWithTitleAndAnimation'
import { StyledButton } from '../General/StyledButton/StyledButton'
import { useI18n } from '../../hooks'

/**
 * @param props The props object
 * @param props.question The current question
 * @param props.isLoading The boolean whether the app is loading
 * @param props.sendAnswer The method that sends the answer
 * @returns The SingleSelect component
 */
export const SingleSelect: FC<{
  question: Question
  isLoading: boolean
  sendAnswer: (val: ChatbotQuestionAnswer) => void
}> = ({ question, isLoading, sendAnswer }) => {
  const { i18n } = useI18n()

  const unHoverRef = useRef<HTMLInputElement>(null)

  //TODO add logic + subtitle to API instead
  const isEndCheckQuestion = useMemo(() => question.detailedType === 'AFTER_REPORT_ACTION_SELECTOR', [question])

  useEffect(() => {
    // let's say you clicked on the first button from several buttons below each other (on mobile)
    // on the next screen the first button will be hovered automatically, which is wrong (WAPP-207)
    // this fixes it by clicking on the button's container on initial render, resetting the hover
    unHoverRef.current?.click()
  }, [])

  return (
    <WrapperWithTitleAndAnimation
      title={question.text}
      isAnimated
      animationStartsWhen={!isLoading}
      tagline={isEndCheckQuestion ? i18n('xund.general.endCheck') : undefined}
    >
      {!['ILLNESS_CHECK_LEGAL_DISCLAIMER', 'SYMPTOM_CHECK_LEGAL_DISCLAIMER'].includes(
        question.checkStateInfo.detailedState,
      ) && (
        <div className="buttonContainer" ref={unHoverRef}>
          {question.options?.values.map((item) => (
            <StyledButton
              key={item.id}
              type="outlined"
              title={item.text}
              style={{ marginTop: 10 }}
              disabled={isLoading}
              onClick={() => sendAnswer({ questionId: question.id, answer: { id: item.id } })}
            />
          ))}
        </div>
      )}
    </WrapperWithTitleAndAnimation>
  )
}
