import styled from '@emotion/styled'
import { SectionTitle } from '../Report.styled'

export const MainContainer = styled.div`
  border-radius: 15px;
  background-color: #f9f9f9;
  padding: 16px 20px;
  height: 106px;
`
export const BMINumber = styled(SectionTitle)`
  color: black;
  font-size: 16px;
  margin-left: 2px;
  margin-right: 6px;
`
export const BarContainer = styled.div`
  position: relative;
`
export const BarColoredSegment = styled.div<{
  color: string
  widthPercent: number
  isFirst?: boolean
  isLast?: boolean
}>`
  display: inline-block;
  height: 15px;
  width: ${(props) => `${props.widthPercent}`}%;
  background-color: ${(props) => `${props.color}`};
  ${(props) =>
    props.isFirst &&
    `border-top-left-radius: 10px;
     border-bottom-left-radius: 10px;`}
  ${(props) =>
    props.isLast &&
    `border-top-right-radius: 10px;
     border-bottom-right-radius: 10px;`}
  :hover {
    position: relative;
    box-shadow: ${(props) => `0 0 0 2px ${props.color}`};
  }
`
export const BarPointer = styled.div<{ position: number }>`
  position: absolute;
  height: 22px;
  background-color: black;
  width: 3px;
  left: ${(props) => `${props.position}`}%;
  bottom: 0;
  border-radius: 50px;
`
