import React, { FC, useMemo } from 'react'
import { CURRENT_HOUR, NAME_OF_TODAYS_DAY } from '../../../../../../constants'
import { useI18n } from '../../../../../../hooks'
import { OpeningHours } from '../../../../../../models'
import { getHoursValueAsNumberFromTimeString } from '../../../../../../utils'
import { convertCurrentServiceOpeningHoursToObject } from '../../../../../../utils/services/convertCurrentServiceOpeningHoursToObject'
import { CSS_VARS } from '../../../../../../resources/cssVariableConfig'

/**
 * @param props The props object
 * @param props.openingHours The current opening hours
 * @returns The text that is displayed based on the opening hours
 */
export const ServiceOpeningHoursText: FC<{ openingHours?: OpeningHours }> = ({ openingHours }) => {
  const { i18n } = useI18n()

  const todaysOpeningHours = useMemo(
    () => convertCurrentServiceOpeningHoursToObject(openingHours)[NAME_OF_TODAYS_DAY],
    [openingHours],
  )

  const isStillOpenToday = useMemo(
    () => todaysOpeningHours.find((hours) => getHoursValueAsNumberFromTimeString(hours.finish) > CURRENT_HOUR),
    [todaysOpeningHours],
  )

  if (!todaysOpeningHours?.length) {
    return <span style={{ color: CSS_VARS.lightTextColor }}>{i18n('xund.services.openingHours.noInfo')}</span>
  }

  if (
    isStillOpenToday &&
    getHoursValueAsNumberFromTimeString(isStillOpenToday.finish) - CURRENT_HOUR >= 2 &&
    getHoursValueAsNumberFromTimeString(isStillOpenToday.start) <= CURRENT_HOUR
  ) {
    return <span style={{ color: CSS_VARS.GREEN_COLOR }}>{i18n('xund.services.open')}</span>
  }

  if (isStillOpenToday && getHoursValueAsNumberFromTimeString(isStillOpenToday.finish) - CURRENT_HOUR <= 2) {
    return (
      <span style={{ color: CSS_VARS.RED_COLOR }}>
        {i18n('xund.services.closesAt', { TIMEDATA: isStillOpenToday.finish })}
      </span>
    )
  }

  if (isStillOpenToday && getHoursValueAsNumberFromTimeString(isStillOpenToday.start) - CURRENT_HOUR > 2) {
    return (
      <span style={{ color: CSS_VARS.GREEN_COLOR }}>
        {i18n('xund.services.opensAt', { TIMEDATA: isStillOpenToday.start })}
      </span>
    )
  }

  return <span style={{ color: CSS_VARS.RED_COLOR }}>{i18n('xund.services.closed')}</span>
}
