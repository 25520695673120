import { useDebouncedState } from 'xund-react-utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  DEFAULT_COUNTRY,
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  DEFAULT_SERVICES_MAP_DEBOUNCE_TIME_IN_MS,
} from '../constants'
import { useApiGatewayContext } from '../context'
import { GenericSearchParameters, ServiceWithDetailedOpeningHours } from '../models'
import { ListResponse } from '../models/ListResponse'
import { isSupportedCountry } from '../utils/services/isSupportedCountry'
import { useGetCountryCodeFromCurrentCoordinates, usePrevious } from './'

/**
 * @returns The useServices hook
 */
export const useServices = () => {
  const {
    setValue: setCoordinates,
    debouncedValue: debouncedCoordinates,
    value: coordinates,
  } = useDebouncedState(
    { longitude: DEFAULT_LONGITUDE, latitude: DEFAULT_LATITUDE },
    DEFAULT_SERVICES_MAP_DEBOUNCE_TIME_IN_MS,
  )
  const [matchingFiltersServicesData, setMatchingFiltersServicesData] = useState<
    ListResponse<ServiceWithDetailedOpeningHours>
  >({
    count: 0,
    items: [],
  })
  const [notMatchingFiltersServicesData, setNotMatchingFiltersServicesData] = useState<
    ListResponse<ServiceWithDetailedOpeningHours>
  >({
    count: 0,
    items: [],
  })
  const [filterObject, setFilterObject] = useState<GenericSearchParameters>({})
  const [requestFilter, setRequestFilter] = useState<GenericSearchParameters>({})
  const [country, setCountry] = useState(DEFAULT_COUNTRY)
  const [lastSupportedCountryVisited, setLastSupportedCountryVisited] = useState(DEFAULT_COUNTRY)
  const [searchText, setSearchText] = useState('') // TODO investigate why useDebouncedState caused the bug here
  const [selectedService, setSelectedService] = useState<ServiceWithDetailedOpeningHours | null>(null)
  const [isMatchingFiltersServicesLoading, setIsMatchingFiltersServicesLoading] = useState(false)
  const [isNotMatchingFiltersServicesLoading, setIsNotMatchingFiltersServicesLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const { medicalServices } = useApiGatewayContext()
  const { getCountryCodeFromCurrentCoordinates } = useGetCountryCodeFromCurrentCoordinates()

  const lastCountryVisited = usePrevious(country) || country

  const previousLastSupportedCountryVisited = usePrevious(lastSupportedCountryVisited) || lastSupportedCountryVisited

  const fromCountryCode = useMemo(
    () => (isSupportedCountry(lastCountryVisited) ? lastCountryVisited : previousLastSupportedCountryVisited),
    [lastCountryVisited, previousLastSupportedCountryVisited],
  )

  const isBorderChanging = useMemo(
    () => isSupportedCountry(country) && fromCountryCode !== country,
    [country, fromCountryCode],
  )

  /**
   * Converts selected specializations when there is a border change
   *
   * @param requestBody The body for the request
   */
  const changeBorder = useCallback(
    async (requestBody: Object) => {
      try {
        const { data } = await medicalServices.post(`services/filters/borderChange`, requestBody)

        const { specializations } = data.filterValues

        setFilterObject({ ...filterObject, specializations })
        setRequestFilter({ ...requestFilter, specializations })
      } catch (err) {
        setError(err as Error)
      } finally {
        setIsMatchingFiltersServicesLoading(false)
      }
    },
    [filterObject, requestFilter, medicalServices],
  )

  /**
   * Fetches Services data that matches the filters
   */
  const getMatchingFiltersServicesData = useCallback(
    async (searchTerm?: string) => {
      try {
        setIsMatchingFiltersServicesLoading(true)

        const locationCoordinates = {
          latitude: requestFilter?.location?.latitude,
          longitude: requestFilter?.location?.longitude,
        }

        const { latitude, longitude } = debouncedCoordinates

        const location =
          locationCoordinates.latitude && locationCoordinates.longitude ? locationCoordinates : { latitude, longitude }

        const defaultFilters = { ...requestFilter, location }

        if (searchTerm) {
          defaultFilters.name = searchTerm
        }

        if (isBorderChanging && isSupportedCountry(country)) {
          const borderChangeRequestBody = { fromCountryCode, toCountryCode: country, filterValues: defaultFilters }

          await changeBorder(borderChangeRequestBody)

          return
        }

        const { data } = await medicalServices.post<ListResponse<ServiceWithDetailedOpeningHours>>(
          `services/filters/apply`,
          defaultFilters,
        )
        setMatchingFiltersServicesData(data)
      } catch (err) {
        setError(err as Error)
      } finally {
        setIsMatchingFiltersServicesLoading(false)
      }
    },
    [changeBorder, country, debouncedCoordinates, fromCountryCode, isBorderChanging, requestFilter, medicalServices],
  )

  /**
   * Fetches Services data that matches the name, but not the filters
   */
  const getNotMatchingFiltersServicesData = useCallback(
    async (searchTerm: string) => {
      try {
        setIsNotMatchingFiltersServicesLoading(true)

        const locationCoordinates = {
          latitude: requestFilter?.location?.latitude,
          longitude: requestFilter?.location?.longitude,
        }

        const { latitude, longitude } = debouncedCoordinates

        const location =
          locationCoordinates.latitude && locationCoordinates.longitude ? locationCoordinates : { latitude, longitude }

        const { data } = await medicalServices.post<ListResponse<ServiceWithDetailedOpeningHours>>(
          `services/filters/apply`,
          {
            name: searchTerm,
            location,
          },
        )
        setNotMatchingFiltersServicesData(data)
      } catch (err) {
        setError(err as Error)
      } finally {
        setIsNotMatchingFiltersServicesLoading(false)
      }
    },
    [debouncedCoordinates, requestFilter?.location?.latitude, requestFilter?.location?.longitude, medicalServices],
  )

  useEffect(() => {
    if (!isSupportedCountry(country) && matchingFiltersServicesData.count) {
      setMatchingFiltersServicesData({ count: 0, items: [] })
    }
  }, [country, matchingFiltersServicesData.count, setMatchingFiltersServicesData])

  useEffect(() => {
    getMatchingFiltersServicesData(searchText)
    if (searchText) {
      getNotMatchingFiltersServicesData(searchText)
    }
  }, [getMatchingFiltersServicesData, getNotMatchingFiltersServicesData, searchText])

  /**
   * @param val The value object
   * @param val.longitude The current longitude
   * @param val.latitude The current latitude
   * @returns The method that converts coordinates into a country code
   */
  const setCountryCodeFromCurrentCoordinates = (val: { longitude: number; latitude: number }) =>
    getCountryCodeFromCurrentCoordinates(val, setCountry, setLastSupportedCountryVisited, setError)

  return {
    getMatchingFiltersServicesData,
    matchingFiltersServicesData,
    notMatchingFiltersServicesData,
    selectedService,
    setSelectedService,
    filterObject,
    setFilterObject,
    requestFilter,
    setRequestFilter,
    searchText,
    setSearchText,
    coordinates,
    setCoordinates,
    country,
    setCountry,
    lastSupportedCountryVisited,
    setCountryCodeFromCurrentCoordinates,
    isMatchingFiltersServicesLoading,
    isNotMatchingFiltersServicesLoading,
    error,
    isSupportedCountry: isSupportedCountry(country),
  }
}
