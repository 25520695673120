// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EOU74DT2IrdUC3TAMDwg {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100px;\n  height: 100px;\n}\n@media screen and (max-height: 576px) and (max-width: 915px) and (orientation: landscape) {\n  .D4dUJw6l86mBrMQIdKsi {\n    padding-top: 70px;\n  }\n  .EOU74DT2IrdUC3TAMDwg {\n    margin-top: 50px;\n  }\n  .EOU74DT2IrdUC3TAMDwg hr {\n    margin: 40px 0 -60px;\n  }\n}\n@media screen and (max-width: 576px) {\n  .D4dUJw6l86mBrMQIdKsi {\n    max-width: 100%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/features/healthCheck/components/WrapperWithTitleAndAnimation/WrapperWithTitleAndAnimation.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;AADF;AAIA;EACE;IACE,iBAAA;EAFF;EAKA;IACE,gBAAA;EAHF;EAEA;IAII,oBAAA;EAHJ;AACF;AAOA;EACE;IACE,eAAA;EALF;AACF","sourcesContent":["@import '../../../../variables.less';\n\n.iconContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100px;\n  height: 100px;\n}\n\n@media screen and (max-height: @mobile-max-height) and (max-width: @mobile-landscape-max-width) and (orientation: landscape) {\n  .question {\n    padding-top: 70px;\n  }\n\n  .iconContainer {\n    margin-top: 50px;\n\n    hr {\n      margin: 40px 0 -60px;\n    }\n  }\n}\n\n@media screen and (max-width: @mobile-max-width) {\n  .question {\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconContainer": "EOU74DT2IrdUC3TAMDwg",
	"question": "D4dUJw6l86mBrMQIdKsi"
};
export default ___CSS_LOADER_EXPORT___;
