import { AxiosInstance } from 'axios'
import { SupportedCountryCode } from '../../../models'
import { VirtualUrlService } from '../../../models/virtualServices'

type CountryCodeFilter = { countryCode?: SupportedCountryCode }
type GeolocationFilter = { location?: { latitude: number; longitude: number } }
type LocalSpecializationFilter = { specializations?: string[] }
type LinkedIcd10CodesFilter = { linkedIcd10Codes?: string[] }

export type FetchVirtualUrlServices = {
  (o: CountryCodeFilter & LocalSpecializationFilter & LinkedIcd10CodesFilter): Promise<VirtualUrlService[]>
  (o: GeolocationFilter & LocalSpecializationFilter & LinkedIcd10CodesFilter): Promise<VirtualUrlService[]>
}

/**
 * @param apiGateway - the api gateway axios instance
 * @returns function fetching virtual url services
 */
export const fetchVirtualUrlServices =
  (medicalServices: AxiosInstance): FetchVirtualUrlServices =>
  async (options) =>
    (await medicalServices.post(`services/virtual`, { ...options, types: ['URL'] })).data.items
