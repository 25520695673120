import React, { FC } from 'react'
import { Article, SectionTitle, StyledRow } from '../Report.styled'
import { Col } from 'antd'
import { RiskFactorTitle } from './RiskFactorTitle'
import { useI18n } from '../../../../../hooks'

export const UnchangeableRiskFactorSection: FC = () => {
  const { i18n } = useI18n()

  return (
    <StyledRow gutter={[32, 10]}>
      <Col span={24}>
        <SectionTitle>{i18n('healthCheck.report.unchangeableRiskFactors.title')}</SectionTitle>
      </Col>
      <Col xs={24} sm={8}>
        <RiskFactorTitle icon="sex" title={i18n('healthCheck.report.unchangeableRiskFactors.sex')} />
        <RiskFactorTitle icon="age" title={i18n('healthCheck.report.unchangeableRiskFactors.age')} />
        <RiskFactorTitle
          icon="familyHistory"
          title={i18n('healthCheck.report.unchangeableRiskFactors.familyHistory')}
        />
      </Col>
      <Col xs={24} sm={16}>
        <Article>
          <p>{i18n('healthCheck.report.unchangeableRiskFactors.paragraphOne')}</p>
          <p>{i18n('healthCheck.report.unchangeableRiskFactors.paragraphTwo')}</p>
          <p>{i18n('healthCheck.report.unchangeableRiskFactors.paragraphThree')}</p>
          <p>{i18n('healthCheck.report.unchangeableRiskFactors.paragraphFour')}</p>
        </Article>
      </Col>
    </StyledRow>
  )
}
