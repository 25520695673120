import React, { FC, useCallback, useEffect, useState } from 'react'
import { Col, Row, Typography } from 'antd'
import { GenericSpecializationType } from 'medical-engine-api'
import { CustomIcon } from '../../../../../CustomIcon/CustomIcon'
import { Specialization, SpecializationType } from '../../../../../../models'
import { useFetchData, useI18n } from '../../../../../../hooks'
import { useServicesContext } from '../../../../../../context'
import {
  FilterDetailedViewClearButton,
  FilterDetailedViewContainer,
  FilterDetailedViewSubmitButton,
} from '../../Filters.common.styled'
import { CSS_VARS } from '../../../../../../resources/cssVariableConfig'
import { SpecializationsFilterDetailedViewFilterItem } from './SpecializationsFilterDetailedViewFilterItem/SpecializationsFilterDetailedViewFilterItem'
import {
  InnerContainer,
  Specialization as SpecializationContainer,
  Title,
} from './SpecializationsFilterDetailedView.styled'

/**
 * @param props The props object
 * @param props.searchText The search term to use for filtering
 * @param props.setIsSpecializationsDetailedViewVisible The setter for whether the specializations detailed view is visible
 * @returns The SpecializationsFilterDetailedView component
 */
export const SpecializationsFilterDetailedView: FC<{
  searchText: string
  setIsSpecializationsDetailedViewVisible: (val: boolean) => void
}> = ({ searchText, setIsSpecializationsDetailedViewVisible }) => {
  const { country, filterObject, setFilterObject } = useServicesContext()

  const { fetchedData } = useFetchData<Specialization[]>(`services/specializations/${country}`)
  const [specializations, setSpecializations] = useState<Specialization[]>()

  const { i18n } = useI18n()

  const [specializationsData, setSpecializationsData] = useState(() => specializations)
  const [selectedSpecializationsFilter, setSelectedSpecializationsFilter] = useState('')

  useEffect(() => {
    if (fetchedData) {
      setSpecializations([...fetchedData].sort((a, b) => (a.name < b.name ? -1 : 1)))
    }
  }, [fetchedData])

  /**
   *
   * @param value The selected gender
   */
  const handleFilterSelect = useCallback(
    (value: string) => {
      if (filterObject?.specializations?.includes(value)) {
        setFilterObject({
          ...filterObject,
          specializations: filterObject?.specializations?.filter((item) => item !== value),
        })
      } else {
        setFilterObject({ ...filterObject, specializations: [...(filterObject.specializations || []), value] })
      }
    },
    [filterObject, setFilterObject],
  )

  const resetFilterSelect = useCallback(() => {
    setFilterObject({
      ...filterObject,
      specializations: [],
    })
  }, [filterObject, setFilterObject])

  /**
   * @param filter The filter to use
   */
  const handleFilterSelection = useCallback(
    (filter: GenericSpecializationType) => {
      if (selectedSpecializationsFilter === filter) {
        setSelectedSpecializationsFilter('')
      } else {
        setSelectedSpecializationsFilter(filter)
      }
    },
    [selectedSpecializationsFilter],
  )

  useEffect(() => {
    if (selectedSpecializationsFilter) {
      setSpecializationsData(specializations?.filter((spec) => spec.type === selectedSpecializationsFilter))
    } else if (searchText) {
      setSpecializationsData(
        specializations?.filter((spec) => spec.name.toLowerCase().includes(searchText.toLowerCase())),
      )
    } else {
      setSpecializationsData(specializations)
    }
  }, [selectedSpecializationsFilter, specializations, searchText])

  return (
    <FilterDetailedViewContainer>
      <Title>{i18n('xund.services.specializations.all')}</Title>

      <InnerContainer>
        <SpecializationsFilterDetailedViewFilterItem
          icon={SpecializationType.Doctor}
          label="xund.services.specializations.doctors"
          isSelected={selectedSpecializationsFilter === SpecializationType.Doctor}
          onClick={() => handleFilterSelection(SpecializationType.Doctor)}
        />

        <SpecializationsFilterDetailedViewFilterItem
          icon={SpecializationType.Pharmacy}
          label="xund.services.specializations.pharmacies"
          isSelected={selectedSpecializationsFilter === SpecializationType.Pharmacy}
          onClick={() => handleFilterSelection(SpecializationType.Pharmacy)}
        />

        <SpecializationsFilterDetailedViewFilterItem
          icon={SpecializationType.Hospital}
          label="xund.services.specializations.hospitals"
          isSelected={selectedSpecializationsFilter === SpecializationType.Hospital}
          onClick={() => handleFilterSelection(SpecializationType.Hospital)}
        />

        {specializationsData?.map((spec) => (
          <SpecializationContainer key={spec.id} onClick={() => handleFilterSelect(spec.id)}>
            <Typography>
              {filterObject?.specializations?.includes(spec.id) ? (
                <>
                  <span style={{ width: '85%', fontWeight: 'bold' }}>{spec.name}</span>
                  <CustomIcon icon="check" />
                </>
              ) : (
                <span style={{ width: '85%' }}>{spec.name}</span>
              )}
            </Typography>
          </SpecializationContainer>
        ))}
      </InnerContainer>

      <Row justify="end">
        <Col>
          <FilterDetailedViewClearButton type="text" onClick={resetFilterSelect}>
            {i18n('xund.services.specializations.clearAll')}
          </FilterDetailedViewClearButton>
        </Col>

        <Col>
          <FilterDetailedViewSubmitButton
            type="primary"
            style={{
              background: CSS_VARS.filledButtonBackgroundColor,
              color: CSS_VARS.filledButtonFontColor,
            }}
            onClick={() => setIsSpecializationsDetailedViewVisible(false)}
          >
            {i18n('xund.general.done')}
          </FilterDetailedViewSubmitButton>
        </Col>
      </Row>
    </FilterDetailedViewContainer>
  )
}
