import styled from '@emotion/styled'
import { Collapse, Row, Typography } from 'antd'
import { CSS_VARS, JS_STYLING } from '../../../../resources/cssVariableConfig'
import { CustomIcon } from '../../../../components/CustomIcon/CustomIcon'
import { LeftOutlined } from '@ant-design/icons'
import { BaseButton } from './FooterSection/FooterSection.styled'

export const Card = styled.div<{ isHeightAdjusted?: boolean }>`
  min-height: ${(props) => (props.isHeightAdjusted ? 'initial' : '586px')};
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.05);
  margin-bottom: 14px;
  padding: 20px 30px;
`

export const Backlink = styled.button`
  align-items: center;
  border: unset;
  background-color: unset;
  cursor: pointer;
  display: flex;
  grid-area: backLink;
  color: #0065bd;
  @media (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    width: 100%;
  }
`

export const BackArrowIcon = styled(LeftOutlined)`
  color: black;
  margin-right: 8px;
`

export const BackLinkText = styled(Typography.Text)`
  color: #0065bd;
  font-family: ${CSS_VARS.fontFamily};
  margin-top: auto;
  min-width: fit-content;
  word-break: normal;
  margin-right: 15px;
`

export const LegendScoreDot = styled.div<{ color: string }>`
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 5px;
`

// TODO color + h1/2
export const SectionTitle = styled(Typography.Text)<{ color?: string; fontSize?: number }>`
  font-size: ${(props) => `${props.fontSize ?? 20}px `};
  font-weight: 700;
  font-family: ${CSS_VARS.fontFamily};
  color: ${(props) => props.color ?? CSS_VARS.questionFontColor};
  line-height: 1.25;
  @media (max-width: ${JS_STYLING.TABLET_MAX_WIDTH}) {
    font-size: 18px;
  }
`
export const SectionSubtitle = styled(Typography.Text)`
  font-size: 16px;
  font-weight: 700;
  font-family: ${CSS_VARS.fontFamily};
  color: ${CSS_VARS.questionFontColor};
`

export const RiskFactorPill = styled.div`
  align-items: center;
  background-color: color-mix(in srgb, ${CSS_VARS.filledButtonBackgroundColor} 10%, white);
  border-radius: 5px;
  color: black;
  display: flex;
  font-family: ${CSS_VARS.fontFamily};
  height: 27px;
  margin-right: 5px;
  margin-bottom: 10px;
  padding: 4px 14px;
  width: fit-content;
`

export const StyledText = styled(Typography.Text, {
  shouldForwardProp: (prop) => !['fontSize', 'isBold'].includes(prop),
})<{ fontSize?: number; isBold?: boolean; color?: string }>`
  font-family: ${CSS_VARS.fontFamily};
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.fontSize ?? 14}px`};
  font-weight: ${(props) => `${props.isBold ? 700 : 400}`};
`

export const RecommendationContainer = styled(Row)`
  margin-top: 10px;
  margin-bottom: 18px;
  padding-left: 15px;
  height: 100%;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: ${CSS_VARS.questionFontColor};
    border-radius: 15px;
  }
`

export const RiskFactorCategoryButton = styled.button<{ fontWeight: number }>`
  color: #00417a;
  font-size: 14px;
  font-weight: ${(props) => `${props.fontWeight}`};
  font-family: ${CSS_VARS.fontFamily} !important;
  border: unset;
  margin-right: 20px;
  background-color: unset;
  cursor: pointer;
  padding: 10px 0;
  display: inline-block;
  :hover {
    font-weight: bold;
  }
  :after {
    display: block;
    content: attr(data-text);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
  :disabled {
    color: #808080;
    :hover {
      font-weight: normal;
      cursor: not-allowed;
    }
  }
`

export const RiskSeverityContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: 0 16px 26px 0 rgba(0, 0, 0, 0.05);
  padding: 20px 30px 70px 30px;
`

export const RiskTopicPillContainer = styled.div`
  display: flex;
  min-width: 100%;
  min-height: 93px;
  flex-wrap: wrap;
  padding: 27px 0 27px 10px;
  border-radius: 15px;
  font-size: 12px;
`

export const RiskTopicPill = styled.div<{ hasBorder?: boolean }>`
  display: flex;
  align-items: center;
  padding: 4px 14px;
  color: #00417a;
  cursor: pointer;
  border: ${(props) => `${props.hasBorder ? '0.5px solid #00417a' : 'unset'}`};
  border-radius: 17px;
  background-color: rgba(0, 101, 189, 0.05);
  min-height: 27px;
  width: fit-content;
  margin-right: 5px;
  margin-bottom: 10px;
  font-family: ${CSS_VARS.fontFamily};
`

export const RiskTopicChartContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  justify-content: center;
  padding: ${(props) => `${props.isMobile ? '90px 0' : '190px 0'}`};
`

export const RiskLevelContainer = styled.div`
  border-radius: 15px;
  box-shadow: 0 16px 26px 0 rgba(0, 0, 0, 0.05);
  height: 100%;
  padding-bottom: 45px;
  padding-top: 20px;

  @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    box-shadow: unset;
    padding-bottom: 0;
    padding-top: 0;
  }
`

export const RiskDescriptionContainer = styled.div`
  border-radius: 15px;
  height: 100%;
  padding: 0 30px 45px 30px;
`

export const RecommendationPanel = styled(Collapse.Panel)`
  &&& {
    margin-bottom: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
  }
`

export const ExaminationPanel = styled(Collapse.Panel)`
  &&& {
    .ant-collapse-header {
      align-items: center;
    }
    margin-bottom: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    background-color: white;
  }
`

export const RecommendationTitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    justify-content: start;
    align-items: start;
    flex-direction: column;
  }
`

export const AssociatedRiskFactorRow = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    flex-direction: column;
    align-items: start;
  }
`

export const AssociatedRiskFactorContainer = styled.div`
  padding: 0 12px;
  border: 0.5px solid black;
  border-radius: 17px;
  margin-left: 10px;
  margin-right: 18px;

  @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    margin: 5px 0 0 0;
  }
`

export const PositiveRiskFactorContainer = styled.div`
  display: flex;
  min-width: 100%;
  flex-wrap: wrap;
  border-radius: 15px;
`

export const ReportContainer = styled.div`
  margin: 0 180px 0 180px;

  @media screen and (max-width: ${JS_STYLING.TABLET_MAX_WIDTH}) {
    margin: 0 20px 0 20px;
  }
`

export const StyledRow = styled(Row)<{ margin?: string }>`
  margin: ${(props) => props.margin ?? 'initial'};
`

export const SectionTitleIcon = styled(CustomIcon)`
  margin-right: 10px;
`

export const BookingButton = styled.button`
  background-color: ${CSS_VARS.filledButtonBackgroundColor};
  color: white;
  border-radius: 17px;
  border: unset;
  padding: 4px 15px;
  cursor: pointer;
`

export const RiskTopicSectionContainer = styled.div`
  margin: 50px 0 60px 0;

  @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    margin: 15px 0 60px 0;
  }
`

export const RiskTopicSectionTitle = styled.div`
  margin-bottom: 20px;
`

export const RiskTopicSelectorButton = styled.button`
  background-color: ${CSS_VARS.filledButtonBackgroundColor};
  color: white;
  border-radius: 17px;
  border: unset;
  padding: 6px 12px;
  cursor: pointer;
  font-family: ${CSS_VARS.fontFamily} !important;
  margin-left: 20px;
  min-width: fit-content;
  max-height: 30px;
`

export const RiskTopicSelector = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 10px;
`

export const RiskTopicSelectorTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    flex-direction: column;
  }
`

export const ProfileRow = styled(Row)`
  justify-content: center;
  margin-bottom: 60px;
`

export const ProfileDataBoxContainer = styled.div`
  align-items: start;
  background-color: #f9f9f9;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px 26px;
`

export const FullWidthBackground = styled.div<{ left: number }>`
  background-color: #f9f9f9;
  height: 100%;
  left: -${(props) => props.left}px;
  position: absolute;
  top: 0;
  width: 100vw;
`
export const ExaminationRow = styled(Row)`
  margin: 60px 0 60px 0;
  padding: 40px 0 40px 0;
`

export const ShowMoreButton = styled.button`
  background-color: unset;
  border: unset;
  cursor: pointer;
  font-family: ${CSS_VARS.fontFamily} !important;
`

export const Article = styled.article`
  color: ${CSS_VARS.chatFontColor};
  font-family: ${CSS_VARS.fontFamily};
  font-size: ${CSS_VARS.chatFontSize};
  font-weight: ${CSS_VARS.chatFontWeight};
  text-align: justify;
  margin-top: 10px;
  white-space: pre-line;
`

export const GhostButton = styled(BaseButton)`
  background-color: unset;
  border: 0;
  box-shadow: unset;
  color: ${CSS_VARS.filledButtonBackgroundColor};
  font-size: 14px;
  height: 30px;

  @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) and (orientation: portrait) {
    background-color: unset;
    box-shadow: unset;
  }
`
