import React, { FC, useState } from 'react'
import {
  MedicalEngineReportGenericSpecialization,
  ClientQuickSolution,
  GenericSpecializationType,
  MedicalEngineReportLocalSpecialization,
} from 'medical-engine-api'
import { Col, Row } from 'antd'
import { useAppStateContext } from '../../context'
import { filterDuplicateValuesFromArray } from '../../utils/filterDuplicateValuesFromArray'
import { useI18n, useScreenSize } from '../../hooks'
import { CSS_VARS } from '../../resources/cssVariableConfig'
import { QuickSolutionsInfoModal } from './QuickSolutionsInfoModal/QuickSolutionsInfoModal'
import {
  Container,
  QuickSolutionsIcon,
  QuickSolutionsItem,
  QuickSolutionsRow,
  QuickSolutionsText,
  SpecializationsIcon,
  SpecializationsItem,
  SpecializationsRow,
  SpecializationsText,
  TransparencyRemoverContainer,
} from './QuickSolutions.styled'
import { useApiKeyScopes } from '../../hooks/useApiKeyScopes'
import { BaseModal } from '../Modals/BaseModal/BaseModal'
import { Heading } from '../xund-ui-legacy-components'

export const LocalSpecializationInfoModal: FC<{
  selectedLocalSpecialization: MedicalEngineReportLocalSpecialization[]
  setSelectedLocalSpecialization: (val: MedicalEngineReportLocalSpecialization[] | null) => void
}> = ({ selectedLocalSpecialization, setSelectedLocalSpecialization }) => {
  const { i18n } = useI18n()

  return (
    <BaseModal
      onCancel={() => setSelectedLocalSpecialization(null)}
      title={
        <>
          <Heading as={'h4'} style={{ fontSize: CSS_VARS.modalTitleFontSize, color: CSS_VARS.modalTitleFontColor }}>
            {i18n('xund.general.medicalSpecialization')}
          </Heading>
        </>
      }
      hasButtons={false}
    >
      {selectedLocalSpecialization.map((localSpecialization) => {
        return (
          <p key={crypto.randomUUID()} style={{ fontSize: '14px', marginBottom: 0 }}>
            {localSpecialization.name}
          </p>
        )
      })}
    </BaseModal>
  )
}

/**
 * @param props The props object
 * @param props.quickSolutions The current quick solutions
 * @param props.specializations The current specializations
 * @param props.inReport Whether the component is used in report
 * @returns The QuickSolutions component
 */
export const QuickSolutions: FC<{
  quickSolutions: ClientQuickSolution[]
  specializations: MedicalEngineReportGenericSpecialization[]
  localSpecializations?: MedicalEngineReportLocalSpecialization[]
  inReport?: boolean
}> = ({ quickSolutions, specializations, localSpecializations, inReport }) => {
  const [selectedQuickSolution, setSelectedQuickSolution] = useState<ClientQuickSolution | null>(null)
  const [selectedLocalSpecialization, setSelectedLocalSpecialization] = useState<
    MedicalEngineReportLocalSpecialization[] | null
  >(null)

  const { isMobileView } = useScreenSize()
  const { setServicesOpen, setSideMenuOpen, isCheckOnlyMode } = useAppStateContext()
  const { hasApiKeyScope } = useApiKeyScopes()

  /**
   * Returns the icon name for a generic specialization type
   *
   * @param type The generic specialization type in question
   * @returns Icon name
   */
  const getIcon = function (type: GenericSpecializationType): string {
    switch (type) {
      case 'HOSPITAL':
        return 'hospitalFilled'
      case 'DOCTOR':
        return 'doctorFilled'
      case 'PHARMACY':
        return 'pharmacyFilled'
      default:
        return ''
    }
  }

  const quickSolutionsElements = (
    <QuickSolutionsRow>
      {quickSolutions.map((quickSolution) => (
        <QuickSolutionsItem
          key={quickSolution.id}
          onClick={() => setSelectedQuickSolution(quickSolution)}
          radius={'md'}
          shadow={4}
        >
          <TransparencyRemoverContainer>
            <QuickSolutionsIcon icon={quickSolution.iconUrl} color={CSS_VARS.questionFontColor} colorizeByPlainCss />
          </TransparencyRemoverContainer>

          <QuickSolutionsText>{quickSolution.name}</QuickSolutionsText>
        </QuickSolutionsItem>
      ))}

      {selectedQuickSolution && (
        <QuickSolutionsInfoModal
          selectedQuickSolution={selectedQuickSolution}
          setSelectedQuickSolution={setSelectedQuickSolution}
        />
      )}
    </QuickSolutionsRow>
  )

  const specializationsElements = (
    <SpecializationsRow>
      {filterDuplicateValuesFromArray(specializations.map((item) => item.type)).map((item) => (
        <SpecializationsItem
          key={item}
          onClick={() => {
            if (!hasApiKeyScope('query_services') || isCheckOnlyMode) {
              if (localSpecializations?.length && item === 'DOCTOR') {
                setSelectedLocalSpecialization(localSpecializations)
              }

              return
            }

            document.getElementById('header')?.scrollIntoView()

            if (!isMobileView) {
              setSideMenuOpen(true)
            }

            setServicesOpen(true)
          }}
          radius={'md'}
          shadow={4}
        >
          <SpecializationsIcon icon={getIcon(item)} />

          <SpecializationsText>{`${item.slice(0, 1)}${item.toLowerCase().slice(1)}`}</SpecializationsText>
        </SpecializationsItem>
      ))}

      {selectedLocalSpecialization && (
        <LocalSpecializationInfoModal
          selectedLocalSpecialization={selectedLocalSpecialization}
          setSelectedLocalSpecialization={setSelectedLocalSpecialization}
        />
      )}
    </SpecializationsRow>
  )

  return (
    <Container className="descriptionFadeIn">
      {(quickSolutions.length ?? 0) > 0 &&
        (inReport ? (
          <Row>
            <Col xs={{ span: 18, offset: 0 }} md={{ offset: 4, span: 18 }}>
              {quickSolutionsElements}
            </Col>
          </Row>
        ) : (
          quickSolutionsElements
        ))}

      {(specializations.length ?? 0) > 0 && inReport ? (
        <Row>
          <Col xs={{ span: 18, offset: 0 }} md={{ offset: 4, span: 18 }}>
            {specializationsElements}
          </Col>
        </Row>
      ) : (
        specializationsElements
      )}
    </Container>
  )
}
