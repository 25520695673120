import styled from '@emotion/styled'
import { Col, Row } from 'antd'
import { CSS_VARS, JS_STYLING } from '../../../../resources/cssVariableConfig'

export const MainRow = styled(Row)`
  flex: 1;
`

export const MainCol = styled(Col)<{ isBoxShadowHidden?: boolean; isReport?: boolean }>`
  align-items: center;
  background: ${CSS_VARS.chatBackgroundColor};
  border-radius: 15px;
  box-shadow: ${(props) => (props.isBoxShadowHidden ? 'none' : '0px 16px 26px 0px rgba(0,0,0,0.05)')};
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-height: 550px;
  padding: 24px 24px 50px 24px;
  margin-top: 50px;
  ${(props) => props.isReport && `margin-top: 10px;`}

  @media screen and (max-height: ${JS_STYLING.MOBILE_MAX_HEIGHT}) and (max-width: ${JS_STYLING.MOBILE_LANDSCAPE_MAX_WIDTH}) and (orientation: landscape),
    screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    border-radius: unset;
    min-width: 100%;
    min-height: initial;
    padding: 23px;
    ${(props) => props.isReport && `margin: 0;`}
  }
`

export const FullSizeContainer = styled.div`
  height: 100%;
  width: 100%;
`
