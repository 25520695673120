import styled from '@emotion/styled'
import { Row, Typography } from 'antd'
import { CSS_VARS, JS_STYLING } from '../../../../resources/cssVariableConfig'
import { DownOutlined } from '@ant-design/icons'

export const StyledRow = styled(Row)`
  margin-top: 45px;

  @media (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    margin-top: 15px;
  }
`

export const SelectArrow = styled(DownOutlined)`
  color: black;
`

export const LabelContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`

export const Label = styled(Typography.Text)<{ color?: string }>`
  color: ${(props) => (props.color ? props.color : 'black')};
  font-family: ${CSS_VARS.fontFamily};
`

export const InfoBoxContainer = styled.div`
  margin: 0 auto;
  width: fit-content;
`
