import { Card, Col, Row } from 'antd'
import styled from '@emotion/styled'

export const StyledCard = styled(Card)`
  border-radius: 15.5px;
  margin: 10px 20px;

  :hover {
    background-color: white !important;
    border: 1px solid #f0f0f0 !important;
  }

  .ant-card-body {
    margin: 0;
    padding: 15px 10px;
  }
`

export const StyledRow = styled(Row)`
  flex-wrap: nowrap;
`

export const StyledCol = styled(Col)`
  margin-right: 10px;
`

export const Details = styled.div`
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }
`
