import React, { FC } from 'react'
import { Col } from 'antd'
import { useI18n } from '../../../../hooks'
import { SectionSubtitle, StyledRow, StyledText } from './Report.styled'
import { CheckIdText } from './FooterSection/FooterSection.styled'
import { useQuestionContext } from '../../context'

export const DisclaimerSection: FC = () => {
  const { i18n } = useI18n()
  const { checkId } = useQuestionContext()
  return (
    <StyledRow gutter={[10, 10]} margin="0 0 36px 0">
      <Col span={24}>
        <SectionSubtitle>{i18n('xund.general.important')}</SectionSubtitle>
      </Col>
      <Col span={24}>
        <StyledText>{i18n('healthCheck.report.disclaimer')}</StyledText>
      </Col>
      <Col span={24} style={{ marginTop: 10 }}>
        <CheckIdText>{`${i18n('healthCheck.report.footer.checkId')} ${checkId}`}</CheckIdText>
      </Col>
    </StyledRow>
  )
}
