import React, { FC, useMemo, useState } from 'react'
import { Col } from 'antd'
import { SectionTitle, StyledText } from '../Report.styled'
import { useI18n } from '../../../../../hooks'
import { BarContainer, BarPointer, BMINumber, MainContainer } from './BMIDataBox.styled'
import { Flex } from '../../Flex'
import { Modal } from '../../../../../common/Modals/Modal'
import { BarSegment } from './BarSegment'

interface Props {
  value: number
}
export const BMIDataBox: FC<Props> = (props: Props) => {
  const { value } = props
  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const minBMI = 16
  const maxBMI = 40
  const normalMin = 18.5
  const overWeightMin = 25
  const obeseMin = 30

  const range = maxBMI - minBMI
  const buffer = 0.15 //Used for better representation on the bar
  const { i18n } = useI18n()

  const validatedBMI = useMemo(() => {
    if (value <= minBMI) {
      return buffer
    }
    if (value >= maxBMI) {
      return range - buffer
    }
    return value - minBMI
  }, [value, range])

  const pinPosition: number = useMemo(() => {
    return (validatedBMI / range) * 100
  }, [range, validatedBMI])

  const bmiRangeName = useMemo(() => {
    if (value >= obeseMin) {
      return 'obese'
    }
    if (value >= overWeightMin) {
      return 'overweight'
    }
    if (value >= normalMin) {
      return 'normal'
    }

    return 'underweight'
  }, [value])

  return (
    <Col xxl={{ span: 8 }} xl={{ span: 8 }} lg={{ span: 16 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
      <MainContainer>
        <Flex flexDirection="column" style={{ marginBottom: 20 }}>
          <Flex justifyContent="space-between" style={{ width: '100%' }}>
            <StyledText>{i18n('healthCheck.report.profile.bmi')}</StyledText>
            <button
              style={{ border: 'unset', background: 'unset', cursor: 'pointer' }}
              onClick={() => setModalOpen((prevState) => !prevState)}
            >
              <StyledText fontSize={12}>{i18n('healthCheck.report.bmi.infoButton')}</StyledText>
            </button>
          </Flex>
          <Flex>
            <BMINumber>{value}</BMINumber>
            <SectionTitle color="black" fontSize={16}>
              - {bmiRangeName}
            </SectionTitle>
          </Flex>
        </Flex>
        <BarContainer>
          <BarSegment
            color="#00ADEF"
            widthPercent={11}
            isFirst={true}
            tooltip={i18n('healthCheck.report.bmi.category.underWeight')}
          />
          <BarSegment color="#66D096" widthPercent={27} tooltip={i18n('healthCheck.report.bmi.category.normal')} />
          <BarSegment color="#FF9248" widthPercent={20} tooltip={i18n('healthCheck.report.bmi.category.overWeight')} />
          <BarSegment
            color="#FF6666"
            widthPercent={42}
            isLast={true}
            tooltip={i18n('healthCheck.report.bmi.category.obese')}
          />
          <BarPointer position={pinPosition} />
        </BarContainer>
      </MainContainer>
      <Modal
        isOpen={isModalOpen}
        onCancel={() => setModalOpen(false)}
        title={i18n('healthCheck.report.bmi.infoButton')}
      >
        <StyledText>{i18n('healthCheck.report.bmi.modalText')}</StyledText>
      </Modal>
    </Col>
  )
}
