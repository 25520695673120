import React, { FC } from 'react'
import { MultiSelectAnswer } from 'health-check-api'
import { useI18n } from '../../../hooks'
import { InfoBox } from './InfoBox'
import { SelectQuestionWrapper } from './SelectQuestionWrapper'
import { SelectInput } from '../../../common'

interface Props {
  title: string
  onDropdownSelect: (value: string | string[]) => void
  answers: MultiSelectAnswer[]
  isLoading: boolean
  questionDescription?: string
}

export const MultiSelect: FC<Props> = (props) => {
  const { title, onDropdownSelect, answers, isLoading, questionDescription } = props
  const { i18n } = useI18n()

  return (
    <SelectQuestionWrapper title={title} isLoading={isLoading}>
      <>
        <SelectInput
          isMulti={true}
          options={answers.map((answer) => ({
            label: answer.text,
            value: answer.id,
            excluding: answer.params.excluding,
          }))}
          placeholder={i18n('components.multiSelect.placeholder')}
          onSelect={(values) => {
            onDropdownSelect(values)
          }}
        />
        {questionDescription && <InfoBox contextText={questionDescription} title={title} />}
      </>
    </SelectQuestionWrapper>
  )
}
