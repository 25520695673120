import React, { FC } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { CSS_VARS } from '../../resources/cssVariableConfig'

interface Props {
  color?: 'primary' | 'secondary'
}
export const LoadingIndicator: FC<Props> = (props) => {
  const { color = 'primary' } = props

  return (
    <div className="loadingIndicator">
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              color:
                color === 'primary' ? CSS_VARS.filledButtonBackgroundColor : CSS_VARS.outlinedButtonBackgroundColor,
            }}
          />
        }
        style={{ width: '100%' }}
      />
    </div>
  )
}
