import { GrowingScaleQuestion, QUESTION_STATE_HEALTH_DATA, QUESTION_TYPE_GROWINGSCALE } from 'health-check-api'

export const mockGrowingScaleQuestion: GrowingScaleQuestion = {
  id: 'question2',
  text: 'How many cigarettes do you smoke daily?',
  description: '',
  type: QUESTION_TYPE_GROWINGSCALE,
  state: QUESTION_STATE_HEALTH_DATA,
  answers: [
    {
      id: 'q2a1',
      text: '1-10',
      params: {
        from: 1,
        to: 10,
      },
    },
    {
      id: 'q2a2',
      text: '11-15',
      params: {
        from: 11,
        to: 15,
      },
    },
    {
      id: 'q2a3',
      text: '16-20',
      params: {
        from: 16,
        to: 20,
      },
    },
    {
      id: 'q2a4',
      text: 'more than 20',
      params: {
        from: 21,
        to: 30,
      },
    },
  ],
  params: {
    scaleStep: 1,
  },
  canMoveBack: true,
}
