import React, { FC } from 'react'
import { BarColoredSegment } from './BMIDataBox.styled'
import { Tooltip } from 'antd'
import { StyledText } from '../Report.styled'

interface Props {
  tooltip: string
  color: string
  widthPercent: number
  isFirst?: boolean
  isLast?: boolean
}

export const BarSegment: FC<Props> = (props) => {
  const { tooltip, color, widthPercent, isFirst, isLast } = props
  return (
    <Tooltip title={<StyledText>{tooltip}</StyledText>} color="white">
      <BarColoredSegment
        color={color}
        widthPercent={widthPercent}
        isFirst={isFirst ?? false}
        isLast={isLast ?? false}
      />
    </Tooltip>
  )
}
