import React, { FC, useMemo } from 'react'
import { Backlink, BackLinkText } from './Report.styled'
import { mobileMaxWidth, useI18n } from '../../../../hooks'
import { useWindowSize } from 'usehooks-ts'
import { LeftOutlined } from '@ant-design/icons'
import { IconButton } from '../../../../components/General/IconButton/IconButton'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'

interface Props {
  onClick: () => void
}

export const BackToOverviewButton: FC<Props> = (props) => {
  const { onClick } = props
  const { width: windowWidth } = useWindowSize()
  const isMobile = useMemo(() => windowWidth <= mobileMaxWidth, [windowWidth])
  const { i18n } = useI18n()

  return (
    <>
      {!isMobile && (
        <Backlink onClick={onClick}>
          <BackLinkText>{i18n('healthCheck.report.multiTopic.backlink')}</BackLinkText>
        </Backlink>
      )}
      {isMobile && (
        <IconButton
          style={{
            backgroundColor: CSS_VARS.chatBackgroundColor,
            color: '#0065bd',
          }}
          icon={<LeftOutlined />}
          onClick={onClick}
        />
      )}
    </>
  )
}
