import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Col } from 'antd'
import { WrapperWithTitleAndAnimation } from '../../../../components/WrapperWithTitleAndAnimation'
import { useI18n } from '../../../../hooks'
import { StyledRow } from './BMI.styled'
import { calculateBMI, MAX_HEIGHT, MAX_WEIGHT, MIN_HEIGHT, MIN_WEIGHT } from 'health-check-api'
import { NumberInput } from '../../../../common'
import { useDebounce } from 'usehooks-ts'

interface Props {
  isLoading: boolean
  title: string
  onCalculation: (calculatedBMI: [string, string] | null) => void
}

export const BMI: FC<Props> = (props: Props) => {
  const { isLoading, title, onCalculation } = props
  const [weight, setWeight] = useState<number | undefined>()
  const [height, setHeight] = useState<number | undefined>()
  const [isInputChanged, setInputChanged] = useState<boolean>(false)
  const { i18n } = useI18n()

  const debouncedWeight = useDebounce(weight, 500)
  const debouncedHeight = useDebounce(height, 500)

  const calculatedBMI = useMemo(() => {
    if (debouncedHeight === undefined || debouncedWeight === undefined) {
      return null
    }
    if (
      debouncedHeight < MIN_HEIGHT ||
      debouncedWeight < MIN_WEIGHT ||
      debouncedHeight > MAX_HEIGHT ||
      debouncedWeight > MAX_WEIGHT
    ) {
      return null
    }
    return calculateBMI({ height: debouncedHeight, weight: debouncedWeight })
  }, [debouncedHeight, debouncedWeight])

  const hasHeightError = useMemo(() => {
    if (debouncedHeight === undefined) {
      return false
    }
    if (debouncedHeight > MAX_HEIGHT || debouncedHeight < MIN_HEIGHT) {
      return true
    }
  }, [debouncedHeight])

  const hasWeightError = useMemo(() => {
    if (debouncedWeight === undefined) {
      return false
    }
    if (debouncedWeight > MAX_WEIGHT || debouncedWeight < MIN_WEIGHT) {
      return true
    }
  }, [debouncedWeight])

  const handleHeightChange = useCallback(
    (value: unknown) => {
      if (typeof value !== 'number') {
        return
      }
      setHeight(value)
      setInputChanged(true)
    },
    [setHeight],
  )

  const handleWeightChange = useCallback(
    (value: unknown) => {
      if (typeof value !== 'number') {
        return
      }
      setWeight(value)
      setInputChanged(true)
    },
    [setWeight],
  )

  useEffect(() => {
    if (!isInputChanged) {
      return
    }
    if (debouncedHeight && debouncedWeight && calculatedBMI) {
      onCalculation([debouncedHeight.toString(), debouncedWeight.toString()])
      setInputChanged(false)
    } else {
      onCalculation(null)
    }
  }, [isInputChanged, height, weight, calculatedBMI, onCalculation, debouncedHeight, debouncedWeight])

  return (
    <WrapperWithTitleAndAnimation isAnimated={isLoading} title={title}>
      <StyledRow gutter={[10, 20]}>
        <Col span={24}>
          <NumberInput
            isErroneous={hasHeightError}
            label={i18n('components.bmi.height.title')}
            id="heightInput"
            placeholder={i18n('components.bmi.height.placeholder')}
            onChange={(value) => handleHeightChange(value)}
            errorMessage={i18n('components.bmi.height.error')}
          />
        </Col>
      </StyledRow>
      <StyledRow style={{ marginBottom: 16 }}>
        <Col span={24}>
          <NumberInput
            isErroneous={hasWeightError}
            label={i18n('components.bmi.weight.title')}
            id="weightInput"
            placeholder={i18n('components.bmi.weight.placeholder')}
            onChange={(value) => handleWeightChange(value)}
            errorMessage={i18n('components.bmi.weight.error')}
          />
        </Col>
      </StyledRow>
    </WrapperWithTitleAndAnimation>
  )
}
