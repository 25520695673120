import React, { FC, useCallback, useMemo, useState } from 'react'
import { Col } from 'antd'
import { WrapperWithTitleAndAnimation } from '../../../../components/WrapperWithTitleAndAnimation'
import { InfoBox } from '../InfoBox'
import { InfoBoxContainer, Label, StyledRow } from './FamilyHistory.styled'
import { useI18n } from '../../../../hooks'
import { useWindowSize } from 'usehooks-ts'
import { FamilyHistoryAnswer } from 'health-check-api'
import { FamilyMemberSection } from './FamilyMemberSection'

export const FAMILY_HISTORY_NONE_ANSWER = 'None'
export const FAMILY_HISTORY_I_DONT_KNOW_ANSWER = 'IDontKnow'
const MOTHER = 'mother'
const FATHER = 'father'
const MD_BREAKPOINT = 768

export type FamilyMember = 'mother' | 'father' | 'grandFather' | 'grandMother'

interface Props {
  isLoading: boolean
  title: string
  conditions: Condition[]
  parent: 'mother' | 'father'
  questionDescription?: string
  selectedAnswer: FamilyHistoryAnswer
  onSelectAnswer: (answer: FamilyHistoryAnswer) => void
}

interface Condition {
  id: string
  name: string
}

export const FamilyHistory: FC<Props> = (props) => {
  const { isLoading, title, conditions, parent, questionDescription, selectedAnswer, onSelectAnswer } = props
  const [parentValue, setParentValue] = useState<string[]>([])
  const [grandMotherValue, setGrandMotherValue] = useState<string[]>([])
  const [grandFatherValue, setGrandFatherValue] = useState<string[]>([])
  const { width: windowWidth } = useWindowSize()
  const isSmallScreenSize = useMemo(() => windowWidth < MD_BREAKPOINT, [windowWidth])

  const { i18n } = useI18n()

  const groupedOptions = useMemo(() => {
    const groups: Record<string, Record<string, any>> = {}
    conditions
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach((answer) => {
        if (!groups[answer.name[0]]) {
          groups[answer.name[0]] = {
            label: answer.name[0],
            options: [{ value: answer.id, label: answer.name }],
          }
        } else {
          groups[answer.name[0]].options.push({ value: answer.id, label: answer.name })
        }
      })
    return [
      { value: FAMILY_HISTORY_NONE_ANSWER, label: i18n('components.familyHistory.none'), excluding: true },
      {
        value: FAMILY_HISTORY_I_DONT_KNOW_ANSWER,
        label: i18n('components.familyHistory.I_dont_know'),
        excluding: true,
      },
      ...Object.values(groups),
    ]
  }, [conditions, i18n])

  const updateSelectionValue = useCallback(
    (value: string[], option: Record<string, any>) => {
      let updatedValue = value
      if (Array.isArray(option) && option.length > 0) {
        const lastOption = option[option.length - 1]
        if (lastOption.excluding) {
          updatedValue = [lastOption.value]
        }
        if (!lastOption.excluding) {
          updatedValue = groupedOptions
            .flatMap((optionGroup) => (optionGroup?.options ? optionGroup.options : optionGroup))
            .filter((currentOption) => !currentOption.excluding && value.includes(currentOption.value))
            .map((currentOption) => currentOption.value)
        }
      }
      return updatedValue
    },
    [groupedOptions],
  )

  const setVisibleAnswers = useCallback(
    (familyMember: FamilyMember, transformedValues: FamilyHistoryAnswer, memberIndex: number) => {
      if ([FATHER, MOTHER].includes(familyMember)) {
        setParentValue(transformedValues[memberIndex])
      }
      if (familyMember === 'grandFather') {
        setGrandFatherValue(transformedValues[memberIndex])
      }
      if (familyMember === 'grandMother') {
        setGrandMotherValue(transformedValues[memberIndex])
      }
    },
    [],
  )

  const onSelect = useCallback(
    (familyMember: FamilyMember, value: string[], option: Record<string, any>) => {
      const memberIndex = [FATHER, MOTHER].includes(familyMember) ? 0 : familyMember === 'grandMother' ? 1 : 2
      const filteredSelectValues = updateSelectionValue(value, option)

      let transformedValues = selectedAnswer

      if (selectedAnswer === null || !Array.isArray(selectedAnswer[0])) {
        transformedValues = [[], [], []]
      }
      transformedValues[memberIndex] = filteredSelectValues
      onSelectAnswer([...transformedValues])
      setVisibleAnswers(familyMember, transformedValues, memberIndex)
    },
    [onSelectAnswer, selectedAnswer, setVisibleAnswers, updateSelectionValue],
  )

  return (
    <WrapperWithTitleAndAnimation isAnimated={isLoading} title={title}>
      <StyledRow gutter={isSmallScreenSize ? [5, 5] : [15, 15]}>
        {!isSmallScreenSize && (
          <Col span={5}>
            <Label color="#00417A">
              {parent === 'mother'
                ? i18n('components.familyHistory.motherSide')
                : i18n('components.familyHistory.fatherSide')}
            </Label>
          </Col>
        )}
        {!isSmallScreenSize && (
          <Col span={19}>
            <Label color="#808080">{i18n('components.familyHistory.conditions')}</Label>
          </Col>
        )}
        <FamilyMemberSection
          groupedOptions={groupedOptions}
          familyMember={parent}
          visibleValue={parentValue}
          onSelect={onSelect}
        />
        <FamilyMemberSection
          groupedOptions={groupedOptions}
          familyMember="grandMother"
          visibleValue={grandMotherValue}
          onSelect={onSelect}
        />
        <FamilyMemberSection
          groupedOptions={groupedOptions}
          familyMember="grandFather"
          visibleValue={grandFatherValue}
          onSelect={onSelect}
        />
      </StyledRow>
      {questionDescription && (
        <InfoBoxContainer>
          <InfoBox contextText={questionDescription} title={title} />
        </InfoBoxContainer>
      )}
    </WrapperWithTitleAndAnimation>
  )
}
