import { useEffect } from 'react'

export const usePreventNavigationHook = (disabled = false) => {
  useEffect(() => {
    if (disabled) {
      return
    }
    const handler = (event: any) => event.preventDefault()
    window.addEventListener('beforeunload', handler)
    return () => {
      window.removeEventListener('beforeunload', handler)
    }
  }, [disabled])
}
