import styled from '@emotion/styled'

export const RiskClassesContainer = styled.div`
  background-color: #f9f9f9;
  border-radius: 15px;
  height: 100%;
  padding: 20px;
  width: 100%;
`
export const RiskClassSeverityBar = styled.div<{ color: string; width: number }>`
  background-color: ${(props) => props.color};
  border-radius: 100px;
  height: 5px;
  width: ${(props) => `${props.width}%`};
`
