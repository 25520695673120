import React, { FC } from 'react'
import { AGE_QUESTION_MAX, AGE_QUESTION_MIN } from '../../../constants'
import { useI18n } from '../../../hooks'
import { ChatbotQuestionAnswer, Question } from '../../../models'
import { SelectInput } from '../SelectInput'

/**
 * @param props The props object
 * @param props.question The current question
 * @param props.animationStartsWhen The boolean that decides when to start the animation
 * @param props.isLoading The boolean that decides whether the app is loading
 * @param props.sendAnswer The method that sends the answer
 * @returns The AgeSelect component
 */
export const AgeSelect: FC<{
  question: Question
  animationStartsWhen: boolean
  isLoading: boolean
  sendAnswer: (val: ChatbotQuestionAnswer) => void
}> = ({ question, animationStartsWhen, isLoading, sendAnswer }) => {
  const { i18n } = useI18n()
  /**
   * Creates an array of numbers according to params in ascending order
   *
   * @param from start the array with this number (inclusive)
   * @param to end the array with this number (inclusive)
   * @returns An array of numbers in ascending order
   */
  const range = (from: number, to: number) => {
    const size = Math.abs(to - from) + 1
    return [...Array(size).keys()].map((i) => i + Math.min(from, to))
  }

  const options: number[] = range(AGE_QUESTION_MIN, AGE_QUESTION_MAX).sort((a, b) => b - a)

  return (
    <SelectInput
      question={question}
      animationStartsWhen={animationStartsWhen}
      isLoading={isLoading}
      sendAnswer={sendAnswer}
      options={options.map((year) => {
        return { label: year.toString(), value: year.toString() }
      })}
      placeholder={i18n('questions.yearOfBirth.placeholder')}
    />
  )
}
