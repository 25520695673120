import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 70px auto 0;
  padding: 0 5px 5px 5px;
  position: relative;
  width: 100%;
`
export const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 40px;
  z-index: 1;
`
