import React, { CSSProperties, FC, ReactNode, useMemo } from 'react'
import { Slider } from 'antd'
import { GrowingScaleAnswer } from 'health-check-api'
import { useBreakpoints } from '../../../../hooks'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'
import { SliderLabel } from './ScaleSlider.styled'

export const ScaleSlider: FC<{
  values: GrowingScaleAnswer[]
  value: number
  setValue: (val: number) => void
  minValue: number
  maxValue: number
  stepSize: number
}> = ({ values, value, setValue, minValue, maxValue, stepSize }) => {
  const { isScreenSizeXS } = useBreakpoints()

  const scaleQuestionMarks = useMemo(() => {
    const marks: {
      [key: number]: {
        style: CSSProperties
        label: ReactNode
      }
    } = {}
    values.forEach((mark: GrowingScaleAnswer, index: number) => {
      let markIndex: number
      let position: CSSProperties

      if (mark?.params?.from === minValue) {
        markIndex = minValue
        position = { left: 0, transform: 'none' }
      } else if (mark?.params?.to === maxValue) {
        markIndex = maxValue
        position = { left: 'auto', right: 0, transform: 'none' }
      } else {
        markIndex = ((mark?.params?.from as number) + (mark?.params?.to as number) + minValue) / 2
        const left = ((markIndex - minValue) / (maxValue - minValue)) * 100
        position = { left: `${left}%` }
      }
      if (index === 0 || index + 1 === values.length) {
        marks[markIndex] = {
          style: { top: '5px', ...position },
          label: (
            <SliderLabel
              color={CSS_VARS.outlinedButtonFontColor}
              fontSize={isScreenSizeXS ? '3vw' : CSS_VARS.chatFontSize}
              textAlign={index + 1 === values.length ? 'right' : 'left'}
            >
              {values[index].text}
            </SliderLabel>
          ),
        }
      }
    })

    const indicatorPosition = ((value - minValue) / (maxValue - minValue)) * 100

    marks[maxValue + 100] = {
      style: { top: '-40px', left: `${indicatorPosition}%` },
      label: (
        <span
          style={{ color: CSS_VARS.outlinedButtonFontColor, fontSize: isScreenSizeXS ? '3vw' : CSS_VARS.chatFontSize }}
        >
          <strong>{value}</strong>
        </span>
      ),
    }

    return marks
  }, [values, value, minValue, maxValue, isScreenSizeXS])

  return (
    <Slider
      id="scale_slider"
      min={minValue}
      max={maxValue}
      marks={scaleQuestionMarks}
      // Z-index values are needed for making the background of the track dynamically stylable
      trackStyle={{ backgroundColor: CSS_VARS.sliderTrackColor, zIndex: 1 }}
      handleStyle={{
        borderColor: CSS_VARS.sliderHandleColor,
        backgroundColor: CSS_VARS.chatBackgroundColor,
        zIndex: 2,
      }}
      step={stepSize}
      tooltip={{ open: false }}
      onChange={(val) => setValue(val)}
      value={value}
    />
  )
}
