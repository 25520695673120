import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import { Col, Progress, Row } from 'antd'
import { useWindowSize } from 'usehooks-ts'
import { MedicalEngineReportIllness } from 'medical-engine-api'
import { CustomIcon } from '../../CustomIcon/CustomIcon'
import { mobileMaxWidth, useI18n } from '../../../hooks'
import { QuickSolutions } from '../../QuickSolutions/QuickSolutions'
import { EmergencyWarningWithCallNumber } from '../../QuickSolutions/EmergencyWarningWithCallNumber/EmergencyWarningWithCallNumber'
import { VirtualUrlService } from '../../../models/virtualServices'
import { FetchVirtualUrlServices, VirtualUrlServiceList } from '../VirtualUrlServices'
import { useQuestionContext } from '../../../context/QuestionContext'
import { SpecializationType } from '../../../models'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import styles from './ReportItem.module.less'
import { CHECK_TYPE_ILLNESS_CHECK } from '../../../context'

interface Extended {
  /**
   * The illness id
   */
  id: string
  /**
   * Whether the container is open
   */
  open: boolean
  /**
   * The index of the illness
   */
  index: number | null
}

/**
 * @param props The props object
 * @param props.illness A possible illness
 * @param props.illnessIndex The index of the illness
 * @param props.svgIcon The SVG icon corresponding to the illness
 * @param props.fetchVirtualUrlServices Function to fetch virtual url services
 * @param props.location Geolocation of the user (if not given, virtual url services won't be displayed)
 * @param props.startOpen The boolean that decides if the collapse is opened by default
 * @param props.showFactorScores The boolean that decides if the factorScores are shown
 * @returns The ReportItem component
 */
export const ReportItem: FC<{
  illness: MedicalEngineReportIllness
  illnessIndex: number
  svgIcon: string
  fetchVirtualUrlServices?: FetchVirtualUrlServices
  location?: GeolocationPosition | null
  startOpen?: boolean
  showFactorScores?: boolean
}> = ({
  illness,
  illnessIndex,
  svgIcon,
  fetchVirtualUrlServices,
  location,
  startOpen = false,
  showFactorScores = false,
}) => {
  const [extended, setExtended] = useState<Extended>({
    id: startOpen ? illness.id : '',
    open: startOpen,
    index: startOpen ? illnessIndex : null,
  })
  const [virtualUrlServices, setVirtualUrlServices] = useState<VirtualUrlService[]>([])
  const { width: windowWidth } = useWindowSize()

  const { i18n } = useI18n()

  const { checkType } = useQuestionContext()

  const isMobile = useMemo(() => windowWidth <= mobileMaxWidth, [windowWidth])

  /**
   * Extends an illness item
   *
   * @param id The id of the illness
   * @param extendedIllnessIndex The index of the illness in the list of illnesses on the report
   * @returns Sets the extended state of the illness item
   */
  const extendOption = useCallback(
    (id: string, extendedIllnessIndex: number) =>
      setExtended({
        id,
        open: extended.id === id && extended.index === extendedIllnessIndex ? !extended.open : true,
        index: extendedIllnessIndex,
      }),
    [extended.id, extended.index, extended.open],
  )

  const isAnItemExtended = useMemo(
    () => extended.open && extended.index === illnessIndex,
    [extended.index, extended.open, illnessIndex],
  )

  const isEmergency = useMemo(
    () => illness.specializations.some((item) => item.type === SpecializationType.Hospital),
    [illness.specializations],
  )

  const hasSuggestedMeasures = useMemo(
    () => illness.specializations || illness.quickSolutions || isEmergency,
    [illness, isEmergency],
  )

  useEffect(() => {
    const specializations = illness.localSpecializations?.length
      ? [...new Set(illness.localSpecializations?.map((localSpec) => localSpec.id))]
      : undefined

    isAnItemExtended &&
      virtualUrlServices.length === 0 &&
      fetchVirtualUrlServices &&
      fetchVirtualUrlServices({
        location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
        specializations,
        linkedIcd10Codes: [illness.icd10],
      }).then(setVirtualUrlServices)
  }, [fetchVirtualUrlServices, location, illness, isAnItemExtended, virtualUrlServices.length])

  return (
    <>
      <div
        className={classnames(styles.container, 'chatMessageFadeIn')}
        onClick={() => extendOption(illness.id, illnessIndex)}
      >
        <div className={classnames(styles.textContainer, styles.textLeft)}>
          <Row
            className={classnames(styles.textDiv, styles.text, 'chatFont', 'chatItemPadding')}
            style={{ borderBottom: isAnItemExtended ? 'unset' : '1px solid #f2f2f2' }}
          >
            <Col span={4} style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ display: 'flex' }}>
                <Progress
                  type="circle"
                  percent={Math.round(Math.min(Math.max(illness.score, 5), 95))}
                  strokeColor={CSS_VARS.questionFontColor}
                  strokeWidth={10}
                  trailColor={CSS_VARS.reportProgressBackgroundColor}
                  width={36}
                  strokeLinecap="square"
                  format={() => (
                    <div className={styles.illnessIcon}>
                      <CustomIcon icon={svgIcon} style={{ height: 21, width: 21 }} color={CSS_VARS.questionFontColor} />
                    </div>
                  )}
                />
              </div>
            </Col>
            <Col span={18} style={{ paddingLeft: isMobile ? 5 : 0 }}>
              <div key={illness.id}>
                <h3>{illness.name}</h3>
                <p className={styles.altName} style={{ fontSize: CSS_VARS.chatFontSize }}>
                  {illness.alternativeName}
                </p>
                <p
                  style={{ color: illness.treatmentShortInfo.textColor, fontSize: CSS_VARS.chatFontSize }}
                  className={styles.urgency}
                >
                  {illness.treatmentShortInfo.text}
                </p>
              </div>
            </Col>
            <Col
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
              span={2}
              id={`extend_arrow_button_${illness.name}`}
            >
              <CustomIcon
                icon="arrowDown"
                className={
                  extended.open && illnessIndex === extended.index
                    ? classnames(styles.arrowIcon, styles.animateUp)
                    : styles.arrowIcon
                }
              />
            </Col>
          </Row>
          {isAnItemExtended && (
            <Row
              className={classnames(styles.description, 'descriptionFadeIn')}
              style={{ fontSize: CSS_VARS.chatFontSize }}
            >
              <Col
                xs={{ span: 18, offset: 0 }}
                md={{ offset: 4, span: 18 }}
                style={{ borderBottom: '1px solid #f2f2f2' }}
              >
                <p style={{ marginBottom: 35 }}>{illness.description}</p>
                {checkType === CHECK_TYPE_ILLNESS_CHECK && showFactorScores && (
                  <div style={{ marginBottom: 35 }}>
                    {/*TODO move to dynamic components*/}
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ display: 'flex', marginRight: '0.5em', alignItems: 'center' }}>
                        <CustomIcon
                          icon={illness.factorScores.profile.excludeInfo === 'NOT_EXCLUDING' ? 'tick' : 'cross'}
                          style={{ height: 16, width: 16 }}
                        />
                      </div>
                      <div style={{ display: 'flex' }}>
                        <span dangerouslySetInnerHTML={{ __html: illness.factorScores.profile.text }} />
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ display: 'flex', marginRight: '0.5em', alignItems: 'center' }}>
                        <CustomIcon
                          icon={illness.factorScores.symptoms.excludeInfo === 'NOT_EXCLUDING' ? 'tick' : 'cross'}
                          style={{ height: 16, width: 16 }}
                        />
                      </div>
                      <div style={{ display: 'flex' }}>
                        <span dangerouslySetInnerHTML={{ __html: illness.factorScores.symptoms.text }} />
                      </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ display: 'flex', marginRight: '0.5em', alignItems: 'center' }}>
                        <CustomIcon
                          icon={illness.factorScores.other.excludeInfo === 'NOT_EXCLUDING' ? 'tick' : 'cross'}
                          style={{ height: 16, width: 16 }}
                        />
                      </div>
                      <div style={{ display: 'flex' }}>
                        <span dangerouslySetInnerHTML={{ __html: illness.factorScores.other.text }} />
                      </div>
                    </div>
                  </div>
                )}
                <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Col style={{ marginBottom: 20, marginRight: 10 }}>
                    <p style={{ fontWeight: 700, color: CSS_VARS.DARK_BLUE_COLOR }}>
                      {i18n('xund.report.selectedSymptoms')}
                    </p>
                    <ul>
                      {illness.symptoms
                        .filter((symptomItem) => symptomItem.have)
                        .map((symptomItem) => (
                          <li key={symptomItem.id}>
                            <div className={styles.symptomItem} style={{ fontWeight: 700 }}>
                              {symptomItem.name}
                            </div>
                          </li>
                        ))}
                    </ul>
                  </Col>
                  <Col>
                    <p style={{ fontWeight: 700, color: CSS_VARS.DARK_BLUE_COLOR }}>
                      {i18n('xund.report.notSelectedSymptoms')}
                    </p>
                    <ul>
                      {illness.symptoms
                        .filter((symptomItem) => !symptomItem.have)
                        .map((symptomItem) => (
                          <li key={symptomItem.id}>
                            <div className={styles.symptomItem}>{symptomItem.name}</div>
                          </li>
                        ))}
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </div>
      </div>

      {isAnItemExtended && hasSuggestedMeasures && (
        <>
          <Row style={{ marginBottom: 20 }}>
            <Col xs={{ span: 18, offset: 0 }} md={{ offset: 4, span: 18 }} className="descriptionFadeIn">
              <strong style={{ color: CSS_VARS.questionFontColor }}>{i18n('xund.general.quickSolutions')}</strong>
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>{isEmergency && <EmergencyWarningWithCallNumber />}</Row>
          {(illness.quickSolutions || illness.specializations) && (
            <QuickSolutions
              quickSolutions={illness.quickSolutions}
              specializations={illness.specializations}
              localSpecializations={illness.localSpecializations}
              inReport
            />
          )}
          {virtualUrlServices.length > 0 && <VirtualUrlServiceList virtualUrlServices={virtualUrlServices} />}
        </>
      )}
    </>
  )
}
