import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import {
  BasicQuestion,
  CHECK_ANSWER_TYPE_SKIPPED,
  FamilyHistoryAnswer,
  FamilyHistoryMaternalQuestion,
  FamilyHistoryPaternalQuestion,
  GrowingScaleQuestion,
  MultiSelectQuestion,
  QUESTION_STATE_HEALTH_DATA,
  QUESTION_STATE_PROFILE,
  QUESTION_STATE_REPORT,
  QUESTION_STATE_RISK_TOPIC,
  QUESTION_TYPE_AGE,
  QUESTION_TYPE_BMI,
  QUESTION_TYPE_CONFIRM,
  QUESTION_TYPE_FAMILY_HISTORY_MATERNAL,
  QUESTION_TYPE_FAMILY_HISTORY_PATERNAL,
  QUESTION_TYPE_GENDER,
  QUESTION_TYPE_GROWINGSCALE,
  QUESTION_TYPE_MULTISELECT,
  QUESTION_TYPE_RISK_TOPIC,
  QUESTION_TYPE_SINGLESELECT,
  QUESTION_TYPE_TERMS,
  RiskTopicQuestion,
} from 'health-check-api'
import { LoadingIndicator } from '../../../components/LoadingIndicator/LoadingIndicator'
import { useQuestionContext } from '../context'
import { useAppStateContext, useWebAppConfigContext } from '../../../context'
import { FooterButtonState } from '../models'
import { useI18n } from '../../../hooks'
import { LegalDisclaimer } from './LegalDisclaimer'
import { AgeInput } from './AgeInput'
import { SingleSelect } from './SingleSelect'
import { SliderInput } from './SliderInput'
import { RiskTopicSelector } from './RiskTopicSelector'
import { Info } from './Info'
import { MultiSelect } from './MultiSelect'
import { BMI } from './BMI'
import { usePreventNavigationHook } from '../hooks/usePreventNavigationHook'
import { FamilyHistory } from './FamilyHistory'
import { Gender } from 'health-check-types'

interface Props {
  selectedRiskTopics?: string[]
  onSetProgressPercent: Dispatch<SetStateAction<number>>
}

export const Renderer: FC<Props> = (props: Props) => {
  const { selectedRiskTopics, onSetProgressPercent } = props
  const {
    currentQuestion,
    setFooterButtonState,
    api,
    checkId,
    setCheckId,
    setCurrentQuestion,
    setReport,
    setStatusStep,
    isLoading,
    setLoading,
    selectedAnswer,
    setSelectedAnswer,
  } = useQuestionContext()
  const { initialBirth, initialGender, initialHeight, initialWeight } = useAppStateContext()
  const [component, setComponent] = useState(<LoadingIndicator />)
  const {
    webAppConfig: { privacyPolicyUrl, termsAndConditionsUrl },
  } = useWebAppConfigContext()
  const { i18n } = useI18n()

  const questionType = useMemo(() => currentQuestion.type, [currentQuestion])
  const currentState = useMemo(() => currentQuestion.state, [currentQuestion])

  const handleClick = useCallback(
    async (answer: string | null | string[] | FamilyHistoryAnswer) => {
      setLoading(true)
      await api.setAnswer(checkId, currentQuestion.id, answer)
      const question = await api.getQuestion(checkId)
      setCurrentQuestion(question)
      setSelectedAnswer(null)
      setLoading(false)
    },
    [setLoading, api, checkId, currentQuestion.id, setCurrentQuestion, setSelectedAnswer],
  )

  const handleReportClick = useCallback(async () => {
    setLoading(true)
    await api.setAnswer(checkId, currentQuestion.id, '')
    const reportResponse = await api.getReport(checkId)
    setReport(reportResponse)
    setLoading(false)
  }, [setLoading, api, checkId, currentQuestion.id, setReport])

  const handleSingleSelectClick = useCallback(
    (answer: string) => {
      void handleClick(answer)
    },
    [handleClick],
  )

  const handleStartCheck = useCallback(async () => {
    const age = new Date().getFullYear() - parseInt(initialBirth ?? '', 10)
    const gender = initialGender ? (initialGender.toUpperCase() as Gender) : undefined

    setLoading(true)
    const checkIdFromResponse = await api.initialize(age, gender, initialHeight, initialWeight, selectedRiskTopics)
    const questionFromResponse = await api.getQuestion(checkIdFromResponse)
    setCheckId(checkIdFromResponse)
    setCurrentQuestion(questionFromResponse)
    setLoading(false)
  }, [
    initialBirth,
    initialGender,
    setLoading,
    api,
    initialWeight,
    initialHeight,
    selectedRiskTopics,
    setCheckId,
    setCurrentQuestion,
  ])

  const setStatus = useCallback(() => {
    switch (currentQuestion.state) {
      case QUESTION_STATE_PROFILE:
        setStatusStep(0)
        break
      case QUESTION_STATE_RISK_TOPIC:
      case QUESTION_STATE_HEALTH_DATA:
        setStatusStep(1)
        break
      case QUESTION_STATE_REPORT:
        setStatusStep(3)
        break
      default:
        setStatusStep(0)
    }
    if ([QUESTION_TYPE_FAMILY_HISTORY_MATERNAL, QUESTION_TYPE_FAMILY_HISTORY_PATERNAL].includes(questionType)) {
      setStatusStep(2)
    }
  }, [currentQuestion.state, setStatusStep, questionType])

  const setProgress = useCallback(() => {
    if (currentQuestion.progressPercent) {
      onSetProgressPercent(currentQuestion.progressPercent)
    }
  }, [currentQuestion, onSetProgressPercent])

  const isMultiSelectNextButtonDisabledCondition = useMemo(() => {
    if (Array.isArray(selectedAnswer)) {
      return selectedAnswer.length < 1
    }
    return true
  }, [selectedAnswer])

  usePreventNavigationHook()

  useEffect(() => {
    const defaultFooterButtonState: FooterButtonState = {
      onNextButtonClick: () => handleClick(selectedAnswer),
      nextButtonTitle: i18n('healthCheck.footer.next'),
      isNextButtonDisabled: !selectedAnswer || (selectedAnswer as string[]).length === 0,
      isNextButtonHidden: false,
      isSkipButtonHidden: true,
      onSkipButtonClick: null,
      isBackButtonHidden: !currentQuestion.canMoveBack,
    }

    switch (questionType) {
      case QUESTION_TYPE_TERMS:
        setComponent(<LegalDisclaimer onStartCheck={handleStartCheck} onCancelCheck={() => window.location.reload()} />)
        break
      case QUESTION_TYPE_AGE:
        setFooterButtonState(defaultFooterButtonState)
        setComponent(
          <AgeInput
            title={currentQuestion.text}
            isLoading={isLoading}
            onSelect={(answer) => setSelectedAnswer(answer)}
          />,
        )
        break
      case QUESTION_TYPE_BMI:
        setFooterButtonState(defaultFooterButtonState)
        setComponent(
          <BMI
            isLoading={isLoading}
            title={currentQuestion.text}
            onCalculation={(calculatedBMI) => setSelectedAnswer(calculatedBMI)}
          />,
        )
        break
      case QUESTION_TYPE_SINGLESELECT:
        setFooterButtonState({
          onNextButtonClick: () => handleClick(selectedAnswer),
          nextButtonTitle: i18n('healthCheck.footer.next'),
          isNextButtonDisabled: !selectedAnswer,
          isNextButtonHidden: ((currentQuestion as BasicQuestion).answers?.length ?? 0) < 7,
          isSkipButtonHidden: false,
          onSkipButtonClick: () => handleClick(CHECK_ANSWER_TYPE_SKIPPED),
          isBackButtonHidden: !currentQuestion.canMoveBack,
        })
        setComponent(
          <SingleSelect
            title={currentQuestion.text}
            onDropdownSelect={(answerId) => setSelectedAnswer(answerId)}
            onSingleSelectClick={handleSingleSelectClick}
            answers={(currentQuestion as BasicQuestion).answers}
            isLoading={isLoading}
            questionDescription={currentQuestion.description}
          />,
        )
        break
      case QUESTION_TYPE_MULTISELECT:
        setFooterButtonState({
          onNextButtonClick: () => handleClick(selectedAnswer),
          nextButtonTitle: i18n('healthCheck.footer.next'),
          isNextButtonDisabled: isMultiSelectNextButtonDisabledCondition,
          isNextButtonHidden: false,
          isSkipButtonHidden: false,
          onSkipButtonClick: () => handleClick(CHECK_ANSWER_TYPE_SKIPPED),
          isBackButtonHidden: !currentQuestion.canMoveBack,
        })
        setComponent(
          <MultiSelect
            title={currentQuestion.text}
            onDropdownSelect={(answerId) => setSelectedAnswer(answerId)}
            answers={(currentQuestion as MultiSelectQuestion).answers}
            isLoading={isLoading}
            questionDescription={currentQuestion.description}
          />,
        )
        break
      case QUESTION_TYPE_GENDER:
        setFooterButtonState({
          onNextButtonClick: () => handleClick(selectedAnswer),
          nextButtonTitle: '',
          isNextButtonDisabled: !selectedAnswer,
          isNextButtonHidden: true,
          isSkipButtonHidden: true,
          onSkipButtonClick: null,
          isBackButtonHidden: !currentQuestion.canMoveBack,
        })
        setComponent(
          <SingleSelect
            title={currentQuestion.text}
            onDropdownSelect={(answerId) => setSelectedAnswer(answerId)}
            onSingleSelectClick={handleSingleSelectClick}
            answers={(currentQuestion as BasicQuestion).answers}
            isLoading={isLoading}
          />,
        )
        break
      case QUESTION_TYPE_GROWINGSCALE:
        setFooterButtonState({
          onNextButtonClick: () => handleClick(selectedAnswer),
          nextButtonTitle: i18n('healthCheck.footer.next'),
          isNextButtonDisabled: !selectedAnswer,
          isNextButtonHidden: false,
          isSkipButtonHidden: false,
          onSkipButtonClick: () => handleClick(CHECK_ANSWER_TYPE_SKIPPED),
          isBackButtonHidden: !currentQuestion.canMoveBack,
        })
        setComponent(
          <SliderInput
            title={currentQuestion.text}
            isLoading={isLoading}
            answers={(currentQuestion as GrowingScaleQuestion).answers}
            onSelect={(answerId) => setSelectedAnswer(answerId)}
            questionDescription={currentQuestion.description}
          />,
        )
        break
      case QUESTION_TYPE_RISK_TOPIC:
        setFooterButtonState(defaultFooterButtonState)
        setComponent(
          <RiskTopicSelector
            title={currentQuestion.text}
            isLoading={isLoading}
            answers={(currentQuestion as RiskTopicQuestion).answers}
            onSelect={(answer: string[] | null) => setSelectedAnswer(answer)}
          />,
        )
        break
      case QUESTION_TYPE_CONFIRM:
        setFooterButtonState({
          onNextButtonClick: handleReportClick,
          nextButtonTitle: i18n('healthCheck.footer.understood'),
          isNextButtonHidden: false,
          isNextButtonDisabled: false,
          isSkipButtonHidden: true,
          onSkipButtonClick: null,
          isBackButtonHidden: true,
        })
        setComponent(<Info questionText={currentQuestion.text} isLoading={isLoading} />)
        break
      case QUESTION_TYPE_FAMILY_HISTORY_PATERNAL:
        setFooterButtonState({
          ...defaultFooterButtonState,
          isSkipButtonHidden: false,
          onSkipButtonClick: () => handleClick(CHECK_ANSWER_TYPE_SKIPPED),
          isNextButtonDisabled:
            selectedAnswer === null ||
            !Array.isArray(selectedAnswer?.[0]) ||
            (selectedAnswer as FamilyHistoryAnswer)?.some((familyMember) => !familyMember.length),
        })
        setComponent(
          <FamilyHistory
            isLoading={isLoading}
            title={currentQuestion.text}
            conditions={(currentQuestion as FamilyHistoryPaternalQuestion).params}
            parent="father"
            questionDescription={currentQuestion.description}
            selectedAnswer={selectedAnswer as FamilyHistoryAnswer}
            onSelectAnswer={(answer: FamilyHistoryAnswer) => setSelectedAnswer(answer)}
          />,
        )
        break
      case QUESTION_TYPE_FAMILY_HISTORY_MATERNAL:
        setFooterButtonState({
          ...defaultFooterButtonState,
          isSkipButtonHidden: false,
          nextButtonTitle: i18n('components.familyHistory.nextButtonMother'),
          onSkipButtonClick: () => handleClick(CHECK_ANSWER_TYPE_SKIPPED),
          isNextButtonDisabled:
            selectedAnswer === null ||
            !Array.isArray(selectedAnswer?.[0]) ||
            (selectedAnswer as FamilyHistoryAnswer)?.some((familyMember) => !familyMember.length),
        })
        setComponent(
          <FamilyHistory
            isLoading={isLoading}
            title={currentQuestion.text}
            conditions={(currentQuestion as FamilyHistoryMaternalQuestion).params}
            parent="mother"
            questionDescription={currentQuestion.description}
            selectedAnswer={selectedAnswer as FamilyHistoryAnswer}
            onSelectAnswer={(answer) => setSelectedAnswer(answer)}
          />,
        )
        break
      default:
        setComponent(<LoadingIndicator />)
        break
    }
    setStatus()
    setProgress()
  }, [
    setStatus,
    currentQuestion,
    handleClick,
    handleReportClick,
    questionType,
    selectedAnswer,
    setFooterButtonState,
    currentState,
    setStatusStep,
    isLoading,
    setSelectedAnswer,
    handleSingleSelectClick,
    handleStartCheck,
    termsAndConditionsUrl,
    privacyPolicyUrl,
    isMultiSelectNextButtonDisabledCondition,
    i18n,
    setProgress,
  ])

  return isLoading ? <LoadingIndicator /> : component
}
