import styled from '@emotion/styled'
import { JS_STYLING } from '../../../resources/cssVariableConfig'

// TODO migrate MainPage.module.less to styled components

export const DialogRootContainer = styled.div(`
  width: 100%;
  height: 100%;
  
  position:fixed;
  top:0;
  left:0;
  z-index: 1100;
 
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
`)

export const DialogContentContainer = styled.div(`
  position: relative;
  background-color: white;
  border-radius: 15px;
  box-shadow: 3px 13px 54px 0 rgba(0, 65, 122, 0.18);
  padding: 50px 70px 10px 70px;
  @media (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    padding: 25px 40px 10px 40px;
  }
`)

export const DialogActionsContainer = styled.div(`
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  gap: 12px;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
`)

export const DialogTitleContainer = styled.h1(`
  font-size: 18px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  width: fit-content;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
  color: var(--modalTitleFontColor);
`)
