import styled from '@emotion/styled'
import { InputNumber } from 'antd'
import { CSS_VARS } from '../../resources/cssVariableConfig'

export const Input = styled(InputNumber)`
  width: 100%;
  border-radius: 5px;
  border-width: 0.5px;
  font-size: 14px;
  font-family: ${CSS_VARS.fontFamily};
`

export const Label = styled.p`
  font-family: ${CSS_VARS.fontFamily};
  color: ${CSS_VARS.DARK_GREY_COLOR};
`
