import { BasicQuestion, QUESTION_STATE_PROFILE, QUESTION_TYPE_GENDER } from 'health-check-api'

export const mockGenderSelectQuestion: BasicQuestion = {
  id: 'genderId',
  text: 'What is your biological sex?',
  description: '',
  type: QUESTION_TYPE_GENDER,
  state: QUESTION_STATE_PROFILE,
  answers: [],
  params: null,
  canMoveBack: false,
}
