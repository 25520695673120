import { useI18n } from '../../../hooks'

export const useMockRiskTopics = () => {
  const { i18n } = useI18n()

  return [
    {
      id: '2',
      name: i18n('healthCheck.topics.pulmonary'),
      description: '',
      isDisabled: true,
      score: 0,
      riskFactors: [],
    },
    {
      id: '3',
      name: i18n('healthCheck.topics.gastrointestinal'),
      description: '',
      isDisabled: true,
      score: 0,
      riskFactors: [],
    },
    {
      id: '4',
      name: i18n('healthCheck.topics.dermatologic'),
      description: '',
      isDisabled: true,
      score: 0,
      riskFactors: [],
    },
    { id: '6', name: i18n('healthCheck.topics.women'), description: '', isDisabled: true, score: 0, riskFactors: [] },
    { id: '14', name: i18n('healthCheck.topics.men'), description: '', isDisabled: true, score: 0, riskFactors: [] },
    {
      id: '8',
      name: i18n('healthCheck.topics.endoctrine'),
      description: '',
      isDisabled: true,
      score: 0,
      riskFactors: [],
    },
    {
      id: '9',
      name: i18n('healthCheck.topics.hematologic'),
      description: '',
      isDisabled: true,
      score: 0,
      riskFactors: [],
    },
    {
      id: '11',
      name: i18n('healthCheck.topics.neurologic'),
      description: '',
      isDisabled: true,
      score: 0,
      riskFactors: [],
    },
    {
      id: '12',
      name: i18n('healthCheck.topics.ophthalmology'),
      description: '',
      isDisabled: true,
      score: 0,
      riskFactors: [],
    },
    {
      id: '13',
      name: i18n('healthCheck.topics.otology'),
      description: '',
      isDisabled: true,
      score: 0,
      riskFactors: [],
    },
  ]
}
